<template>
  <modal
    id="DocumentEdit"
    name="documentEditModal"
    :adaptive="true"
    width="500px"
    height="320px"
    :closable="false"
  >
    <v-app>
      <div>
        <v-toolbar flat>
          <v-toolbar-title>ドキュメント編集</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container class="grey lighten-5">
            <v-card-text>
              <v-text-field
                v-model="fileData.file_name"
                label="ファイル名"
                :rules="uploadRules"
                required
              >
              </v-text-field>

              <v-select
                v-model="fileData.category"
                :items="fileData.items"
                item-text="value"
                item-value="key"
                label="カテゴリ"
                clearable
                :rules="[(v) => !!v || '必須項目']"
                required
              ></v-select>

              <v-spacer />
              <v-row>
                <v-col align="center">
                  <v-btn
                    :disabled="!valid"
                    class="ma-2 white--text"
                    color="primary"
                    @click="editDocument"
                  >
                    <v-icon left> mdi-playlist-check </v-icon>
                    編集する
                  </v-btn>
                  <v-btn
                    class="ma-2 white--text"
                    color="blue-grey"
                    dark
                    @click="closeDocumentEditModal(false)"
                  >
                    <v-icon left> mdi-playlist-remove </v-icon>
                    キャンセル
                  </v-btn>
                  <v-spacer />
                </v-col>
              </v-row>

              <v-snackbar
                v-model="snackbar"
                color="orange darken-2"
                :timeout="timeout"
                centered
                text
              >
                {{ text }}
              </v-snackbar>
            </v-card-text>
          </v-container>
        </v-form>
      </div>
    </v-app>
  </modal>
</template>

<script>
import * as API from "../../../apis/API";

export default {
  name: "documentEdit",

  props: {
    id: { type: null, required: true },
    file_name: { type: String, required: true },
    category: { type: null, required: true },
  },

  data() {
    return {
      valid: true,
      uploadRules: [(v) => !!v || "必須項目"],
      snackbar: false,
      text: "",
      timeout: 3000,

      fileData: {
        file_name: this.file_name,
        category: this.category,
        items: [
          { key: 1, value: "就業" },
          { key: 2, value: "研修" },
          { key: 3, value: "その他" },
        ],
      },
    };
  },
  watch: {
    file_name: function (newFileName) {
      this.fileData.file_name = newFileName;
    },
    category: function (newCategory) {
      this.fileData.category = newCategory;
    },
  },

  methods: {
    // データをDBに更新
    editDocument() {
      let id = this.id;
      let file_name = this.fileData.file_name;
      let category = this.fileData.category;
      let notEmpty = this.$refs.form.validate();
      if (notEmpty === true) {
        if (confirm(`ドキュメントID: [ ${this.id} ] を編集しますか?`)) {
          let fileData = new FormData();
          fileData.append("category", category);
          fileData.append("file_name", file_name);
          API.editDocument("documentUpload", id, fileData)
            .then((response) => {
              if (response.payload.status === 200) {
                this.text =
                  "ドキュメントID [ " +
                  id +
                  " ] 更新しました。モーダルをクローズする";
                this.snackbar = true;
                // 登録済、ダイアログクローズ
                setTimeout(() => {
                  this.closeDocumentEditModal(true);
                }, 2000);
              }
            })
            .catch((error) => {
              console.log(error.response);
              this.text = "ドキュメントID [ " + id + " ] 更新を失敗しました";
              this.snackbar = true;
              this.closeDocumentEditModal();
            });
        }
      }
    },

    closeDocumentEditModal(isRefresh) {
      if (isRefresh) {
        // 更新済
        this.fileData.file_name = "";
        this.fileData.category = "";
        this.$modal.hide("documentEditModal");
        this.showModal = false;
        this.$emit("on-close", { isclose: true, isRefresh: isRefresh });
      } else {
        // キャンセル
        this.$modal.hide("documentEditModal");
        this.showModal = false;
      }
    },
  },
};
</script>
<style>
.v-btn {
  width: 150px;
}
</style>