<template>
    <v-data-table :items="list" :headers="header" :sort-by="sortBy" :sort-desc="sortDesc" multi-sort @click:row="clickRow">
        <template v-slot:[`item.created_at`]="props">
            {{new Date(props.item.created_at).toLocaleString()}}
        </template>
        <template v-slot:[`item.updated_at`]="props">
            {{new Date(props.item.updated_at).toLocaleString()}}
        </template>
    </v-data-table>
</template>
<script>
export default {
    props: {
        header: Array,
        list: Array,
        sortBy: Array,
        sortDesc: Array,
    },
    methods: {
        clickRow: function(data) {
            this.$emit("clickRow", data)
        }
    }
};
</script>
