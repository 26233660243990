<template>
    <v-card>
        <v-card-title>
            <span class="headline">定期代申請</span>
        </v-card-title>
        <div class="px-6" style="word-break: keep-all;">※申請期限に間に合わなかった場合は、上長または管理部までご相談ください。</div>
        <v-spacer />
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col>
                        <form-label-select-box
                            label="支給月 (当月5日まで申請可能です)"
                            v-bind:value.sync="payday.value"
                            :items="$store.state[this.namespace]['months']"
                            :error.sync="payday.error"
                            :errorMsg.sync="payday.errorMsg"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <form-label-select-box
                            label="交通機関種別"
                            v-bind:value.sync="type.value"
                            :items="$store.state[this.namespace]['types']"
                            :error.sync="type.error"
                            :errorMsg.sync="type.errorMsg"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <form-label-text
                            label="交通機関名称"
                            placeholder="例）JR, 東京メトロ"
                            v-bind:value.sync="transportation_facilities.value"
                            :error.sync="transportation_facilities.error"
                            :errorMsg.sync="transportation_facilities.errorMsg"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <form-label-text
                            label="訪問先"
                            placeholder="例）Mist Solution"
                            v-bind:value.sync="visit_target.value"
                            :error.sync="visit_target.error"
                            :errorMsg.sync="visit_target.errorMsg"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <form-label-text
                            label="経路（From）"
                            placeholder="例）東京"
                            v-bind:value.sync="route_from.value"
                            :error.sync="route_from.error"
                            :errorMsg.sync="route_from.errorMsg"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <form-label-text
                            label="経路（To）"
                            placeholder="例）神田"
                            v-bind:value.sync="route_to.value"
                            :error.sync="route_to.error"
                            :errorMsg.sync="route_to.errorMsg"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <form-datepicker
                            label="日付(From)"
                            v-bind:value.sync="date_from.value"
                            :error.sync="date_from.error"
                            :errorMsg.sync="date_from.errorMsg"
                        />
                    </v-col>
                    <v-col>
                        <form-datepicker
                            label="日付(To)"
                            v-bind:value.sync="date_to.value"
                            :error.sync="date_to.error"
                            :errorMsg.sync="date_to.errorMsg"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <form-label-text
                            label="金額"
                            placeholder="例）10000"
                            v-bind:value.sync="price.value"
                            :error.sync="price.error"
                            :errorMsg.sync="price.errorMsg"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <submit-button-with-snackbar
                :submitButton="id.value == null"
                :updateButton="id.value != null"
                :deleteButton="id.value != null"
                :cancelButton="id.value != null"
                :snackbar.sync="snackbar"
                @snackbar="snackbarUpdate"
                @submitEvent="submitEvent()"
                @updateEvent="updateEvent()"
                @deleteEvent="deleteEvent()"
                @cancelEvent="cancelEvent()"
            />
        </v-card-actions>
    </v-card>
</template>

<script>
import FormDatepicker from "../molecules/FormDatepicker.vue";
import FormLabelSelectBox from '../molecules/FormLabelSelectBox.vue';
import FormLabelText from "../molecules/FormLabelText.vue";
import SubmitButtonWithSnackbar from '../molecules/SubmitButtonWithSnackbar.vue';
export default {
    components: { FormLabelText, FormDatepicker, FormLabelSelectBox, SubmitButtonWithSnackbar },
    name: "CommuterPassPrice",
    data: () => ({
        namespace: "CommuterPassPrice",
    }),
    computed: {
        id: {
            get: function() {
                return this.$store.state[this.namespace]["items"]["id"]
            }
        },
        list: {
            get: function () {
                return this.$store.state[this.namespace]["list"];
            },
        },
        snackbar: {
            get: function () {
                return this.$store.state[this.namespace]["snackbar"];
            },
        },
        employee_no: {
            get: function () {
                return this.$store.state[this.namespace]["items"][
                    "employee_no"
                ];
            },
            set: function (value) {
                this.$store.dispatch("setItem", {
                    namespace: this.namespace,
                    key: "employee_no",
                    value: value,
                });
            },
        },
        payday: {
            get: function () {
                return this.$store.state[this.namespace]["items"][
                    "payday"
                ];
            },
            set: function (value) {
                this.$store.dispatch("setItem", {
                    namespace: this.namespace,
                    key: "payday",
                    value: value,
                });
            },
        },
        type: {
            get: function () {
                return this.$store.state[this.namespace]["items"][
                    "type"
                ];
            },
            set: function (value) {
                this.$store.dispatch("setItem", {
                    namespace: this.namespace,
                    key: "type",
                    value: value,
                });
            },
        },
        transportation_facilities: {
            get: function () {
                return this.$store.state[this.namespace]["items"][
                    "transportation_facilities"
                ];
            },
            set: function (value) {
                this.$store.dispatch("setItem", {
                    namespace: this.namespace,
                    key: "transportation_facilities",
                    value: value,
                });
            },
        },
        visit_target: {
            get: function () {
                return this.$store.state[this.namespace]["items"][
                    "visit_target"
                ];
            },
            set: function (value) {
                this.$store.dispatch("setItem", {
                    namespace: this.namespace,
                    key: "visit_target",
                    value: value,
                });
            },
        },
        route_from: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["route_from"];
            },
            set: function (value) {
                this.$store.dispatch("setItem", {
                    namespace: this.namespace,
                    key: "route_from",
                    value: value,
                });
            },
        },
        route_to: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["route_to"];
            },
            set: function (value) {
                this.$store.dispatch("setItem", {
                    namespace: this.namespace,
                    key: "route_to",
                    value: value,
                });
            },
        },
        date_from: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["date_from"];
            },
            set: function (value) {
                this.$store.dispatch("setItem", {
                    namespace: this.namespace,
                    key: "date_from",
                    value: value,
                });
            },
        },
        date_to: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["date_to"];
            },
            set: function (value) {
                this.$store.dispatch("setItem", {
                    namespace: this.namespace,
                    key: "date_to",
                    value: value,
                });
            },
        },
        price: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["price"];
            },
            set: function (value) {
                this.$store.dispatch("setItem", {
                    namespace: this.namespace,
                    key: "price",
                    value: value,
                });
            },
        },
    },
    mounted: function () {
        this.getEmployeeNo();
    },
    methods: {
        submitEvent: function () {
            let result = confirm("登録しますか？");
            if (result) {
                this.$store.dispatch("CommuterPassPrice/submit");
            }
        },
        updateEvent: function() {
            let result = confirm("更新しますか？");
            if (result) {
                this.$store.dispatch("CommuterPassPrice/update");
            }
        },
        deleteEvent: function() {
            let result = confirm("削除しますか？");
            if (result) {
                this.$store.dispatch("CommuterPassPrice/delete");
            }
        },
        cancelEvent: function() {
            let result = confirm("編集を取り消しますか？");
            if (result) {
                this.$store.dispatch("CommuterPassPrice/cancel");
            }
        },
        snackbarUpdate: function(data) {
            this.snackbar.isDisplay = data
            this.snackbar.method = null
        },
        getEmployeeNo() {
            this.$store.dispatch("CommuterPassPrice/getEmployeeNo");
        },
    },
};
</script>