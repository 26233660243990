import axios from "axios";

//メンバー一覧画面、メンバー一覧呼び出しAPI
export function getMemberList() {
    return new Promise(resolve => {
        const payload = axios.get(`/miiits/memberList/`, {
                headers: {
                    'content-type': 'application/json',
                },
            })
            .then(response => {
                return { payload: response };
            })
            .catch(error => {
                return { error };
            });
        resolve(payload);
    });

}