<template>
  <v-app>
    <div>
      <v-toolbar flat>
        <v-toolbar-title>ドキュメント一覧</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-4"
          color="cyan"
          outlined
          @click="showDocumentUploadModal()"
          >アップロートする
        </v-btn>
      </v-toolbar>

      <!-- 一覧表示 -->
      <v-data-table
        :headers="headers"
        :items="DocumentList"
        item-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="elevation-1"
        :footer-props="footerProps"
        :search="search"
      >
        <!-- ファイル名 -->
        <template v-slot:[`item.file_name`]="{ item }">
          {{ item.file_name }}
        </template>

        <!-- ファイル操作 -->
        <template v-slot:[`item.file_path`]="{ item }">
          <!-- ダウンロード -->
          <v-btn
            icon
            large
            color="indigo darken-2"
            @click.prevent="downloadDocument(item.file_path, item.file_name)"
          >
            <v-icon color="cyan">mdi-folder-download</v-icon>
          </v-btn>

          <!-- 編集 -->
          <v-btn
            icon
            large
            color="orange"
            @click.prevent="
              showDocumentEditModal(item.id, item.file_name, item.category)
            "
          >
            <v-icon color="cyan">mdi-folder-edit</v-icon>
          </v-btn>

          <!-- 削除 -->
          <v-btn
            icon
            large
            color="indigo darken-2"
            @click.prevent="
              deleteDocument(item.id, item.file_name, item.file_status)
            "
          >
            <v-icon color="blue-grey lighten-2">mdi-folder-remove</v-icon>
          </v-btn>
        </template>

        <!-- ファイル種類 -->
        <template v-slot:[`item.category`]="{ item }">
          <v-chip small :color="getColor(item.category)" dark>
            {{ getCategoryName(item.category) }}
          </v-chip>
        </template>

        <!-- 登録日 -->
        <template v-slot:[`item.upload_date`]="{ item }">
          {{ item.upload_date | moment }}
        </template>

        <!-- 更新日 -->
        <template v-slot:[`item.update_date`]="{ item }">
          {{ item.update_date | moment }}
        </template>
      </v-data-table>

      <!-- アップロードモーダル -->
      <DocumentUpload @on-close="closeDocumentUploadModal" />

      <!-- アップロード編集モーダル -->
      <DocumentEdit
        :id="id"
        :file_name="file_name"
        :category="category"
        @on-close="closeDocumentEditModal"
      />

      <!-- メッセージバー -->
      <v-snackbar
        v-model="snackbar"
        color="orange darken-2"
        :timeout="timeout"
        centered
        text
      >
        {{ text }}
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>
import moment from "moment";
import * as API from "../../../apis/API";
import fileDownload from "js-file-download";
import DocumentUpload from "../../../components/pages/document/DocumentUpload.vue";
import DocumentEdit from "../../../components/pages/document/DocumentEdit.vue";

export default {
  name: "documentList",
  components: {
    DocumentUpload,
    DocumentEdit,
  },

  // 画面更新
  inject: ["reload"],

  data() {
    return {
      DocumentList: [],

      sortBy: "update_date",
      sortDesc: true,
      footerProps: { "items-per-page-options": [20, 60, 100, -1] },
      snackbar: false,
      text: "",
      timeout: 2000,
      search: "1", // 削除されたデータを除く
      valid: true,
      uploadRules: [(v) => !!v || "必須項目"],

      id: "",
      file_name: "",
      category: null,

      fileData: {
        file_path: null,
        file_name: "",
        category: null,
        items: [
          { key: 1, value: "就業" },
          { key: 2, value: "研修" },
          { key: 3, value: "その他" },
        ],
      },

      // テーブルヘッダ
      headers: [
        { text: "ID", align: "center", value: "id", filterable: false },
        {
          text: "有効フラグ",
          align: " d-none", //当項目隠しのため
          value: "file_status",
          sortable: false,
          filterable: true,
        },
        {
          text: "ファイル名",
          align: "left",
          value: "file_name",
          filterable: false,
        },
        {
          text: "カテゴリ",
          align: "center",
          value: "category",
          filterable: false,
        },
        {
          text: "ファイル操作",
          align: "center",
          value: "file_path",
          sortable: false,
          filterable: false,
        },
        {
          text: "登録日",
          align: "center",
          value: "upload_date",
          sortable: false,
          filterable: false,
        },
        {
          text: "更新日",
          align: "center",
          value: "update_date",
          sortable: false,
          filterable: false,
        },
      ],
    };
  },

  // 日付フォーマット
  filters: {
    moment: function (date) {
      return moment(date).format("YYYY/MM/DD HH:mm");
    },
  },
  methods: {
    showDocumentUploadModal() {
      this.$modal.show("documentUploadModal");
      this.showModal = true;
    },

    showDocumentEditModal(id, file_name, category) {
      this.$modal.show("documentEditModal");
      this.id = id;
      this.file_name = file_name;
      this.category = category;
      this.showModal = true;
      return id, file_name, category;
    },

    closeDocumentUploadModal(resVal) {
      this.showModal = resVal.isClose;
      if (resVal.isclose) {
        this.reload();
      }
    },

    closeDocumentEditModal(resVal) {
      this.showModal = resVal.isRefresh;
      if (resVal.isRefresh) {
        this.reload();
      }
    },

    // ドキュメント一覧
    indexDocumentList: function () {
      this.DocumentList = [];

      API.getDocumentList("documentUpload")
        .then((response) => {
          this.DocumentList = response.payload.data;
        })
        .catch((error) => {
          console.log("Error : " + error.response);
        });
    },

    // カテゴリ色付き
    getColor(category) {
      if (category == 1) return "green darken-3";
      else if (category == 2) return "orange darken-3";
      else return "blue darken-3";
    },

    // カテゴリ転換
    getCategoryName(category) {
      if (category == 1) return "就業";
      else if (category == 2) return "研修";
      else return "その他";
    },

    //ドキュメントダウンロード
    downloadDocument(file_path, file_name) {
      let extName = /[^.]+$/.exec(decodeURI(file_path))[0];

      API.downloadDocument(file_path)
        .then((response) => {
          if (response.payload.status === 200) {
            fileDownload(response.payload.data, `${file_name}.${extName}`);
            console.log("Download allow");
          }
        })
        .catch((error) => {
          console.log("Error: ", error.message);
          console.log("Download failed");
        });
    },

    // ドキュメント削除 （file_status更新）
    deleteDocument(id, file_name, file_status) {
      let nowTime = this.$options.filters[`moment`](new Date());
      if (confirm(`ドキュメントID: [ ${id} ] を削除しますか?`)) {
        if (file_status === 1) {
          let fileData = new FormData();
          fileData.append("file_status", 0);
          fileData.append(
            "file_name",
            "Deleted_[" + file_name + "]_" + nowTime
          );

          API.deleteDocument("documentUpload", id, fileData)
            .then((response) => {
              if (response.payload.status === 200) {
                this.text = "ドキュメントID [ " + id + " ] 削除されました";
                this.snackbar = true;
              }
            })
            .catch((error) => {
              console.log(error.response);
              this.text = "ドキュメントID [ " + id + " ]  削除を失敗しました";
              this.snackbar = true;
            });
        }
        setTimeout(() => {
          this.reload();
        }, 2000);
      }
    },
  },

  mounted() {
    this.indexDocumentList();
  },
};
</script>