import Vue from 'vue'
import axios from 'axios';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSession from 'vue-session'
import VueLazyload from 'vue-lazyload';
import vmodal from 'vue-js-modal'
import VueHead from 'vue-head';

Vue.use(vuetify)
Vue.use(VueSession)
Vue.use(VueLazyload, {
  preLoad: 1.3, // 事前ロードする高さの割合指定
  error: 'images/no-image.jpg', // エラー時に表示する画像指定
  loading: 'images/loading.gif', // ロード中に表示する画像指定
  attempt: 1 // ロード失敗した時のリトライの上限指定
})
Vue.use(vmodal)
Vue.use(VueHead);

Vue.config.productionTip = false


axios.defaults.baseURL = process.env.VUE_APP_BACKEND_ROOT //バックエンド側のIPとポート
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.VUE_APP_FRONTEND_ROOT
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
Vue.prototype.$axios = axios

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
