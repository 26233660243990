<template>
    <div>
        <title>"title"</title>
        <h2>登録済みデータ一覧</h2>
        <h4>修正または削除したい場合は当該行をクリック(またはタップ)してください。画面上部のフォームから修正できます。</h4>
        <formatted-list-table :header="header" :list.sync="list" :sort-by="sortBy" :sort-desc="sortDesc" multi-sort @clickRow="clickRowEvent" />
    </div>
</template>

<script>
import FormattedListTable from "../atoms/FormattedListTable.vue";
export default {
    components: { FormattedListTable },
    name: "CommuterPassPrice",
    data: () => ({
        header: [],
        sortBy: ["payday", "date_from", "date_to"],
        sortDesc: ["true", "true", "true"],
    }),
    props: {
        list: Array,
    },
    mounted: function () {
        this.header = [
            { text: "支給月", value: "payday" },
            { text: "交通機関種別", value: "type" },
            { text: "交通機関", value: "transportation_facilities" },
            { text: "訪問先", value: "visit_target" },
            { text: "経路（FROM）", value: "route_from" },
            { text: "経路（TO）", value: "route_to" },
            { text: "日付（FROM）", value: "date_from" },
            { text: "日付（TO）", value: "date_to" },
            { text: "金額", value: "price" },
            { text: "登録日時", value: "created_at" },
            { text: "更新日時", value: "updated_at" },
        ];
    },
    methods: {
        clickRowEvent: function (data) {
            this.$emit("clickRow", data)
        }
    }
};
</script>