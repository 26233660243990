<template>
    <v-main app>
        <transportation-expense-admin-template />
    </v-main>
</template>

<script>
import TransportationExpenseAdminTemplate from "../templates/TransportationExpenseAdminTemplate.vue";
export default {
    // name: "TransportationExpense",
    components: {
        TransportationExpenseAdminTemplate,
    },
    mounted: function () {
        // this.getEmployeeNo();
    },
    methods: {
        // getEmployeeNo() {
        //     this.$store.dispatch("TransportationExpense/getEmployeeNo");
        // },
    },
};
</script>