<template>
  <v-app>
    <Header />
    <div class="body__wrap background__beige">
      <div class="concontainer-fluid font-ryo-gothic">
        <!-- カルーセル -->
        <section class="carousel__background">
          <div class="body__gap--control"></div>
          <div class="row region__control">
            <div class="col-10 offset-1 carousel__flame">
              <!-- カルーセルのコントロールボタン -->
              <button
                type="button"
                data-bs-target="#carouselPickupArticle"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                type="button"
                data-bs-target="#carouselPickupArticle"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
              <!-- カルーセルインジケーター -->
              <div
                id="carouselPickupArticle"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-indicators test mt-5">
                  <button
                    type="button"
                    data-bs-target="#carouselPickupArticle"
                    data-bs-slide-to="0"
                    class="active carousel__indicators--btn"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselPickupArticle"
                    data-bs-slide-to="1"
                    class="carousel__indicators--btn"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselPickupArticle"
                    data-bs-slide-to="2"
                    class="carousel__indicators--btn"
                    aria-label="Slide 3"
                  ></button>
                </div>
                <!-- カルーセル中身（記事リンク） -->
                <div class="carousel-inner carousel__inner--height">
                  <!-- 1記事目 -->
                  <div class="carousel-item active">
                    <div v-if="this.PickupArticleList[0]">
                      <div
                        class="row region__control mt-5 mb-5 top--background"
                      >
                        <!-- サムネ画像 -->
                        <div
                          class="
                            col-sm-4
                            offset-sm-1
                            col-10
                            offset-1
                            d-flex
                            align-items-center
                            justify-content-center
                          "
                        >
                          <img
                            class="carousel--thumbnail__size"
                            style=""
                            v-bind:src="
                              'image/' +
                              this.PickupArticleList[0].thumbnail_img_path.slice(
                                7
                              )
                            "
                            alt="記事サムネイル画像"
                          />
                        </div>
                        <div class="col-sm-6 offset-md-0 col-10 offset-1">
                          <!-- PICKUP -->
                          <p class="fuchidori font-fot-rodin font-size--28">
                            PICK UP
                          </p>
                          <!-- タイトル -->
                          <p class="mt-5 carousel__article--head">
                            {{ this.PickupArticleList[0].title }}
                          </p>
                          <!-- 本文 -->
                          <p
                            class="mt-5 carousel__article--text"
                            v-html="
                              removeHtmlTag(this.PickupArticleList[0].content)
                            "
                          ></p>
                          <!-- 続きを読む -->
                          <router-link
                            :to="{
                              name: 'ArticleDetail',
                              params: { id: this.PickupArticleList[0].id },
                            }"
                          >
                            <p class="text-right">
                              <a class="carousel__article--link"
                                >続きを読む<span class="mr-1"></span
                                ><i class="fa fa-angle-right"></i
                              ></a>
                            </p>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 2記事目 -->
                  <div class="carousel-item">
                    <div v-if="this.PickupArticleList[1]">
                      <div
                        class="row region__control mt-5 mb-5 top--background"
                      >
                        <!-- サムネ画像 -->
                        <div
                          class="
                            col-sm-4
                            offset-sm-1
                            col-10
                            offset-1
                            d-flex
                            align-items-center
                            justify-content-center
                          "
                        >
                          <img
                            class="carousel--thumbnail__size"
                            style=""
                            v-bind:src="
                              'image/' +
                              this.PickupArticleList[1].thumbnail_img_path.slice(
                                7
                              )
                            "
                            alt="記事サムネイル画像"
                          />
                        </div>
                        <div class="col-sm-6 offset-md-0 col-10 offset-1">
                          <!-- PICKUP -->
                          <p class="fuchidori font-fot-rodin font-size--28">
                            PICK UP
                          </p>
                          <!-- タイトル -->
                          <p class="mt-5 carousel__article--head">
                            {{ this.PickupArticleList[1].title }}
                          </p>
                          <!-- 本文 -->
                          <p
                            class="mt-5 carousel__article--text"
                            v-html="
                              removeHtmlTag(this.PickupArticleList[1].content)
                            "
                          ></p>
                          <!-- 続きを読む -->
                          <router-link
                            :to="{
                              name: 'ArticleDetail',
                              params: { id: this.PickupArticleList[1].id },
                            }"
                          >
                            <p class="text-right">
                              <a class="carousel__article--link"
                                >続きを読む<span class="mr-1"></span
                                ><i class="fa fa-angle-right"></i
                              ></a>
                            </p>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 3記事目 -->
                  <div class="carousel-item">
                    <div v-if="this.PickupArticleList[2]">
                      <div
                        class="row region__control mt-5 mb-5 top--background"
                      >
                        <!-- サムネ画像 -->
                        <div
                          class="
                            col-sm-4
                            offset-sm-1
                            col-10
                            offset-1
                            d-flex
                            align-items-center
                            justify-content-center
                          "
                        >
                          <img
                            class="carousel--thumbnail__size"
                            style=""
                            v-bind:src="
                              'image/' +
                              this.PickupArticleList[2].thumbnail_img_path.slice(
                                7
                              )
                            "
                            alt="記事サムネイル画像"
                          />
                        </div>
                        <div class="col-sm-6 offset-md-0 col-10 offset-1">
                          <!-- PICKUP -->
                          <p class="fuchidori font-fot-rodin font-size--28">
                            PICK UP
                          </p>
                          <!-- タイトル -->
                          <p class="mt-5 carousel__article--head">
                            {{ this.PickupArticleList[2].title }}
                          </p>
                          <!-- 本文 -->
                          <p
                            class="mt-5 carousel__article--text"
                            v-html="
                              removeHtmlTag(this.PickupArticleList[2].content)
                            "
                          ></p>
                          <!-- 続きを読む -->
                          <router-link
                            :to="{
                              name: 'ArticleDetail',
                              params: { id: this.PickupArticleList[2].id },
                            }"
                          >
                            <p class="text-right">
                              <a class="carousel__article--link"
                                >続きを読む<span class="mr-1"></span
                                ><i class="fa fa-angle-right"></i
                              ></a>
                            </p>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="body__gap--control"></div>
        </section>
        <!-- 記事一覧 -->
        <section>
          <!-- カテゴリ-SP Begin-->
          <div class="col-md-3 col-11 order-3 articlelist__cotegory_SP">
            <h2 class="articlelist__cotegory">CATEGORY</h2>
            <div class="row region__control pl-2">
              <div class="col-md-12 col-6 p-0">
                <a class="article__cotegory--link" data-filter="all">
                  <p
                    @click="getSearchByCategoryList()"
                    class="article__cotegory--list cotegory__all"
                  >
                    すべて
                  </p>
                </a>
              </div>
              <div class="col-md-12 col-6 p-0">
                <a class="article__cotegory--link" data-filter="interview">
                  <p
                    @click="getSearchByCategoryList(1)"
                    class="article__cotegory--list cotegory__interview"
                  >
                    インタビュー
                  </p>
                </a>
              </div>
              <!-- <div class="col-md-12 col-6 p-0">
                <a class="article__cotegory--link" data-filter="program">
                  <p
                    @click="getSearchByCategoryList(2)"
                    class="article__cotegory--list cotegory__program"
                  >
                    特集・企画
                  </p>
                </a>
              </div>
              <div class="col-md-12 col-6 p-0">
                <a class="article__cotegory--link" data-filter="culture">
                  <p
                    @click="getSearchByCategoryList(3)"
                    class="article__cotegory--list cotegory__culture"
                  >
                    Miiitsカルチャー
                  </p>
                </a>
              </div>
              <div class="col-md-12 col-6 p-0">
                <a class="article__cotegory--link" data-filter="people">
                  <p
                    @click="getSearchByCategoryList(4)"
                    class="article__cotegory--list cotegory__people"
                  >
                    採用
                  </p>
                </a>
              </div> -->
              <div class="col-md-12 col-6 p-0">
                <a class="article__cotegory--link" data-filter="knowledge">
                  <p
                    @click="getSearchByCategoryList(5)"
                    class="article__cotegory--list cotegory__knowledge"
                  >
                    ナレッジ
                  </p>
                </a>
              </div>
            </div>
          </div>
          <!-- カテゴリ-SP End-->
          <div class="concontainer-fluid">
            <div class="row region__control">
              <div class="col-11 offset-1">
                <!-- 見出し・ドロップダウン -->
                <div class="row region__control">
                  <div class="col-md-8 col-12 order-md-1 order-2">
                    <div class="row region__control">
                      <div class="col-md-6 col-12 d-flex align-items-center">
                        <img
                          class="articlelist__head--icon"
                          src="@/assets/image/articlelist_head_icon.svg"
                        />
                        <div class="articlelist__head">記事一覧</div>
                      </div>
                      <!-- 表示順ドロップダウン -->
                      <div class="col-md-5 col-12 d-flex align-items-center">
                        <DropdownBox 
                         @setgood_sort="setgoodicon(1)"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- 記事リスト -->
                  <div class="col-md-8 col-12 ml-3 order-md-2 order-3">
                    <!-- 記事表示 -->
                    <div
                      class="row region__control article__margin"
                      v-for="(item, index) in this.$store.state.miiitsStore
                        .displayLists"
                      :key="index"
                      v-show="getResultLength() != 0"
                    >
                      <ArticleList
                        :index="index.toString()"
                        :id="item.id.toString()"
                        :title="item.title"
                        :category_id="item.category_id.toString()"
                        :like_count="item.like_count"
                        :add_datetime="item.add_datetime"
                        :thumbnail_img_path="item.thumbnail_img_path"
                        :src_goodicon="src_goodicon[5*(pageno - 1) + index]"
                        :btn_flg="btn_flg[5*(pageno - 1) + index]"
                        :number_flg="number_flg[5*(pageno - 1) + index]"
                        @likeoneplus="likeoneplus"
                        @emitEvent="getSearchByCategoryList(item.category_id)"
                      />
                    </div>
                    <!-- ページネーション -->
                    <div class="row region__control pb-5 mb-5">
                      <div
                        class="
                          col-sm-7 col-md-9
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                      >
                        <div class="pager">
                          <pagination @pageno="pagenoupd" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- カテゴリ -->
                  <div class="col-md-3 col-11 order-3 articlelist__cotegory_PC">
                    <h2 class="articlelist__cotegory">CATEGORY</h2>
                    <div class="row region__control pl-2">
                      <div class="col-md-12 col-6 p-0">
                        <a class="article__cotegory--link" data-filter="all">
                          <p
                            @click="getSearchByCategoryList()"
                            class="article__cotegory--list cotegory__all"
                          >
                            すべて
                          </p>
                        </a>
                      </div>
                      <div class="col-md-12 col-6 p-0">
                        <a
                          class="article__cotegory--link"
                          data-filter="interview"
                        >
                          <p
                            @click="getSearchByCategoryList(1)"
                            class="article__cotegory--list cotegory__interview"
                          >
                            インタビュー
                          </p>
                        </a>
                      </div>
                      <!-- <div class="col-md-12 col-6 p-0">
                        <a
                          class="article__cotegory--link"
                          data-filter="program"
                        >
                          <p
                            @click="getSearchByCategoryList(2)"
                            class="article__cotegory--list cotegory__program"
                          >
                            特集・企画
                          </p>
                        </a>
                      </div>
                      <div class="col-md-12 col-6 p-0">
                        <a
                          class="article__cotegory--link"
                          data-filter="culture"
                        >
                          <p
                            @click="getSearchByCategoryList(3)"
                            class="article__cotegory--list cotegory__culture"
                          >
                            Miiitsカルチャー
                          </p>
                        </a>
                      </div>
                      <div class="col-md-12 col-6 p-0">
                        <a class="article__cotegory--link" data-filter="people">
                          <p
                            @click="getSearchByCategoryList(4)"
                            class="article__cotegory--list cotegory__people"
                          >
                            採用
                          </p>
                        </a>
                      </div> -->
                      <div class="col-md-12 col-6 p-0">
                        <a
                          class="article__cotegory--link"
                          data-filter="knowledge"
                        >
                          <p
                            @click="getSearchByCategoryList(5)"
                            class="article__cotegory--list cotegory__knowledge"
                          >
                            ナレッジ
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="body__gap--control"></div>
        <!-- アクセスの多い記事 -->
        <section>
          <div class="row region__control">
            <div class="col-12">
              <div class="ranklist__title text-center">
                <img src="@/assets/image/access_title.png" alt="タイトル王冠" />
                <p>アクセスの多い記事</p>
              </div>
            </div>
          </div>
          <!-- 記事リンク -->
          <div class="article__ranklist">
            <!-- 1番目の記事 -->
            <div class="article__ranklist--box">
              <div v-if="ManyAccessArticleList[0]">
                <img
                  class="article__ranklist--flag"
                  src="@/assets/image/accessrank_1.svg"
                  alt="ランク旗1"
                />
                <!-- サムネ画像（リンク） -->
                <router-link
                      :to="{
                        name: 'ArticleDetail',
                        params: { id: this.ManyAccessArticleList[0].id },
                      }"
                    >
                  <img
                    class="article__ranklist--img"
                    v-bind:src="
                      'image/' +
                      this.ManyAccessArticleList[0].thumbnail_img_path.slice(7)
                    "
                    alt="記事サムネイル画像2"
                  />
                </router-link>
                <div class="row mt-3 px-5">
                  <div class="col-7 text-left">
                    <p class="coetgory__text mb-0">
                      {{
                        this.ManyAccessArticleList[0].category_id.category_name
                      }}
                    </p>
                  </div>
                  <div
                    class="
                      col-3
                      pb-0
                      text-right
                      font-color--orange
                      font-fot-rodin
                    "
                  >
                    <p class="mb-0">
                      {{
                        this.ManyAccessArticleList[0].add_datetime.slice(0, 4)
                      }}.{{
                        this.ManyAccessArticleList[0].add_datetime.slice(5, 7)
                      }}.{{
                        this.ManyAccessArticleList[0].add_datetime.slice(8, 10)
                      }}
                    </p>
                  </div>
                </div>
                <!-- 記事タイトル（リンク） -->
                <router-link
                  :to="{
                    name: 'ArticleDetail',
                    params: { id: this.ManyAccessArticleList[0].id },
                  }"
                >
                  <p class="article__ranklist--title px-5 mb-0">
                    {{ this.ManyAccessArticleList[0].title }}
                  </p>
                </router-link>
                <div class="row mt-4 px-5">
                  <div class="col-6 pb-0 text-left">
                    <p>
                      <button @click="likeplus(0)">
                        <div v-if="manybtn_flg[0]" class="goodbutton_stop">
                        <img class="good-icon" :src="src2[0]" width="17" height="15">{{
                          this.ManyAccessArticleList[0].like_count
                        }}</div>
                        <div v-if="!manybtn_flg[0]" class="goodbutton_anime">
                          <div class="heartsize">
                          <lottie
                            ref="anim"
                            :options="lottieOptions1"
                            v-on:animCreated="handleAnimation1"
                            class="likeshare_icon"
                          /></div>{{
                          this.ManyAccessArticleList[0].like_count
                        }}
                        </div>
                      </button>
                    </p>
                  </div>
                  <div class="col-6 pb-0 text-right">
                    <router-link
                      :to="{
                        name: 'ArticleDetail',
                        params: { id: this.ManyAccessArticleList[0].id },
                      }"
                    >
                      <p class="text-right">
                        <a class="articlelist--nextlink"
                          >記事を読む<i class="fa fa-angle-right articlelist__link--ml"></i
                        ></a>
                      </p>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <!-- 2番目の記事 -->
            <div class="article__ranklist--box">
              <div v-if="ManyAccessArticleList[1]">
                <img
                  class="article__ranklist--flag"
                  src="@/assets/image/accessrank_2.svg"
                  alt="ランク旗2"
                />
                <!-- サムネ画像（リンク） -->
                <router-link
                      :to="{
                        name: 'ArticleDetail',
                        params: { id: this.ManyAccessArticleList[1].id },
                      }"
                >
                  <img
                    class="article__ranklist--img"
                    v-bind:src="
                      'image/' +
                      this.ManyAccessArticleList[1].thumbnail_img_path.slice(7)
                    "
                    alt="記事サムネイル画像2"
                  />
                </router-link>
                <div class="row mt-3 px-5">
                  <div class="col-7 text-left">
                    <p class="coetgory__text mb-0">
                      {{
                        this.ManyAccessArticleList[1].category_id.category_name
                      }}
                    </p>
                  </div>
                  <div
                    class="
                      col-3
                      pb-0
                      text-right
                      font-color--orange
                      font-fot-rodin
                    "
                  >
                    <p class="mb-0">
                      {{
                        this.ManyAccessArticleList[1].add_datetime.slice(0, 4)
                      }}.{{
                        this.ManyAccessArticleList[1].add_datetime.slice(5, 7)
                      }}.{{
                        this.ManyAccessArticleList[1].add_datetime.slice(8, 10)
                      }}
                    </p>
                  </div>
                </div>
                <!-- 記事タイトル（リンク） -->
                <router-link
                      :to="{
                        name: 'ArticleDetail',
                        params: { id: this.ManyAccessArticleList[1].id },
                      }"
                >
                  <p class="article__ranklist--title px-5 mb-0">
                    {{ this.ManyAccessArticleList[1].title }}
                  </p>
                </router-link>
                <div class="row mt-4 px-5">
                  <div class="col-6 pb-0 text-left">
                    <p>
                      <button @click="likeplus(1)">
                        <div v-if="manybtn_flg[1]" class="goodbutton_stop">
                        <img class="good-icon" :src="src2[1]" width="17" height="15">{{
                          this.ManyAccessArticleList[1].like_count
                        }}</div>
                        <div v-if="!manybtn_flg[1]" class="goodbutton_anime">
                          <div class="heartsize">
                          <lottie
                            ref="anim"
                            :options="lottieOptions1"
                            v-on:animCreated="handleAnimation1"
                            class="likeshare_icon"
                          /></div>{{
                          this.ManyAccessArticleList[1].like_count
                        }}
                        </div>
                      </button>
                    </p>
                  </div>
                  <div class="col-6 pb-0 text-right">
                    <router-link
                      :to="{
                        name: 'ArticleDetail',
                        params: { id: this.ManyAccessArticleList[1].id },
                      }"
                    >
                      <p class="text-right">
                        <a class="articlelist--nextlink"
                          >記事を読む<i class="fa fa-angle-right articlelist__link--ml"></i
                        ></a>
                      </p>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <!-- 3番目の記事 -->
            <div class="article__ranklist--box">
              <div v-if="ManyAccessArticleList[2]">
                <img
                  class="article__ranklist--flag"
                  src="@/assets/image/accessrank_3.svg"
                  alt="ランク旗3"
                />
                <!-- サムネ画像（リンク） -->
                <router-link
                      :to="{
                        name: 'ArticleDetail',
                        params: { id: this.ManyAccessArticleList[2].id },
                      }"
                >
                  <img
                    class="article__ranklist--img"
                    v-bind:src="
                      'image/' +
                      this.ManyAccessArticleList[2].thumbnail_img_path.slice(7)
                    "
                    alt="記事サムネイル画像2"
                  />
                </router-link>
                <div class="row mt-3 px-5">
                  <div class="col-7 text-left">
                    <p class="coetgory__text mb-0">
                      {{
                        this.ManyAccessArticleList[2].category_id.category_name
                      }}
                    </p>
                  </div>
                  <div
                    class="
                      col-3
                      pb-0
                      text-right
                      font-color--orange
                      font-fot-rodin
                    "
                  >
                    <p class="mb-0">
                      {{
                        this.ManyAccessArticleList[2].add_datetime.slice(0, 4)
                      }}.{{
                        this.ManyAccessArticleList[2].add_datetime.slice(5, 7)
                      }}.{{
                        this.ManyAccessArticleList[2].add_datetime.slice(8, 10)
                      }}
                    </p>
                  </div>
                </div>
                <!-- 記事タイトル（リンク） -->
                <router-link
                      :to="{
                        name: 'ArticleDetail',
                        params: { id: this.ManyAccessArticleList[2].id },
                      }"
                >
                  <p class="article__ranklist--title px-5 mb-0">
                    {{ this.ManyAccessArticleList[2].title }}
                  </p>
                </router-link>
                <div class="row mt-4 px-5">
                  <div class="col-6 pb-0 text-left">
                    <p>
                      <button @click="likeplus(2)">
                        <div v-if="manybtn_flg[2]" class="goodbutton_stop">
                        <img class="good-icon" :src="src2[2]" width="17" height="15">{{
                          this.ManyAccessArticleList[2].like_count
                        }}</div>
                        <div v-if="!manybtn_flg[2]" class="goodbutton_anime">
                          <div class="heartsize">
                          <lottie
                            ref="anim"
                            :options="lottieOptions1"
                            v-on:animCreated="handleAnimation1"
                            class="likeshare_icon"
                          /></div>{{
                          this.ManyAccessArticleList[2].like_count
                        }}
                        </div>
                      </button>
                    </p>
                  </div>
                  <div class="col-6 pb-0 text-right">
                    <router-link
                      :to="{
                        name: 'ArticleDetail',
                        params: { id: this.ManyAccessArticleList[2].id },
                      }"
                    >
                      <p class="text-right">
                        <a class="articlelist--nextlink"
                          >記事を読む<i class="fa fa-angle-right articlelist__link--ml"></i
                        ></a>
                      </p>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="body__gap--control"></div>
    </div>
    <Footer />
  </v-app>
</template>

<script>
import Header from "@/components/molecules/Header.vue";
import Footer from "@/components/molecules/Footer.vue";
import Pagination from "@/components/molecules/Pagination.vue";
import DropdownBox from "@/components/molecules/DropdownBox.vue";
import ArticleList from "@/components/molecules/ArticleList.vue";
import Lottie from 'vue-lottie';
import animationData1 from '@/assets/image/goodicon_anime.json';
import * as PickupArticleList2API from "../../../apis/PickUpArticleAPI";
import * as ApiMiiitsSearchList from "@/apis/ApiMiiitsSearchList";
import * as ManyAccessArticleListAPI from "../../../apis/ManyAccessArticleListAPI";
import * as LikeCountUpdateAPI from "../../../apis/LikeCountUpdateAPI";

export default {
  name: "app",
  components: { Footer, Header, Pagination, ArticleList, DropdownBox, Lottie},
  data() {
    return {
      articleSearchResultArray: [],
      displayLists: [],
      dataFromHeader: "",
      selectedSort: "",
      categoryArray: [],
      ManyAccessArticleList: [],
      PickupArticleList: [],
      imgPath: process.env.VUE_APP_IMG_BASE,
      pageno: "",
      src2:[],
      src_goodicon:[],
      btn_flg:[],
      manybtn_flg:[],
      number_flg:[],
      lottieOptions1: {
          animationData: animationData1,
          loop: false,
      },
    };
  },
  created: function () {
    ManyAccessArticleListAPI.getManyAccessArticleList()
      .then((response) => {
        this.ManyAccessArticleList = response.payload.data;
        this.goodicon_many_first(0);
        this.goodicon_many_first(1);
        this.goodicon_many_first(2);

      })
      .catch((error) => {
        console.log("Error : " + error.response);
      });

    PickupArticleList2API.getPickupArticleList2()
      .then((response) => {
        this.PickupArticleList = response.payload.data;
      })
      .catch((error) => {
        console.log("Error : " + error.response);
      });
    this.manybtn_flg[0] = true;
    this.manybtn_flg[1] = true;
    this.manybtn_flg[2] = true;
  },
  methods: {
    handleAnimation1: function (anim) {
      this.anim = anim;
    },

    // カテゴリ検索
    getSearchByCategoryList(category) {
      if (category) {
        ApiMiiitsSearchList.getArticleCategory("miiitsArticleList")
          .then((response) => {
            this.articleSearchResultArray = response.payload.data;
            this.articleSearchResultArray =
              this.articleSearchResultArray.filter(function (value) {
                return (
                  value.del_flg === false && value.category_id === category
                );
              });

            // 処理したデータをstoreに保存する
            this.setDisplayListsToStore(this.articleSearchResultArray);

            // デフォルトの並び順：新着順
            this.setSortToDisplay("sortByAddDate");
          })
          .catch((error) => {
            console.log("Error : " + error.response);
          });
      } else {
        this.getDisplayArrayByNull(category);
      }
    },
    // カテゴリ検索が空の場合、全件表示
    getDisplayArrayByNull(input) {
      if (!input) {
        ApiMiiitsSearchList.getMiiitsArticle("miiitsArticleList")
          .then((response) => {
            this.articleSearchResultArray = response.payload.data;
            this.articleSearchResultArray =
              this.articleSearchResultArray.filter(function (value) {
                return value.del_flg === false;
              });

            // 処理したデータをstoreに保存する
            this.setDisplayListsToStore(this.articleSearchResultArray);

            // デフォルトの並び順：新着順
            this.setSortToDisplay("sortByAddDate");
            this.setgoodicon(this.pageno);
          })
          .catch((error) => {
            console.log("Error : " + error.response);
          });
      }
    },

    // APIで取得配列をstoreに保存処理
    setDisplayListsToStore(articleSearchResultArray) {
      // 取得配列をstoreに保存する（分割する前）
      this.$store.dispatch(
        "miiitsStore/setArticleArrays",
        articleSearchResultArray
      );

      // 取得件数をstoreに保存する（分割する前）
      this.$store.dispatch(
        "miiitsStore/setTotalCount",
        this.articleSearchResultArray.length
      );

      // 記事は5件ずつで表示
      if (this.$store.state.miiitsStore.displayLimit != 5) {
        this.$store.dispatch("miiitsStore/setDisplayLimit", 5);
      }

      // 配列を分割して、DisplayListsに保存する
      this.$store.dispatch(
        "miiitsStore/setDisplayLists",
        this.articleSearchResultArray.slice(
          0,
          this.$store.state.miiitsStore.displayLimit
        )
      );
    },

    // ソート順処理(デフォルト：新着順)
    setSortToDisplay(sortBy) {
      let sortArray = this.$store.state.miiitsStore.articleArrays;
      // 選択したソートをstoreに保存
      this.$store.dispatch("miiitsStore/setSort", sortBy);
      if (sortBy == "sortByAccessCountCount" || sortBy === 2) {
        // 人気順：いいね数＋アクセス数
        sortArray.sort(function (a, b) {
          return (
            parseInt(b.access_count) +
            parseInt(b.like_count) -
            (parseInt(a.access_count) + parseInt(a.like_count))
          );
        });
        this.selectedSort = "人気順";

        //ソートした配列をstoreに保存する
        this.$store.dispatch(
          "miiitsStore/setDisplayLists",
          this.articleSearchResultArray.slice(
            0,
            this.$store.state.miiitsStore.displayLimit
          )
        );

        this.setgoodicon(1);
      } else {
        sortArray.sort(function (a, b) {
          return new Date(b.add_datetime) - new Date(a.add_datetime);
        });
        this.selectedSort = "新着順";

        //ソートした配列をstoreに保存する
        this.$store.dispatch(
          "miiitsStore/setDisplayLists",
          this.articleSearchResultArray.slice(
            0,
            this.$store.state.miiitsStore.displayLimit
          )
        );

        this.setgoodicon(1);
      }
    },
    getResultLength() {
      if (!this.articleSearchResultArray) {
        return 0;
      }
      return this.articleSearchResultArray.length;
    },

    //いいね機能（アクセスが多い記事）
    likeplus(id){
        var num = this.ManyAccessArticleList[id].id
        if(document.cookie.indexOf('article'+ num + '=liked') == -1){
            LikeCountUpdateAPI.editLikeCount(num)
            .then(() => {
                this.ManyAccessArticleList[id].like_count = (Number(this.ManyAccessArticleList[id].like_count) + 1).toString();
                document.cookie ='article'+ num + '=liked';
                this.manybtn_flg[id] = false;
                for(let j=0; j < 5;j++){
                  if(this.articleSearchResultArray[(this.pageno-1)*this.$store.state.miiitsStore.displayLimit + j].id == num){
                    this.src_goodicon[(this.pageno-1)*this.$store.state.miiitsStore.displayLimit + j] = require("@/assets/image/goodicon_after.svg");
                    this.articleSearchResultArray[(this.pageno-1)*this.$store.state.miiitsStore.displayLimit + j].like_count = (Number(this.articleSearchResultArray[(this.pageno-1)*this.$store.state.miiitsStore.displayLimit + j].like_count) + 1).toString();
                  }
                }
            })
            .catch(error => {
                console.log("Error : " + error.response);
            });
        }
    },

    removeHtmlTag: function (content) {
      var regEx = /<[^>]*>/g;
      let str = content;
      str = str.replace(regEx, "");
      return str;
    },

    //現ページ数取得
    pagenoupd(arg) {
      this.pageno = arg;
      this.setgoodicon(this.pageno);
    },

    //いいね機能（記事一覧）
    likeoneplus(arg1,arg2){
        let a = Number(arg2) + (this.$store.state.miiitsStore.displayLimit)*(Number(this.pageno) - 1);
        this.articleSearchResultArray[a].like_count = arg1;
        this.btn_flg[a] = false;
        //アクセス数の多い記事のいいねボタンにも反映する
        for(let i = 0;i < 3;i++){
          if(this.articleSearchResultArray[a].id == this.ManyAccessArticleList[i].id){
            this.src2[i] = require("@/assets/image/goodicon_after.svg");
            this.ManyAccessArticleList[i].like_count = (Number(this.ManyAccessArticleList[i].like_count) + 1).toString();
          }
        }
    },

    //ページを読み込んだ際の良いね済み記事のセット（アクセスの多い記事）
    goodicon_many_first(index){
        if(document.cookie.indexOf('article'+ this.ManyAccessArticleList[index].id + '=liked') != -1){
            this.src2[index] = require("@/assets/image/goodicon_after.svg");
        }else{
            this.src2[index] = require("@/assets/image/goodicon_before.svg");
        }
    },

    //ページを読み込んだ際の良いね済み記事のセット（記事一覧）
    setgoodicon(pageno){
      let limit = this.$store.state.miiitsStore.displayLimit;
      let max = pageno*limit;
      if(this.articleSearchResultArray.length < max){
        max = this.articleSearchResultArray.length;
      }
      for(var i = (pageno - 1)*limit; i < max; i++){
        if(document.cookie.indexOf('article'+ this.articleSearchResultArray[i].id + '=liked') == -1){
          this.src_goodicon[i] = require("@/assets/image/goodicon_before.svg")
          this.btn_flg[i] = true;
          if(Number(this.articleSearchResultArray[i].like_count) < 9){
            this.number_flg[i] = true;
          }else{
            this.number_flg[i] = false;
          }
        }else{
          this.src_goodicon[i] = require("@/assets/image/goodicon_after.svg")
          this.btn_flg[i] = true;
          if(Number(this.articleSearchResultArray[i].like_count) < 9){
            this.number_flg[i] = true;
          }else{
            this.number_flg[i] = false;
          }
        }
     }
    },

  },
  mounted() {
    this.getDisplayArrayByNull(null);
    this.pageno = "1";
  }
  };
</script>

<!-- 共通CSS -->
<style src="../miiits/css/common.css"></style>

<!-- ローカルCSS -->
<style scoped>
.body__wrap {
  overflow: hidden;
}

/* ================================ * /
            カルーセル
==================================== */
/* 背景 */
.carousel__background {
  background-image: url("../../../assets/image/carousel_background_lg.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

/* sm */
@media (max-width: 1280px) {
  .carousel__background {
  background-image: url("../../../assets/image/carousel_background.svg");
  }
}


/* サムネイル画像 */
.carousel--thumbnail__size {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  -o-object-fit: cover;
  object-fit: cover;
}

/* sm */
@media (max-width: 600px) {
  .carousel--thumbnail__size {
    height: 200px;
  }
}

/* カルーセル枠 */
.carousel__flame {
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
}

/* カルーセル中身大きさ(既存クラス編集) */
.carousel-inner {
  width: 90%;
  margin: 0 auto;
}

.carousel__inner--height {
  height: 410px;
}

/* sm */
@media (max-width: 600px) {
  .carousel__inner--height {
    height: 620px;
  }
}

/* 記事タイトル調整 */
.carousel__article--head {
  color: #333333;
  font-size: 30px;
  font-weight: 600;
  line-height: 35px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
/* 1500px以上 */
@media (min-width: 1500px) {
  .carousel__article--head {
    font-size: 40px;
    line-height: 45px;
  }
}

/* md */
@media (max-width: 768px) {
  .carousel__article--head {
    font-size: 22px;
    line-height: 35px;
  }
}

/* SP */
@media (max-width: 420px) {
  .carousel__article--head {
    font-size: 20px;
    line-height: 30px;
  }
}

/* 記事本文 */
.carousel__article--text {
  color: #333333;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

/* SP */
@media (max-width: 420px) {
  .carousel__article--text {
    font-size: 14px;
    line-height: 20px;
  }
}

/* 続きを読む */
.carousel__article--link {
  text-decoration: none;
  font-weight: 800;
  color: #db4627;
  font-size: 20px;
}

@media (min-width: 1100px) {
  .carousel__article--link {
    font-size: 20px;
  }
}

/* カルーセルコントロールボタン */
.carousel-control-prev-icon {
  background-image: url("../../../assets/image/carousel_prev.svg");
  top: 46%;
  bottom: 50%;
  opacity: 1;
  right: 97%;
  position: absolute;
  height: 70px;
  width: 70px;
  z-index: 99;
}

@media (max-width: 420px) {
  .carousel-control-prev-icon {
    right: 91%;
    height: 50px;
    width: 50px;
  }
}

.carousel-control-next-icon {
  background-image: url("../../../assets/image/carousel_next.svg");
  top: 46%;
  bottom: 50%;
  opacity: 1;
  right: -3.5%;
  position: absolute;
  height: 70px;
  width: 70px;
  z-index: 99;
}

@media (max-width: 420px) {
  .carousel-control-next-icon {
    right: -8.5%;
    height: 50px;
    width: 50px;
  }
}

/* カルーセルインジケーター */
.carousel__indicators--btn {
  background-color: #999;
  border-radius: 1px;
}

.carousel-indicators .active {
  background-color: #db4627;
}

/* ================================ * /
            アクセスの多い記事
==================================== */
/* タイトル */
.ranklist__title {
  position: relative;
}

.ranklist__title p {
  display: inline-block;
  color: #db4627;
  font-weight: 600;
  font-size: 24px;
  background-color: #fff;
  border-radius: 28px;
  padding: 25px;
}

.ranklist__title img {
  position: absolute;
  width: 60px;
  height: auto;
  top: -10%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

/* アクセスの～記事親 */
.article__ranklist {
  display: flex; /* 子要素を横並べ */
  flex-wrap: wrap;
  justify-content: center;
}

/* アクセスの～記事一覧 */
.article__ranklist--box {
  width: 300px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  position: relative;
  flex-direction: column; /* 子要素を縦並べ */
  min-width: 200px;
  margin: 1%;
  padding-bottom: 1.5rem;
}

@media (max-width: 957px) {
  .article__ranklist--box {
    margin: 4%;
  }
}

@media (max-width: 420px) {
  .article__ranklist--box {
    margin: 8%;
  }
}

/* アクセスの多い記事サムネイル画像 */
.article__ranklist--img {
  width: 100%;
  height: 200px;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
}

/* カテゴリ名 */
.coetgory__text {
  background-color: #db4627;
  color: #fff;
  display: inline-block;
  border-radius: 10px;
  text-align: center;
  padding-inline: 0.5rem;
  font-weight: 500;
  font-size: 14px;
  padding: 3px 15px;
}

/* 記事タイトル */
.article__ranklist--title {
  color: #333333;
  font-weight: 600;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: 0.7rem;
}

/* ================================ * /
            記事リスト部
==================================== */
/* ドロップダウン */
.articlelistst__dropdown {
  background-color: #fff;
  color: #db4627;
  border-color: transparent;
  transition: 1s all;
  border-radius: 15px;
}
@media (max-width: 992px) {
  .articlelistst__dropdown {
    margin-bottom: 2rem;
  }
}

.articlelistst__dropdown:after {
  margin-left: 5em;
}

.articlelistst__dropdown:focus {
  box-shadow: 0 0 0 0 transparent;
}

/* 既存クラス上書き */
.dropdown-menu {
  color: #db4627;
  padding-left: 0;
  min-width: 10.5rem;
}

/* 続きを読む */
.articlelist--nextlink {
  text-decoration: none;
  font-weight: 600;
  color: #db4627;
  font-size: 15px;
}

/* 記事見出し */
.articlelist__head {
  color: #db4627;
  font-size: 24px;
  font-weight: 600;
}

.articlelist__head--icon {
  width: 70px;
  padding-right: 0.5rem;
  height: auto;
}

.articlelist__head--icon {
  width: 70px;
  height: auto;
}

@media (max-width: 768px) {
  .articlelist__head--icon {
    width: 70px;
  }
}

/* カテゴリ見出し */
.articlelist__cotegory {
  color: #db4627;
  border-bottom: 2px solid #db4627;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 5px;
  position: relative;
  padding-left: 2rem;
}

@media (max-width: 1365px) {
  .articlelist__cotegory {
    font-size: 20px;
    padding-bottom: 15px;
    margin-bottom: 1.5rem;
  }
  .articlelist__cotegory:before {
    height: 22px;
    width: 22px;
    left: 0;
    position: absolute;
    top: 2px;
  }
}

.articlelist__cotegory:before {
  background: #db4627;
  content: "";
  height: 24px;
  width: 24px;
  left: 0;
  position: absolute;
  top: 0;
  border-radius: 3px;
}

.article__cotegory--link {
  text-decoration: none;
  color: #db4627;
  font-weight: 500;
}

.article__cotegory--link :hover {
  color: #e79b8c;
  transition: 0.3s;
}

/* 記事リスト間margin */
.article__margin {
  margin-bottom: 70px;
}

/* カテゴリアイコン（ALL） */
.cotegory__all {
  background-image: url("../../../assets/image/category_icon_all.png");
}

@media (min-width: 1200px) {
  .cotegory__all {
    background-size: 12%;
  }
}

/* カテゴリアイコン（インタビュー） */
.cotegory__interview {
  background-image: url("../../../assets/image/category_icon_interview.png");
}

@media (min-width: 1200px) {
  .cotegory__interview {
    background-size: 12%;
  }
}

/* カテゴリアイコン（特集・企画） */
.cotegory__program {
  background-image: url("../../../assets/image/category_icon_program.png");
}

@media (min-width: 1200px) {
  .cotegory__program {
    background-size: 12%;
  }
}

/* カテゴリアイコン（Miiitsカルチャー） */
.cotegory__culture {
  background-image: url("../../../assets/image/category_icon_miiits.png");
}

@media (min-width: 1200px) {
  .cotegory__culture {
    background-size: 12%;
  }
}

/* カテゴリアイコン（Miiitsカルチャー） */
.cotegory__people {
  background-image: url("../../../assets/image/category_icon_people.png");
}

@media (min-width: 1200px) {
  .cotegory__people {
    background-size: 12%;
  }
}

/* カテゴリアイコン（ナレッジ） */
.cotegory__knowledge {
  background-image: url("../../../assets/image/category_icon_knowledge.png");
}

@media (min-width: 1200px) {
  .cotegory__knowledge {
    background-size: 12%;
  }
}

/* 記事一覧 */
.article__list--box--img {
  background: #fff;
  border-radius: 15px 15px 0px 0px;
  margin: -1%;
}

.article__list--box--content {
  background: #fff;
  border-radius: 0px 10px 10px 0px;
  margin: -1%;
}

@media (min-width: 1367px) {
  .article__list--box--img {
    border-radius: 15px 15px 0px 0px;
  }

  .article__list--box--content {
    border-radius: 0px 15px 15px 0px;
  }
}

.article__list--img {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  -o-object-fit: cover;
  object-fit: cover;
  padding: 0.1rem;
}

@media (max-width: 992px) {
  .article__list--date_SP {
    margin-left: -1rem;
  }
}

.goodbutton_stop{
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1.5px;
  margin-left: 1px;
}

.goodbutton_anime {
  width:42px;
  height: 24px;
  display: flex;
  align-items: flex-end;
}

.heartsize{
  width: 24.873px;
  height: 25.869px;
}
</style>