<template>
  <v-app>
    <Header />
    <div class="body__wrap background__beige">
      <div class="body__gap--control"></div>
      <div class="concontainer-fluid font-ryo-gothic">
        <!-- 記事一覧 -->
        <section>
          <!-- カテゴリ-SP Begin-->
          <div class="col-md-3 col-11 order-3 articlelist__cotegory_SP">
            <h2 class="articlelist__cotegory">CATEGORY</h2>
            <div class="row region__control pl-2">
              <div class="col-md-12 col-6 p-0">
                <a class="article__cotegory--link" data-filter="all">
                  <p
                    @click="getSearchByCategoryList()"
                    class="article__cotegory--list cotegory__all"
                  >
                    すべて
                  </p>
                </a>
              </div>
              <div class="col-md-12 col-6 p-0">
                <a class="article__cotegory--link" data-filter="interview">
                  <p
                    @click="getSearchByCategoryList(1)"
                    class="article__cotegory--list cotegory__interview"
                  >
                    インタビュー
                  </p>
                </a>
              </div>
              <!-- <div class="col-md-12 col-6 p-0">
                <a class="article__cotegory--link" data-filter="program">
                  <p
                    @click="getSearchByCategoryList(2)"
                    class="article__cotegory--list cotegory__program"
                  >
                    特集・企画
                  </p>
                </a>
              </div>
              <div class="col-md-12 col-6 p-0">
                <a class="article__cotegory--link" data-filter="culture">
                  <p
                    @click="getSearchByCategoryList(3)"
                    class="article__cotegory--list cotegory__culture"
                  >
                    Miiitsカルチャー
                  </p>
                </a>
              </div>
              <div class="col-md-12 col-6 p-0">
                <a class="article__cotegory--link" data-filter="people">
                  <p
                    @click="getSearchByCategoryList(4)"
                    class="article__cotegory--list cotegory__people"
                  >
                    採用
                  </p>
                </a>
              </div> -->
              <div class="col-md-12 col-6 p-0">
                <a class="article__cotegory--link" data-filter="knowledge">
                  <p
                    @click="getSearchByCategoryList(5)"
                    class="article__cotegory--list cotegory__knowledge"
                  >
                    ナレッジ
                  </p>
                </a>
              </div>
            </div>
          </div>
          <!-- カテゴリ-SP End-->
          <div class="concontainer-fluid">
            <div class="row region__control">
              <div class="col-11 offset-1">
                <!-- 見出し -->
                <div class="row region__control">
                  <div
                    class="
                      col-md-8 col-12
                      d-flex
                      align-items-center
                      order-md-1 order-2
                    "
                  >
                    <img
                      class="articlelist__head--icon"
                      src="@/assets/image/articlelist_head_icon.svg"
                    />
                    <div class="articlelist__head">
                      ' {{ showKeyword }} ' 検索結果
                    </div>
                  </div>
                </div>
                <!-- 記事が見つかりました・ドロップダウン -->
                <div class="row region__control">
                  <div class="col-md-9 col-12 order-md-1 order-2">
                    <div class="row region__control">
                      <div class="col-md-6 col-12 d-flex align-items-center">
                        <div class="font-color--orange font-size--18">
                          {{ this.$store.state.miiitsStore.totalCount }}
                          件の記事が見つかりました。
                        </div>
                      </div>
                      <!-- 表示順ドロップダウン -->
                      <div class="col-md-5 col-12 d-flex align-items-center">
                        <DropdownBox />
                      </div>
                    </div>
                  </div>
                  <!-- 記事リスト -->
                  <div class="col-md-8 col-12 order-md-2 order-3">
                    <!-- ロード進捗 -->
                    <div class="text-center" v-if="loadFinish">
                      <v-progress-circular
                        indeterminate
                        color="#DB4627"
                      ></v-progress-circular>
                    </div>
                    <!-- 記事表示 -->
                    <div
                      class="row region__control article__margin"
                      v-for="(item, index) in this.$store.state.miiitsStore
                        .displayLists"
                      :key="index"
                      v-show="getResultLength() != 0"
                    >
                      <!-- サムネ画像 -->
                      <v-col
                        class="
                          col-11 col-sm-3
                          d-flex
                          align-items-center
                          justify-content-center
                          article__list--box--img
                        "
                      >
                        <router-link
                          class="articlelist--nextlink"
                          :to="{
                            name: 'ArticleDetail',
                            params: { id: item.id },
                          }"
                        >
                          <img
                            class="article__list--img"
                            v-bind:src="getThumbnailImgPath(item.id)"
                            alt="記事サムネイル画像2"
                          />
                        </router-link>
                      </v-col>
                      <!-- 記事タイトル・本文・リンク -->
                      <v-col
                        class="
                          col-sm-7 col-11
                          d-flex
                          align-items-start
                          justify-content-center
                          article__list--box--content
                        "
                      >
                        <v-row class="row region__control">
                          <v-col class="col-6 text-left">
                            <p
                              @click="getSearchByCategoryList(item.category_id)"
                              class="coetgory__text mb-0"
                            >
                              {{ transCategory(item.category_id) }}
                            </p>
                          </v-col>
                          <v-col
                            class="
                              col-6
                              pb-0
                              text-right
                              font-color--orange
                              font-fot-rodin
                            "
                          >
                            <p class="mb-0 article__list--date_SP">
                              {{ item.add_datetime | moment }}
                            </p>
                          </v-col>
                          <router-link
                            class="articlelist--nextlink"
                            :to="{
                              name: 'ArticleDetail',
                              params: { id: item.id },
                            }"
                          >
                            <p class="article__list--title px-5 mb-0">
                              {{ item.title }}
                            </p>
                          </router-link>
                          <v-col class="col-6 py-0 text-left px-5">
                            <p>
                              <button @click="plus(item.id, index)">
                                <div
                                  v-if="btn_flg[(pageno - 1) * 5 + index]"
                                  class="goodbutton_stop"
                                >
                                  <img
                                    :src="
                                      src_goodicon[(pageno - 1) * 5 + index]
                                    "
                                    width="17"
                                    height="15"
                                  />
                                  {{ item.like_count }}
                                </div>
                                <div
                                  v-if="!btn_flg[(pageno - 1) * 5 + index]"
                                >
                                 <div v-if="!number_flg[(pageno - 1) * 5 + index]"
                                  class="goodbutton_anime">
                                  <div class="heartsize">
                                    <lottie
                                      ref="anim"
                                      :options="lottieOptions1"
                                      v-on:animCreated="handleAnimation1"
                                      class="likeshare_icon"
                                    />
                                  </div>
                                  {{ item.like_count }}
                                </div>
                                <div v-if="number_flg[(pageno - 1) * 5 + index]"
                                  class="goodbutton_anime">
                                  <div class="heartsize2">
                                    <lottie
                                      ref="anim"
                                      :options="lottieOptions1"
                                      v-on:animCreated="handleAnimation1"
                                      class="likeshare_icon"
                                    />
                                  </div><div class="onenumber">
                                  {{ item.like_count }}
                                </div></div>
                                </div>
                              </button>
                            </p>
                          </v-col>
                          <v-col class="col-6 py-0 text-right pt-1">
                            <p class="text-right m-0 pt-3">
                              <router-link
                                class="articlelist--nextlink"
                                :to="{
                                  name: 'ArticleDetail',
                                  params: { id: item.id },
                                }"
                              >
                                記事を読む<i
                                  class="
                                    fa fa-angle-right
                                    articlelist__link--ml
                                  "
                                ></i>
                              </router-link>
                            </p>
                          </v-col>
                        </v-row>
                      </v-col>
                    </div>
                    <!-- ページネーション -->
                    <div class="row region__control pb-5 mb-5">
                      <div
                        class="
                          col-sm-7 col-md-9
                          d-flex
                          align-items-center
                          justify-content-center
                        "
                      >
                        <div class="pager">
                          <pagination @pageno="pagenoupd" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- カテゴリ -->
                  <div class="col-md-3 col-11 order-3 articlelist__cotegory_PC">
                    <h2 class="articlelist__cotegory">CATEGORY</h2>
                    <div class="row region__control pl-2">
                      <div class="col-md-12 col-6 p-0">
                        <a class="article__cotegory--link" data-filter="all">
                          <p
                            @click="getSearchByCategoryList(0)"
                            class="article__cotegory--list cotegory__all"
                          >
                            すべて
                          </p>
                        </a>
                      </div>
                      <div class="col-md-12 col-6 p-0">
                        <a
                          class="article__cotegory--link"
                          data-filter="interview"
                        >
                          <p
                            @click="getSearchByCategoryList(1)"
                            class="article__cotegory--list cotegory__interview"
                          >
                            インタビュー
                          </p>
                        </a>
                      </div>
                      <!-- <div class="col-md-12 col-6 p-0">
                        <a
                          class="article__cotegory--link"
                          data-filter="program"
                        >
                          <p
                            @click="getSearchByCategoryList(2)"
                            class="article__cotegory--list cotegory__program"
                          >
                            特集・企画
                          </p>
                        </a>
                      </div>
                      <div class="col-md-12 col-6 p-0">
                        <a
                          class="article__cotegory--link"
                          data-filter="culture"
                        >
                          <p
                            @click="getSearchByCategoryList(3)"
                            class="article__cotegory--list cotegory__culture"
                          >
                            Miiitsカルチャー
                          </p>
                        </a>
                      </div>
                      <div class="col-md-12 col-6 p-0">
                        <a class="article__cotegory--link" data-filter="people">
                          <p
                            @click="getSearchByCategoryList(4)"
                            class="article__cotegory--list cotegory__people"
                          >
                            採用
                          </p>
                        </a>
                      </div> -->
                      <div class="col-md-12 col-6 p-0">
                        <a
                          class="article__cotegory--link"
                          data-filter="knowledge"
                        >
                          <p
                            @click="getSearchByCategoryList(5)"
                            class="article__cotegory--list cotegory__knowledge"
                          >
                            ナレッジ
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="body__gap--control"></div>
        <!-- アクセスの多い記事 -->
        <section>
          <div class="row region__control">
            <div class="col-12">
              <div class="ranklist__title text-center">
                <img src="@/assets/image/access_title.png" alt="タイトル王冠" />
                <p>アクセスの多い記事</p>
              </div>
            </div>
          </div>
          <!-- 記事リンク -->
          <div class="article__ranklist">
            <!-- 1記事目 -->
            <div
              class="article__ranklist--box"
              v-if="acticleByAccessCountArray[0]"
            >
              <img
                class="article__ranklist--flag"
                src="@/assets/image/accessrank_1.svg"
                alt="ランク旗1"
              />
              <!-- サムネ画像（リンク） -->
              <router-link
                class="articlelist--nextlink"
                :to="{
                  name: 'ArticleDetail',
                  params: { id: acticleByAccessCountArray[0].id },
                }"
              >
                <img
                  class="article__ranklist--img"
                  :src="
                    getAccessThumbnailImgPath(acticleByAccessCountArray[0].id)
                  "
                  alt="記事サムネイル画像2"
                />
              </router-link>
              <div class="row mt-3 px-5">
                <div class="col-7 text-left">
                  <p class="coetgory__text mb-0">
                    {{
                      transCategory(acticleByAccessCountArray[0].category_id)
                    }}
                  </p>
                </div>
                <div
                  class="
                    col-3
                    pb-0
                    text-right
                    font-color--orange
                    font-fot-rodin
                  "
                >
                  <p class="mb-0">
                    {{ acticleByAccessCountArray[0].add_datetime | moment }}
                  </p>
                </div>
              </div>
              <!-- 記事タイトル（リンク） -->
              <router-link
                class="articlelist--nextlink"
                :to="{
                  name: 'ArticleDetail',
                  params: { id: acticleByAccessCountArray[0].id },
                }"
              >
                <p class="article__ranklist--title px-5 mb-0">
                  {{ acticleByAccessCountArray[0].title }}
                </p>
              </router-link>
              <div class="row mt-4 px-5">
                <div class="col-6 pb-0 text-left">
                  <p>
                    <button @click="likeplus(0)">
                      <div v-if="manybtn_flg[0]" class="goodbutton_stop2">
                        <img
                          class="good-icon"
                          :src="src2[0]"
                          width="17"
                          height="15"
                        />{{ acticleByAccessCountArray[0].like_count }}
                      </div>
                      <div v-if="!manybtn_flg[0]" class="goodbutton_anime">
                        <div class="heartsize">
                          <lottie
                            ref="anim"
                            :options="lottieOptions1"
                            v-on:animCreated="handleAnimation1"
                            class="likeshare_icon"
                          />
                        </div>
                        {{ acticleByAccessCountArray[0].like_count }}
                      </div>
                    </button>
                  </p>
                </div>
                <div class="col-6 pb-0 text-right">
                  <p class="text-right">
                    <router-link
                      class="articlelist--nextlink"
                      :to="{
                        name: 'ArticleDetail',
                        params: { id: acticleByAccessCountArray[0].id },
                      }"
                    >
                      記事を読む<i
                        class="fa fa-angle-right articlelist__link--ml"
                      ></i>
                    </router-link>
                  </p>
                </div>
              </div>
            </div>
            <!-- 2記事目 -->
            <div
              class="article__ranklist--box"
              v-if="acticleByAccessCountArray[1]"
            >
              <img
                class="article__ranklist--flag"
                src="@/assets/image/accessrank_2.svg"
                alt="ランク旗2"
              />
              <!-- サムネ画像（リンク） -->
              <router-link
                class="articlelist--nextlink"
                :to="{
                  name: 'ArticleDetail',
                  params: { id: acticleByAccessCountArray[1].id },
                }"
              >
                <img
                  class="article__ranklist--img"
                  :src="
                    getAccessThumbnailImgPath(acticleByAccessCountArray[1].id)
                  "
                  alt="記事サムネイル画像2"
                />
              </router-link>
              <div class="row mt-3 px-5">
                <div class="col-7 text-left">
                  <p class="coetgory__text mb-0">
                    {{
                      transCategory(acticleByAccessCountArray[1].category_id)
                    }}
                  </p>
                </div>
                <div
                  class="
                    col-3
                    pb-0
                    text-right
                    font-color--orange
                    font-fot-rodin
                  "
                >
                  <p class="mb-0">
                    {{ acticleByAccessCountArray[1].add_datetime | moment }}
                  </p>
                </div>
              </div>
              <!-- 記事タイトル（リンク） -->
              <router-link
                class="articlelist--nextlink"
                :to="{
                  name: 'ArticleDetail',
                  params: { id: acticleByAccessCountArray[1].id },
                }"
              >
                <p class="article__ranklist--title px-5 mb-0">
                  {{ acticleByAccessCountArray[1].title }}
                </p>
              </router-link>
              <div class="row mt-4 px-5">
                <div class="col-6 pb-0 text-left">
                  <p>
                    <button @click="likeplus(1)">
                      <div v-if="manybtn_flg[1]" class="goodbutton_stop2">
                        <img
                          class="good-icon"
                          :src="src2[1]"
                          width="17"
                          height="15"
                        />{{ acticleByAccessCountArray[1].like_count }}
                      </div>
                      <div v-if="!manybtn_flg[1]" class="goodbutton_anime">
                        <div class="heartsize">
                          <lottie
                            ref="anim"
                            :options="lottieOptions1"
                            v-on:animCreated="handleAnimation1"
                            class="likeshare_icon"
                          />
                        </div>
                        {{ acticleByAccessCountArray[1].like_count }}
                      </div>
                    </button>
                  </p>
                </div>
                <div class="col-6 pb-0 text-right">
                  <p class="text-right">
                    <router-link
                      class="articlelist--nextlink"
                      :to="{
                        name: 'ArticleDetail',
                        params: { id: acticleByAccessCountArray[1].id },
                      }"
                    >
                      記事を読む<i
                        class="fa fa-angle-right articlelist__link--ml"
                      ></i>
                    </router-link>
                  </p>
                </div>
              </div>
            </div>
            <!-- 3記事目 -->
            <div
              class="article__ranklist--box"
              v-if="acticleByAccessCountArray[2]"
            >
              <img
                class="article__ranklist--flag"
                src="@/assets/image/accessrank_3.svg"
                alt="ランク旗3"
              />
              <!-- サムネ画像（リンク） -->
              <router-link
                class="articlelist--nextlink"
                :to="{
                  name: 'ArticleDetail',
                  params: { id: acticleByAccessCountArray[2].id },
                }"
              >
                <img
                  class="article__ranklist--img"
                  :src="
                    getAccessThumbnailImgPath(acticleByAccessCountArray[2].id)
                  "
                  alt="記事サムネイル画像2"
                />
              </router-link>
              <div class="row mt-3 px-5">
                <div class="col-7 text-left">
                  <p class="coetgory__text mb-0">
                    {{
                      transCategory(acticleByAccessCountArray[2].category_id)
                    }}
                  </p>
                </div>
                <div
                  class="
                    col-3
                    pb-0
                    text-right
                    font-color--orange
                    font-fot-rodin
                  "
                >
                  <p class="mb-0">
                    {{ acticleByAccessCountArray[2].add_datetime | moment }}
                  </p>
                </div>
              </div>
              <!-- 記事タイトル（リンク） -->
              <router-link
                class="articlelist--nextlink"
                :to="{
                  name: 'ArticleDetail',
                  params: { id: acticleByAccessCountArray[2].id },
                }"
              >
                <p class="article__ranklist--title px-5 mb-0">
                  {{ acticleByAccessCountArray[2].title }}
                </p>
              </router-link>
              <div class="row mt-4 px-5">
                <div class="col-6 pb-0 text-left">
                  <p>
                    <button @click="likeplus(2)">
                      <div v-if="manybtn_flg[2]" class="goodbutton_stop2">
                        <img
                          class="good-icon"
                          :src="src2[2]"
                          width="17"
                          height="15"
                        />{{ acticleByAccessCountArray[2].like_count }}
                      </div>
                      <div v-if="!manybtn_flg[2]" class="goodbutton_anime">
                        <div class="heartsize">
                          <lottie
                            ref="anim"
                            :options="lottieOptions1"
                            v-on:animCreated="handleAnimation1"
                            class="likeshare_icon"
                          />
                        </div>
                        {{ acticleByAccessCountArray[2].like_count }}
                      </div>
                    </button>
                  </p>
                </div>
                <div class="col-6 pb-0 text-right">
                  <p class="text-right">
                    <router-link
                      class="articlelist--nextlink"
                      :to="{
                        name: 'ArticleDetail',
                        params: { id: acticleByAccessCountArray[2].id },
                      }"
                      >記事を読む<i
                        class="fa fa-angle-right articlelist__link--ml"
                      ></i>
                    </router-link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="body__gap--control"></div>
    </div>
    <Footer />
  </v-app>
</template>

<script>
import moment from "moment";
import Header from "@/components/molecules/Header.vue";
import Footer from "@/components/molecules/Footer.vue";
import Pagination from "@/components/molecules/Pagination.vue";
import DropdownBox from "@/components/molecules/DropdownBox.vue";
import Lottie from "vue-lottie";
import animationData1 from "@/assets/image/goodicon_anime.json";
import * as ApiMiiitsSearchList from "@/apis/ApiMiiitsSearchList";
import * as LikeCountUpdateAPI from "../../../apis/LikeCountUpdateAPI";
import * as ArticleDetailAPI from "@/apis/ArticleDetailAPI";

export default {
  components: { Footer, Header, Pagination, DropdownBox, Lottie },
  props: {},
  data() {
    return {
      articleSearchResultArray: [],
      displayLists: [],
      dataFromHeader: "",
      categoryArray: [],
      showKeyword: "",
      acticleByAccessCountArray: [],
      pageno: "",
      likecount: "",
      src_goodicon: [],
      btn_flg: [],
      manybtn_flg: [],
      src2: [],
      lottieOptions1: {
        animationData: animationData1,
        loop: false,
      },
      loadFinish: false,
      number_flg:[],
    };
  },
  filters: {
    // 日付フォーマット
    moment: function (date) {
      return moment(date).format("YYYY.MM.DD");
    },
  },
  methods: {
    // 検索処理
    articleSearch() {
      let keyword = this.$store.state.miiitsStore.textKeyword;
      // APIで検索結果を取得
      if (keyword != "") {
        // キーワード有
        this.showKeyword = this.$store.state.miiitsStore.textKeyword;
        ApiMiiitsSearchList.getMiiitsArticle("miiitsArticleList")
          .then((response) => {
            if (response.payload.status == 200) {
              this.articleSearchResultArray = response.payload.data;
              // 検索処理
              this.articleSearchResultArray =
                this.articleSearchResultArray.filter(function (value) {
                  return (
                    (value.del_flg === false && value.title.match(keyword)) ||
                    value.content.match(keyword)
                  );
                });

              // 処理したデータをstoreに保存する
              this.setDisplayListsToStore(this.articleSearchResultArray);

              // デフォルトの並び順：新着順
              this.setSortToDisplay("sortByAddDate");

              this.setgoodicon(1);
            } else {
              this.loadFinish = true;
            }
          })
          .catch((error) => {
            console.log("Error : " + error.response);
          });
      }
    },

    // カテゴリ検索
    getSearchByCategoryList(category) {
      if (category) {
        this.showKeyword = this.transCategory(category);
        ApiMiiitsSearchList.getArticleCategory("miiitsArticleList")
          .then((response) => {
            if (response.payload.status == 200) {
              this.articleSearchResultArray = response.payload.data;
              this.articleSearchResultArray =
                this.articleSearchResultArray.filter(function (value) {
                  return (
                    value.del_flg === false && value.category_id === category
                  );
                });

              // 処理したデータをstoreに保存する
              this.setDisplayListsToStore(this.articleSearchResultArray);

              // デフォルトの並び順：新着順
              this.setSortToDisplay("sortByAddDate");
            } else {
              this.loadFinish = true;
            }
          })
          .catch((error) => {
            console.log("Error : " + error.response);
          });
      } else if (!category) {
        this.showKeyword = "全カテゴリ";
        ApiMiiitsSearchList.getMiiitsArticle("miiitsArticleList")
          .then((response) => {
            if (response.payload.status == 200) {
              this.articleSearchResultArray = response.payload.data;
              this.articleSearchResultArray =
                this.articleSearchResultArray.filter(function (value) {
                  return value.del_flg === false;
                });

              // 処理したデータをstoreに保存する
              this.setDisplayListsToStore(this.articleSearchResultArray);

              // デフォルトの並び順：新着順
              this.setSortToDisplay("sortByAddDate");
            } else {
              this.loadFinish = true;
            }
          })
          .catch((error) => {
            console.log("Error : " + error.response);
          });
      }
    },

    // APIで取得配列をstoreに保存処理
    setDisplayListsToStore(articleSearchResultArray) {
      // 取得配列をstoreに保存する（分割する前）
      this.$store.dispatch(
        "miiitsStore/setArticleArrays",
        articleSearchResultArray
      );

      // 取得件数をstoreに保存する（分割する前）
      this.$store.dispatch(
        "miiitsStore/setTotalCount",
        this.articleSearchResultArray.length
      );

      // 記事は5件ずつで表示
      if (this.$store.state.miiitsStore.displayLimit != 5) {
        this.$store.dispatch("miiitsStore/setDisplayLimit", 5);
      }

      // 配列を分割して、DisplayListsに保存する
      this.$store.dispatch(
        "miiitsStore/setDisplayLists",
        this.articleSearchResultArray.slice(
          0,
          this.$store.state.miiitsStore.displayLimit
        )
      );
    },

    // ソート順処理(デフォルト：新着順)
    setSortToDisplay(sortBy) {
      let sortArray = this.$store.state.miiitsStore.articleArrays;
      // 選択したソートをstoreに保存
      this.$store.dispatch("miiitsStore/setSort", sortBy);
      if (sortBy == "sortByAccessCount" || sortBy === 2) {
        // 人気順：いいね数＋アクセス数
        sortArray.sort(function (a, b) {
          return (
            parseInt(b.access_count) +
            parseInt(b.like_count) -
            (parseInt(a.access_count) + parseInt(a.like_count))
          );
        });

        //ソートした配列をstoreに保存する
        this.$store.dispatch(
          "miiitsStore/setDisplayLists",
          sortArray.slice(0, this.$store.state.miiitsStore.displayLimit)
        );
      } else {
        sortArray.sort(function (a, b) {
          return new Date(b.add_datetime) - new Date(a.add_datetime);
        });

        //ソートした配列をstoreに保存する
        this.$store.dispatch(
          "miiitsStore/setDisplayLists",
          sortArray.slice(0, this.$store.state.miiitsStore.displayLimit)
        );
      }
    },

    // 記事画像パスを取得
    getThumbnailImgPath(articleId) {
      let step = this.articleSearchResultArray.length;
      for (var i = 0; i < step; i++) {
        const articleIdinArray = this.articleSearchResultArray[i].id;
        if (articleId == articleIdinArray) {
          const thumbnail_img_path =
            "image/" +
            this.articleSearchResultArray[i].thumbnail_img_path.slice(7);
          return thumbnail_img_path;
        }
      }
    },

    // アクセス数多い記事を取得
    getActicleByAccessCount() {
      ApiMiiitsSearchList.getMiiitsArticle("miiitsArticleList")
        .then((response) => {
          let acticleByAccessCountArrayBefore = response.payload.data;
          this.acticleByAccessCountArray = acticleByAccessCountArrayBefore
            .sort(function (a, b) {
              return b.access_count - a.access_count;
            })
            .slice(0, 3);
          this.setgoodicon2();
        })
        .catch((error) => {
          console.log("Error : " + error.response);
        });
    },

    // アクセス数多い記事の画像パスを取得
    getAccessThumbnailImgPath(articleId) {
      let step = this.acticleByAccessCountArray.length;
      for (var i = 0; i < step; i++) {
        const articleIdinArray = this.acticleByAccessCountArray[i].id;
        if (articleId == articleIdinArray) {
          const thumbnail_img_path =
            "image/" +
            this.acticleByAccessCountArray[i].thumbnail_img_path.slice(7);
          return thumbnail_img_path;
        }
      }
    },

    // カテゴリ一覧を取得
    getCategoryArray() {
      ApiMiiitsSearchList.getArticleCategory("articleCategory")
        .then((response) => {
          this.categoryArray = response.payload.data;
        })
        .catch((error) => {
          console.log("Error : " + error.response);
        });
    },

    // カテゴリフォーマット
    transCategory(category) {
      let step = this.categoryArray.length;
      for (var i = 0; i < step; i++) {
        const categoryId = this.categoryArray[i].id;
        if (categoryId == category) {
          return this.categoryArray[i].category_name;
        }
      }
    },

    // 記事件数を取得
    getResultLength() {
      if (!this.articleSearchResultArray) {
        return 0;
      }
      return this.articleSearchResultArray.length;
    },

    //ページャーの番号取得
    pagenoupd(arg) {
      this.pageno = arg;
      this.setgoodicon(this.pageno);
    },

    //検索結果の記事のいいね数更新
    plus(id, index) {
      if (document.cookie.indexOf("article" + id + "=liked") == -1) {
        LikeCountUpdateAPI.editLikeCount(id)
          .then(() => {
            document.cookie = "article" + id + "=liked";
            ArticleDetailAPI.getArticleDetail(id)
              .then((response) => {
                this.likecount = response.payload.data.like_count;
                for (var i = 0; i < 3; i++) {
                  if (this.acticleByAccessCountArray[i].id == id) {
                    this.acticleByAccessCountArray[i].like_count =
                      this.likecount;
                    this.src2[i] = require("@/assets/image/goodicon_after.svg");
                  }
                }
                this.articleSearchResultArray[
                  Number(index) +
                    this.$store.state.miiitsStore.displayLimit *
                      (Number(this.pageno) - 1)
                ].like_count = this.likecount;
                this.btn_flg[
                  Number(index) +
                    this.$store.state.miiitsStore.displayLimit *
                      (Number(this.pageno) - 1)
                ] = false;
              })
              .catch((error) => {
                console.log("Error : " + error.response);
              });
          })
          .catch((error) => {
            console.log("Error : " + error.response);
          });
      }
    },

    //アクセス数の多い記事のいいね数更新
    likeplus(id) {
      var num = this.acticleByAccessCountArray[id].id;
      if (document.cookie.indexOf("article" + num + "=liked") == -1) {
        LikeCountUpdateAPI.editLikeCount(num)
          .then(() => {
            this.acticleByAccessCountArray[id].like_count = (
              Number(this.acticleByAccessCountArray[id].like_count) + 1
            ).toString();
            document.cookie = "article" + num + "=liked";
            this.manybtn_flg[id] = false;
            for (var j = 0; j < this.articleSearchResultArray.length; j++) {
              if (this.articleSearchResultArray[j].id == num) {
                this.articleSearchResultArray[j].like_count =
                  this.acticleByAccessCountArray[id].like_count;
                this.src_goodicon[
                  j
                ] = require("@/assets/image/goodicon_after.svg");
              }
            }
          })
          .catch((error) => {
            console.log("Error : " + error.response);
          });
      }
    },

    setgoodicon(pageno) {
      let limit = this.$store.state.miiitsStore.displayLimit;
      let max = pageno*limit;
      if(this.articleSearchResultArray.length < max){
          max = this.articleSearchResultArray.length;
      }
      for (var i = (pageno - 1) * limit; i < max; i++) {
        if (
          document.cookie.indexOf(
            "article" + this.articleSearchResultArray[i].id + "=liked"
          ) == -1
        ) {
          this.src_goodicon[i] = require("@/assets/image/goodicon_before.svg");
          this.btn_flg[i] = true;
          if(Number(this.articleSearchResultArray[i].like_count) < 9){
            this.number_flg[i] = true;
          }else{
            this.number_flg[i] = false;
          }
        } else {
          this.src_goodicon[i] = require("@/assets/image/goodicon_after.svg");
          this.btn_flg[i] = true;
          if(Number(this.articleSearchResultArray[i].like_count) < 9){
            this.number_flg[i] = true;
          }else{
            this.number_flg[i] = false;
          }
        }
      }
    },

    setgoodicon2() {
      for (let i = 0; i < 3; i++) {
        var num = this.acticleByAccessCountArray[i].id;
        if (document.cookie.indexOf("article" + num + "=liked") != -1) {
          this.src2[i] = require("@/assets/image/goodicon_after.svg");
          this.manybtn_flg[i] = true;
        } else {
          this.src2[i] = require("@/assets/image/goodicon_before.svg");
          this.manybtn_flg[i] = true;
        }
      }
    },

    handleAnimation1: function (anim) {
      this.anim = anim;
    },
  },
  computed: {
    textKeyword() {
      return this.$store.state.miiitsStore.textKeyword;
    },
    displayLimit() {
      return this.$store.state.miiitsStore.displayLimit;
    },
  },
  watch: {
    textKeyword() {
      this.articleSearch();
    },
    displayLimit() {
      this.articleSearch();
    },
  },
  created: function () {
    this.manybtn_flg[0] = true;
    this.manybtn_flg[1] = true;
    this.manybtn_flg[2] = true;
  },

  mounted() {
    this.articleSearch();
    this.getCategoryArray();
    this.getActicleByAccessCount();
    this.pageno = "1";
  },
};
</script>

<!-- 共通CSS -->
<style src="../miiits/css/common.css"></style>

<!-- ローカルCSS -->
<style scoped>
.body__wrap {
  overflow: hidden;
}

/* ================================ * /
            アクセスの多い記事
==================================== */
/* タイトル */
.ranklist__title {
  position: relative;
}

.ranklist__title p {
  display: inline-block;
  color: #db4627;
  font-weight: 600;
  font-size: 24px;
  background-color: #fff;
  border-radius: 28px;
  padding: 25px;
}

.ranklist__title img {
  position: absolute;
  width: 60px;
  height: auto;
  top: -10%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

/* アクセスの～記事親 */
.article__ranklist {
  display: flex; /* 子要素を横並べ */
  flex-wrap: wrap;
  justify-content: center;
}

/* アクセスの～記事一覧 */
.article__ranklist--box {
  width: 300px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  position: relative;
  flex-direction: column; /* 子要素を縦並べ */
  min-width: 200px;
  margin: 1%;
}

@media (max-width: 957px) {
  .article__ranklist--box {
    margin: 4%;
  }
}

@media (max-width: 420px) {
  .article__ranklist--box {
    margin: 8%;
  }
}

/* アクセスの多い記事サムネイル画像 */
.article__ranklist--img {
  width: 100%;
  height: 200px;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
}

/* カテゴリ名 */
.coetgory__text {
  background-color: #db4627;
  color: #fff;
  display: inline-block;
  border-radius: 10px;
  text-align: center;
  padding-inline: 0.5rem;
  font-weight: 500;
  font-size: 14px;
  padding: 3px 15px;
  cursor: pointer;
  transition: 0.4s;
}
.coetgory__text:hover {
  opacity: 0.8;
  transition: 0.4s;
}

/* 記事タイトル */
.article__ranklist--title {
  color: #333333;
  font-weight: 600;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: 0.7rem;
}

/* ================================ * /
            記事リスト部
==================================== */
/* ドロップダウン */
.articlelistst__dropdown {
  background-color: #ffffff;
  color: #db4627;
  border-color: transparent;
  transition: 1s all;
  max-width: 100%;
  border-radius: 15px;
}
@media (max-width: 992px) {
  .articlelistst__dropdown {
    margin-bottom: 2rem;
  }
}

.articlelistst__dropdown:after {
  margin-left: 5em;
}

.articlelistst__dropdown:focus {
  box-shadow: 0 0 0 0 transparent;
}

/* 既存クラス上書き */
.dropdown-menu {
  color: #db4627;
  padding-left: 0;
  min-width: 10.5rem;
}

/* 続きを読む */
.articlelist--nextlink {
  text-decoration: none;
  font-weight: 600;
  color: #db4627;
  font-size: 15px;
}

/* カルーセル続きを読む */
.carousel__article--link {
  text-decoration: none;
  font-weight: 600;
  color: #db4627;
}

/* 記事見出し */
.articlelist__head {
  color: #db4627;
  font-size: 24px;
  font-weight: 600;
  padding-right: 1.3rem;
}

.articlelist__head--icon {
  width: 70px;
  padding-right: 0.5rem;
  height: auto;
}

.articlelist__head--icon {
  width: 70px;
  height: auto;
}

@media (max-width: 768px) {
  .articlelist__head--icon {
    width: 40px;
  }
}

/* カテゴリ見出し */
.articlelist__cotegory {
  color: #db4627;
  border-bottom: 2px solid #db4627;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 5px;
  position: relative;
  padding-left: 2rem;
}

.articlelist__cotegory:before {
  background: #db4627;
  content: "";
  height: 24px;
  width: 24px;
  left: 0;
  position: absolute;
  top: 0;
}

/* カテゴリアイコン表示調整 */

.article__cotegory--link {
  text-decoration: none;
  color: #db4627;
  font-weight: 500;
}

/* 記事一覧 */
.article__list--box--img {
  background: #fff;
  border-radius: 15px 0px 0px 15px;
  margin: -1%;
}

.article__list--box--content {
  background: #fff;
  border-radius: 0px 15px 15px 0px;
  margin: -1%;
}

@media (max-width: 600px) {
  .article__list--box--img {
    border-radius: 15px 15px 0px 0px;
  }

  .article__list--box--content {
    border-radius: 0px 0px 15px 15px;
    padding-bottom: 1.5rem;
  }
}

.article__list--img {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  -o-object-fit: cover;
  object-fit: cover;
  padding: 0.1rem;
}

.article__list--title {
  color: #333333;
  font-weight: 600;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: 0.5rem;
  min-height: 45px;
}

@media (max-width: 992px) {
  .article__list--date_SP {
    margin-left: -1rem;
  }

  .article__list--title {
    margin-top: 0.3rem;
  }
}

.goodbutton_stop {
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1.5px;
  margin-left: 1px;
}

.goodbutton_stop2 {
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2px;
  margin-left: 2px;
}

.goodbutton_anime {
  width:42px;
  height: 24px;
  display: flex;
  align-items: flex-end;
}

.heartsize{
  width: 24.873px;
  height: 25.869px;
}

.heartsize2{
  width: 24.873px;
  height: 25.869px;
  margin-left: 1.5px;
}

.onenumber{
  margin-left: 5px;
}

/* 記事を読む */
.articlelist__link--ml {
  margin-left: 0.3rem;
}
</style>