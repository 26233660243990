<template>
    <section>
        <div class="container-fluid mt-5 pt-5">
        <div class="body__gap--control"></div>
        <div class="body__gap--control"></div>
            <!-- 白背景部分 -->
            <div class="row region__control">
                <div class="col-12">
                    <div class="memberd__area">
                        <div class="row pb-sm-5 pb-3">
                            <!-- 自己紹介画像 -->
                            <div class="col-md-6 col-sm-6">
                                <div>
                                    <img class="memberd__img" :src="src" alt="吉野写真">
                                </div>
                            </div>
                            <!-- 自己紹介基本文 -->
                            <div class="col-md-6 col-sm-6">
                                <div class="memberd__introduction--about">
                                    <div>
                                        <p class="font-color--orange font-fot-rodin font-size--25 m-0">{{ pen_name_roman }}</p>
                                        <p class="memberd__name--ja font-fot-rodin">{{ pen_name }}</p>
                                        <div class="font-ryo-gothic memberd__class pt-sm-0 pt-4">
                                            <p v-if="miiits_team_id == '1'">Miiits編集部</p>
                                            <p v-else-if="miiits_team_id == '2'">社長</p>
                                            <p v-else-if="miiits_team_id == '3'">人事・採用</p>
                                            <p v-else-if="miiits_team_id == '4'">プロダクトサービス</p>
                                            <p v-else-if="miiits_team_id == '5'">システムエンジニアリング</p>
                                            <p v-else-if="miiits_team_id == '6'">ビジネスエンジニアリング</p>
                                            <p v-else-if="miiits_team_id == '7'">インフラ</p>
                                            <p v-else-if="miiits_team_id == '8'">営業</p>
                                            <p v-else>LBD事業</p>
                                        </div>
                                        <p class="font-color--black font-ryo-gothic font-size--20" style="margin-bottom:0px">{{ job_category_name }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- 自己紹介_紹介文 -->
                        <div class="row">
                            <div class="col-12 p-sm-4 p-0">
                                <div class="memberd__introduction">
                                    <p class="memberd__introduction--text font-color--black font-size--25"
                                        v-html=this.self_introduct_stat >
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="body__gap--controlmini"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            open :false,
            imgPath: process.env.VUE_APP_IMG_BASE,
        }
    },
    props: {
        id: String,
        miiits_team_id: String,
        pen_name: String,
        pen_name_roman: String,
        job_category_name: String,
        self_introduct_stat: String,
        profile_img_path: String,
    },
    computed: {
        src:function() {
            return this.imgPath + this.profile_img_path
        }
    },

}
</script>

<!-- 共通CSS -->
<style src="../pages/miiits/css/common.css"></style>

<!-- ローカルCSS -->
<style scoped>

/* ベース部分（白背景） */
.memberd__area {
    background-color: #FFF;
    border-radius: 30px;
    position: relative;
    margin: 0 auto;
    width: 90%;
    max-width: 1000px;
}


/* プロフィール名前日本語 */
.memberd__name--ja {
    color: #DB4627;
    font-size: clamp(1.75rem, 1.271rem + 2.47vw, 3.125rem);
    margin-bottom: 0;
    font-weight: 700;
}
/* @media screen and (max-width: 526px) {
    .memberd__name--ja {
        font-size: 32px;
    }
} */

/* 自己紹介画像 */
.memberd__img {
    width:500px;
    height:300px;
    object-fit: cover;
    border-radius:20px;
    position: absolute;
    left: -1%;
    top: -70px;
}

@media screen and (max-width: 992px) {
    .memberd__img {
        width:350px;
    }
}

@media screen and (max-width: 700px) {
    .memberd__img {
        width:250px;
    }
}

@media screen and (max-width: 576px) {
    .memberd__img {
        width:200px;
        height:200px;
        object-fit: cover;
        border-radius:200px;
        top: -10%;
        left: 50%;
        transform: translate(-50%, -50%);    }
}

/* 所属 */
.memberd__class {
    font-size: 20px;
    font-weight: 600;
    color: #333333;
}

.memberd__class p {
    margin-bottom: 0;
    margin-top: 0.5rem;
}

/* 自己紹介文の枠*/
.memberd__introduction {
    background-color:#FCF8F2;
    width: 70%;
    border-radius: 30px;
    padding: 20px;
    margin: 0 auto;
}

@media screen and (max-width: 576px) {
    .memberd__introduction {
    width: 80%;
    border-radius: 10px;
    }
}

/* 自己紹介文 */
.memberd__introduction--text {
    text-align:left;
    margin-bottom:0;
}

/* 基本情報紹介 */
.memberd__introduction--about {
    margin-top:30px;
    text-align: center;
}

</style>