<template>
    <v-app>
        <v-sheet>
            <v-toolbar color="blue" dark>
                <v-toolbar-title>EVENT</v-toolbar-title>
            </v-toolbar>
            <album/>
            <sns/>
        </v-sheet>
    </v-app>
</template>

<script>
import Album from './event/Album.vue'
import Sns from './event/Sns.vue'
export default {
    name: 'Event',
  components:{
    Album,
    Sns
  }
}
</script>