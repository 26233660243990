<template>
    <v-app>
    <Header />
    <div class="body__wrap background__beige">
        <section>
            <div class="concontainer-fluid">
                <div class="firstview_range">
                    <div class="row">
                        <div class="col-md-12" style= "padding:0;">
                            <!-- ファーストビュー -->
                            <lottie
                                ref="anim"
                                :options="lottieOptions1"
                                @:animCreated="handleAnimation1"
                                class="firstviewpc"
                            />
                            <lottie
                                ref="anim"
                                :options="lottieOptions2"
                                @:animCreated="handleAnimation2"
                                class="firstviewsp"
                                style="height: 100vh;"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 offset-md-6">
                            <img class="scroll_img" src="@/assets/image/about_scroll.png" alt="TOP1">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- サービス -->
        <section>
            <div class="container-fluid region__control">
                <div class="service_all_background">
                    <div class="row">
                        <div class="col-md-2 offset-md-1 col-12 offset-0 text-center text-md-left">
                            <img class="about_img" src="@/assets/image/about_discava.png" alt="DISCaVa">
                        </div>
                        <div class="col-md-7 offset-md-1 col-12">
                            <div class="describe">
                                <p class="describe_titlle font-size--34 font-weight--800 font-ryo-gothic">DISCaVa</p>
                                <p class="describe_text font-size--16 font-weight--400 font-ryo-gothic">WEB制作の<br class="br__sp"> サブスクリプションサービス</p><br>
                                <p class="describe_text font-size--16 font-weight--400 font-ryo-gothic">月額9,800円から初期費用不要で<br class="br__sp"> あなただけのオリジナルHPが作れる！<br>
                                「聞き上手」なデザイナーたちが、<br class="br__sp"> 魅力をDISCOVER（発見）します。</p>
                                <div class="button_DISCaVa">
                                    <a href="https://discava.net/" target="_blank" rel="noopener noreferrer">WEBサイトへ<i class="fas fa-external-link-alt font-color--white ml-2"></i></a>
                                </div>
                            </div>
                        </div>
                    <hr>
                        <div class="col-md-2 offset-md-1 col-12 offset-0 text-center text-md-left">
                            <img class="about_img" src="@/assets/image/about_managedservice.png" alt="マネージドサービス">
                        </div>
                        <div class="col-md-7 offset-md-1 col-12">
                            <div class="describe">
                                <p class="describe_titlle font-size--34 font-weight--800 font-ryo-gothic">マネージドサービス</p>
                                <p class="describe_text font-size--16 font-weight--400 font-ryo-gothic">ITのお困りごと、なんでもおまかせ！</p><br>
                                <p class="describe_text font-size--16 font-weight--400 font-ryo-gothic">
                                    AWSの運用代行からアプリの開発まで、<br class="br__sp"> ITの「困った」を解決します！<br>お客様のお困りごとに合わせた<br class="br__sp"> 最適なプランをご提案！
                                </p>
                                <div class="button_DISCaVa">
                                    <a
                                        href="mailto:info@miiits.com?subject=%E3%80%90%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E3%80%91%E3%83%9E%E3%83%8D%E3%83%BC%E3%82%B8%E3%83%89%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6"
                                    >
                                        お問い合わせ<i class="fas fa-envelope font-color--white ml-2"></i>
                                    </a>
                                </div>

                            </div>
                        </div>
                    <!-- <hr>
                        <div class="col-md-2 offset-md-1 col-12 offset-0 text-center text-md-left">
                            <img class="about_img" src="@/assets/image/about_otaku.png" alt="オタク発電">
                        </div>
                        <div class="col-md-7 offset-md-1 col-12">
                            <div class="describe">
                                <p class="describe_titlle font-size--34 font-weight--800 font-ryo-gothic">オタク発電</p>
                                <p class="describe_text font-size--16 font-weight--400 font-ryo-gothic">オタク発電</p><br>
                                <p class="describe_text font-size--16 font-weight--400 font-ryo-gothic">
                                    神田に存在するオタク発電所。<br>
                                    実態はあなたの目で確かめて………。</p>
                            </div>
                            <div class="button_DISCaVa">
                                    <a href="https://otaku-hatsuden.com/" target="_blank" rel="noopener noreferrer">WEBサイトへ<i class="fas fa-external-link-alt font-color--white ml-2"></i></a>
                            </div>
                        </div> -->
                    <hr>
                        <div class="col-md-2 offset-md-1 col-12 offset-0 text-center text-md-left">
                            <img class="about_img" src="@/assets/image/about_goodvisibility.png" alt="GoodVisibility">
                        </div>
                        <div class="col-md-7 offset-md-1 col-12">
                            <div class="describe">
                                <p class="describe_titlle font-size--34 font-weight--800 font-ryo-gothic">Good Visibility</p>
                                <p class="describe_text font-size--16 font-weight--400 font-ryo-gothic">資料作成、丸投げできます</p><br>
                                <p class="describe_text font-size--16 font-weight--400 font-ryo-gothic">営業・社内資料など、<br class="br__sp"> 手間や時間がかかる資料作成、<br class="br__sp"> おまかせください！<br>
                                「可視化」に長けたデザイナーが、<br class="br__sp"> 見やすい・わかりやすい資料を作成！</p>
                            </div>
                            <div class="button_DISCaVa">
                                <a
                                    href="mailto:info@miiits.com?subject=%E3%80%90%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E3%80%91Goodvisibility%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6"
                                >
                                    お問い合わせ<i class="fas fa-envelope font-color--white ml-2"></i>
                                </a>
                            </div>
                        </div>
                    <hr>
                        <div class="col-md-2 offset-md-1 col-12 offset-0 text-center text-md-left">
                            <img class="about_img" src="@/assets/image/about_jonetsutairiku.png" alt="TOP1">
                        </div>
                        <div class="col-md-7 offset-md-1 col-12">
                            <div class="describe">
                                <p class="describe_titlle font-size--34 font-weight--800 font-ryo-gothic">OUR TIMES</p>
                                <p class="describe_text font-size--16 font-weight--400 font-ryo-gothic">あなたに完全一日密着！</p><br>
                                <p class="describe_text font-size--16 font-weight--400 font-ryo-gothic pb-5">一日に完全密着取材で<br class="br__sp"> まるで気分は有名人！？<br class="br__sp"> 取材内容はWEB記事に！<br>
                                遠い場所にいる家族や友達に、<br class="br__sp"> あなたの一日をスタイリッシュに発信。</p>
                            </div>
                            <div class="button_ourtimes">
                                <a tabindex="-1">
                                    準備中…<i class="fas fa-comment font-color--white ml-2"></i>
                                </a>
                            </div>
                        </div>
                </div>
            </div>
            <div class="row">
                <div class="newservice_range">
                    <p class="newservice_title font-color--orange font-ryo-gothic font-weight--600 font-size--20">新サービス案<br>続々発掘中！</p>
                    <div class="newservice_namerange">
                        <p class="newservice_name font-ryo-gothic font-weight--600">
                            エンディングプロフィール帳 <span class="none__sp">/</span><br class="br__sp br__pad">エンジニアお見合い<br class="none__pad none__sp2"><span class="none__pc"> /</span>
                            キイテミ！ <br class="br__sp br__pad"><span class="none__sp">/</span> 冠婚葬祭全部代行 / ほめのぽ<br>
                            ホムペ！ / 196 dessert <br class="br__sp br__pad"><span class="none__sp">/</span> 酔い撮れ / のんでれら
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="text-center">
                    <img class="people_img" src="@/assets/image/about_3_people.png">
                </div>
            </div>
        </div>
        </section>
    </div>
    <Footer />
    </v-app>
</template>
<!--ファーストビューの表示-->
<script>
    // ヘッダーとフッター
    import Header from '@/components/molecules/Header.vue'
    import Footer from '@/components/molecules/Footer.vue'
    // vue-lottieをimport
    import Lottie from 'vue-lottie';
    // JSONデータ
    import animationData1 from '@/assets/image/about_pc_firstview.json';
    import animationData2 from '@/assets/image/about_sp_firstview.json';
    export default {
        name: "About",
        components: {
            Lottie,Header,Footer
        },
        data() {
            return {
                lottieOptions1: {
                    animationData: animationData1,
                    loop: true,
                    autoplay: true,
                },
                lottieOptions2: {
                    animationData: animationData2,
                    loop: true,
                    autoplay: true,
                }
            }
        },
        methods: {
            handleAnimation1: function (anim) {
                this.anim = anim;
            },
            handleAnimation2: function (anim) {
                this.anim = anim;
            },
        },
        mounted() {},
    };
</script>

<!-- 共通CSS -->
<style src="../miiits/css/common.css"></style>

<!-- ローカルCSS -->
<style scoped>
/* 全体 */
/* 右の余白をなくす */
.body__wrap{
    overflow: hidden;
}
/* ファーストビュー */
/* PCとSPでファーストビューを切り替える */
@media(min-width:751px){
    .firstviewsp{
        display: none !important;
    }
}
@media(max-width:750px){
    .firstviewpc{
        display: none !important;
    }
}
.firstview_range{
    position:relative; 

}

/* スクロール画像 */
.scroll_img{
        width:50px;
        height:auto;
        position: absolute;
        bottom: 3%;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
}

@media(max-width:749px){
    .scroll_img {
        width: 30px;
        bottom: 8%;
    }
}


/* 背景 */
.service_all_background{
    border-radius: 30px;
    padding:50px 0 50px 0;
    margin-top:100px;
    margin-right: auto; 
    margin-left: auto;
    max-width:90%;
    background: #FFFFFF; 
}

/* サービス */
hr{
    margin:50px 0 50px 0;
    width:80%;
    margin-right:auto;
    margin-left:auto;
}

.about_img{
    width: 200px;
    height: auto;

}

/* WEBサイトへボタン（共通） */
.button_DISCaVa {
    text-align: right;
    width: 80%;
    margin-top: 2rem;
}

.button_DISCaVa a {
    position: relative;
    justify-content: space-around;
    align-items: center;
    max-width: 183px;
    max-height: 46px;
    padding: 10px 25px;
    color: #FFFFFF;
    font-weight: 700;
    font-family: ryo-gothic-plusn,sans-serif;
    font-size: 20px;
    font-style: normal;
    text-decoration: none;
    background: #DE614C;
    border-radius: 50px;
    overflow: hidden;
}

.button_ourtimes {
    text-align: right;
    width: 80%;
    margin-bottom: 2rem;
}

.button_ourtimes a {
    position: relative;
    justify-content: space-around;
    align-items: center;
    max-width: 183px;
    max-height: 46px;
    padding: 10px 25px;
    color: #FFFFFF;
    font-weight: 700;
    font-family: ryo-gothic-plusn,sans-serif;
    font-size: 20px;
    font-style: normal;
    text-decoration: none;
    background: #e59f93;
    border-radius: 50px;
    overflow: hidden;
    pointer-events: none;
}


.newservice_name {
    margin: 0;
}

.newservice_range{
    position: relative;
    max-width:700px;
    margin-top:100px;
    margin-right: auto;
    margin-left: auto;
}

.newservice_namerange{
    border-radius: 60px;
    padding:20px 20px;
    margin-right: auto; 
    margin-left: auto;
    max-width:700px;
    background: #FFFFFF; 
    text-align: center;
}

.newservice_title{
    position: absolute;
    transform: rotate( -18deg );
    top: -23%;
    text-align: center;
}

.people_img{
    width: 50%;
    height: auto;
}

/* 新サービス部分改行調整 */
@media (min-width: 767px){
    .none__pc {
        display:none;
    }
}
@media (max-width: 767px){
    .none__sp{
        display:none;
    }
    .none__pad{
        display:none;
    }
}
@media (max-width: 420px){
    .none__pad{
        display:inline-block;
    }
    .none__sp2 {
        display: none;
    }
}

/* タブレット */
@media (max-width: 768px) {
    /* 背景 */
    .service_all_background{
        border-radius: 30px;
        padding:80px 30px 0 30px;
        margin-top: 0;
        margin-right: 30px;
        margin-left: 30px;
        text-align: center;
        background: #FFFFFF;
    }
    .newservice_range{
        max-width:400px;
        margin-top:200px;
        margin-right: auto; 
        margin-left: auto;
    }
    .newservice_namerange{
        border-radius: 60px;
        padding:120px 20px 120px 20px;
        height:380px;
        width:300px;
        background: #FFFFFF; 
        text-align: center;
    }
    .people_img{
        width: 100%;
        height: auto;
    }
    .button_DISCaVa {
        width: 100%;
        text-align: center;
        margin-top: 2.4rem;
    }
    .button_ourtimes {
        width: 100%;
        text-align: center;
        margin-top: 0.5rem;
    }

    .newservice_title{
        font-size: 25px;
        top: -5%;
    }

}


/* SP */
@media screen and (max-width: 420px) {
    /* 背景 */
    .service_all_background{
        border-radius: 30px;
        padding:80px 10px 0 10px;
        margin-top: 0;
        margin-right: 30px;
        margin-left: 30px;
        text-align: center;
        background: #FFFFFF;
    }

    .button_DISCaVa a {
        position: relative;
        max-width: 183px;
        max-height: 46px;
        padding: 10px 25px;
        color: #FFFFFF;
        font-weight: 700;
        font-family: ryo-gothic-plusn,sans-serif;
        font-size: 20px;
        font-style: normal;
        text-decoration: none;
        background: #DE614C;
        border-radius: 50px;
        overflow: hidden;
    }

        /* サービス */
        .describe_titlle{
        font-size: 24px;
    }
        .describe_text{
        font-size: 13px;
        font-weight: 500;
    }
        hr{
        margin:50px 0px 50px 0px;
        width:100%;
    }
        .about_img{
        width: 150px;
        height: auto;
    }

    .newservice_range{
        max-width: 500px;
        margin-top: 140px;
        margin-right: auto;
        margin-left: auto;
    }
    .newservice_namerange{
        border-radius: 60px;
        padding: 20px;
        margin-right: auto;
        margin-left: auto;
        height: 330px;
        max-width: 300px;
        background: #FFFFFF;
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .people_img{
        width: 100%;
        height: auto;
    }
}
</style>







