<template>
    <v-container>
        <form-label-text
            label="却下理由"
            placeholder="例）日付に誤りがある"
            v-bind:value.sync="admin_comment.value"
            :error.sync="admin_comment.error"
            :errorMsg.sync="admin_comment.errorMsg"
        />
    </v-container>
</template>

<script>
import FormLabelText from "../molecules/FormLabelText.vue";
export default {
    components: { FormLabelText },
    name: "TransportationExpense",
    props: {
        prop_employee_no: [Number, String],
    },
    data: () => ({
        namespace: "TransportationExpense",
    }),
    computed: {
        employee_no: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["employee_no"];
            },
            set: function (value) {
                return this.$store.state[this.namespace]["items"]["employee_no"].value = value;
            },
        },
        admin_comment: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["admin_comments"];
            },
            set: function (value) {
                return this.$store.state[this.namespace]["items"]["admin_comments"].value = value;
            },
        },
        
    },
    mounted: function () {
        if (this.prop_employee_no == null) {
            this.getEmployeeNo()
        } else {
            this.employee_no.value = this.prop_employee_no
        }

    },
    watch: {
        prop_employee_no() {
            this.employee_no.value = this.prop_employee_no
        }
    },
    methods: {
        getEmployeeNo() {
            this.$store.dispatch("TransportationExpense/getEmployeeNo");
        },
    },
};
</script>