<template>
    <v-app>
        <v-sheet>
            <v-app-bar color="light-blue" dark>
                <v-toolbar-title>定期代/交通費申請</v-toolbar-title>
            </v-app-bar>
            <v-container>
                <v-row style="height: 600px;">
                    <v-col cols="8">
                        <v-row style="height: 300px;">
                            <v-col cols="4">
                                <v-card class="grey lighten-3">
                                    <v-card-text class="text-center" @click="jumpTo('transportationExpense')">
                                        <v-icon large class="py-5">mdi-train</v-icon>
                                        <p class="text-white section-app-SUBtitle-font">交通費申請</p>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="4">
                                <v-card class="grey lighten-3">
                                    <v-card-text class="text-center" @click="jumpTo('commuterPassPrice')">
                                        <v-icon large class="py-5">mdi-train</v-icon>
                                        <p class="text-white section-app-SUBtitle-font">定期代申請</p>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="4">
                                <v-card class="grey lighten-3">
                                    <v-card-text class="text-center" @click="jumpTo('transportationExpenseAdmin')">
                                        <v-icon large class="py-5">mdi-train</v-icon>
                                        <p class="text-white section-app-SUBtitle-font">交通費申請管理</p>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
    </v-app>
</template>

<script>
import router from "../../router"
export default {
    name: "SwitchApplication",
    methods: {
        jumpTo(path) {
            console.log(path)
            router.push("/" + path);
        },
    },
};
</script>