var dt = new Date();  //現在日時のDateオブジェクトを生成
var dt5th = new Date().setDate(5);  //当月5日のDateオブジェクトを生成
var dt20th = new Date().setDate(20);  //当月20日のDateオブジェクトを生成
var tdt = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);  //今月末日を取得
var ndt = new Date(dt.getFullYear(), dt.getMonth() + 2, 0);  //来月末日を取得
var monthList = new Array;

//フォーマット整形
if (dt <= dt5th) {   //支給当月6日以降は申請不可
    var ty = tdt.getFullYear();
    var tm = ("00" + (tdt.getMonth()+1)).slice(-2);
    var thisMonth = ty + "-" + tm;
    monthList.push(thisMonth);
}

if (dt >= dt20th) {   //支給前月20日以前は申請不可
    var ny = ndt.getFullYear();
    var nm = ("00" + (ndt.getMonth()+1)).slice(-2);
    var nextMonth = ny + "-" + nm;
    monthList.push(nextMonth);
}

export {monthList};