import axios from "axios";

//記事詳細画面、PICKUP記事呼び出しAPI
export function getPickupArticleList(id) {
    return new Promise(resolve => {
        const payload = axios
            .get(`/miiits/PickupArticleList/${id}/`, {
                headers: {
                    'content-type': 'application/json',
                },
            })
            .then(response => {
                return { payload: response };
            })
            .catch(error => {
                return { error };
            });
        resolve(payload);
    });
}

//記事一覧画面、PICKUP記事呼び出しAPI
export function getPickupArticleList2() {
    return new Promise(resolve => {
        const payload = axios
            .get(`/miiits/PickupArticleList2/`, {
                headers: {
                    'content-type': 'application/json',
                },
            })
            .then(response => {
                return { payload: response };
            })
            .catch(error => {
                return { error };
            });
        resolve(payload);
    });
}
