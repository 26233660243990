<template>
  <modal
    id="DocumentUpload"
    name="documentUploadModal"
    :adaptive="true"
    width="500px"
    height="420px"
    :closable="false"
  >
    <v-app>
      <div>
        <v-toolbar flat>
          <v-toolbar-title>ドキュメントアップロート</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container class="grey lighten-5">
            <v-card-text>
              <v-file-input
                v-model="fileData.file_path"
                ref="fileInput"
                label="ファイル選択"
                clearable
                :rules="uploadRules"
                required
              ></v-file-input>

              <v-text-field
                v-model="fileData.file_name"
                label="ファイル名"
                :rules="uploadRules"
                class="my-4"
                required
              ></v-text-field>

              <v-select
                v-model="fileData.category"
                :items="fileData.items"
                item-text="value"
                item-value="key"
                label="カテゴリ"
                class="my-4"
                clearable
                :rules="[(v) => !!v || '必須項目']"
                required
              ></v-select>

              <v-spacer />
              <v-row>
                <v-col align="center">
                  <v-btn
                    :disabled="!valid"
                    class="ma-2 white--text"
                    color="primary"
                    @click="submitUpload"
                  >
                    <v-icon left> mdi-playlist-plus </v-icon>
                    アップロード
                  </v-btn>
                  <v-btn
                    class="ma-2 white--text"
                    color="blue-grey"
                    dark
                    @click="closeDocumentUploadModal(false)"
                  >
                    <v-icon left> mdi-playlist-remove </v-icon>
                    キャンセル
                  </v-btn>
                  <v-spacer />
                </v-col>
              </v-row>

              <v-snackbar
                v-model="snackbar"
                color="orange darken-2"
                :timeout="timeout"
                centered
                text
              >
                {{ text }}
              </v-snackbar>
            </v-card-text>
          </v-container>
        </v-form>
      </div>
    </v-app>
  </modal>
</template>

<script>
import * as API from "../../../apis/API";

export default {
  name: "documentUploadModal",
  data() {
    return {
      valid: true,
      uploadRules: [(v) => !!v || "必須項目"],
      snackbar: false,
      text: "",
      timeout: 3000,
      fileData: {
        file_path: null,
        file_name: "",
        category: null,
        items: [
          { key: 1, value: "就業" },
          { key: 2, value: "研修" },
          { key: 3, value: "その他" },
        ],
      },
    };
  },
  methods: {
    // ファイルアップロート
    submitUpload() {
      // カラムヴァリデーション
      let notEmpty = this.$refs.form.validate();

      if (notEmpty === true) {
        // アップロートアイテム
        let fileData = new FormData();
        fileData.append("file_path", this.fileData.file_path);
        fileData.append("file_name", this.fileData.file_name);
        fileData.append("category", this.fileData.category);

        API.documentUpload("documentUpload", fileData)
          .then((response) => {
            if (response.payload.status === 201) {
              this.file_path = "";
              this.file_name = "";
              this.category = "";
              this.text = "正常に登録されました。モーダルをクローズする";
              this.snackbar = true;
              this.$refs.form.reset();

              // 登録済、ダイアログクローズ
              setTimeout(() => {
                this.closeDocumentUploadModal(true);
              }, 2000);
            }
          })
          .catch((error) => {
            console.log(error.response);
            this.text = "登録に失敗しました";
            this.snackbar = true;
            this.$refs.form.reset();
          });
      }
    },

    closeDocumentUploadModal(isRefresh) {
      if (isRefresh) {
        // アップロード済
        this.fileData.file_path = null;
        this.fileData.file_name = "";
        this.fileData.category = "";
        this.$modal.hide("documentUploadModal");
        this.showModal = false;
        this.$emit("on-close", { isclose: true, isRefresh: isRefresh });
      } else {
        // アップロードキャンセル
        this.$modal.hide("documentUploadModal");
        this.showModal = false;
      }
    },
  },
};
</script>
<style>
.v-btn {
  width: 150px;
}
</style>