// initial state
const state = () => ({
    list: [],
    transportationList: [],
    items: {
        employee_no: {value: null, error: false, errorMsg: []},
        full_name: {value: null, error: false, errorMsg: []},
        total_price: {value: null, error: false, errorMsg: []},
    }
})

// getters
const getters = {}

// actions
const actions = {
    getList({ dispatch }) {
        dispatch('getList', {
            apiName: 'api/transeport-expence-admin-employee-list/',
            namespace: 'TransportationExpenseAdmin'
        }, {
            root: true
        })
    },
    setList({commit} , {list}) {
        commit('setTransportationList', {list})
    },
    getTransPortationList({dispatch, commit}, {employee_no}) {
        const thenAction = function (res, { namespace }) {
            commit('setTransportationList', {
                namespace: namespace,
                list: res.data
            })
        }
        if (employee_no != null) {
            dispatch('getAction', {
                apiName: 'api/transeport-expence-admin-list/' + employee_no + '/',
                namespace: 'TransportationExpenseAdmin',
                thenAction: thenAction,
            }, {root: true})
        }
    }
}

// mutations
const mutations = {
    setTransportationList(state, { list }) {
        state.transportationList = list
    },
    setEmployeeNo (state, { employee_no }) {
        state.items.employee_no.value = employee_no
    },
    setName (state, { full_name }) {
        state.items.full_name.value = full_name
    },
    setTotal (state, { total_price }) {
        state.items.total_price.value = total_price
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}