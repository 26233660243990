<template>
    <v-app>
    <Header />
    <div class="body__wrap background__beige" style="overflow:hidden;">
    <div class="body__gap--control"></div>
        <!-- Miiitsメンバー -->
        <section>
            <div class="concontainer-fluid font-fot-rodin pt-5">
                <div class="member_first">
                    <img class="member_illust1" src="@/assets/image/member_leftpeople.png" alt="memberillust">
                    <div class="member_firsttext">
                        <p class="member_title font-size--60 font-fot-rodin">Miiitsメンバー</p>
                                <p class="member_describe font-size--16 font-weight--400 font-ryo-gothic">皆様がMiiitsと出会うきっかけを作るため、<br class="br__pad">MISTsollutionの社員が<br class="br__sp">さまざまなコンテンツを発信中！<br><br class="br__sp">
                                個性豊かなMiiitsメンバーを<br class="br__sp">ご紹介いたします！</p>
                    </div>
                    <img class="member_illust2" src="@/assets/image/member_rightpeople.png" alt="memberillust">
                </div>
                <!-- タブレット、SPで表示する画像 -->
                <div class="member_first_spimg">
                    <img class="member_spillust1" src="@/assets/image/member_leftpeople.png" alt="memberillust">
                    <img class="member_spillust2" src="@/assets/image/member_rightpeople.png" alt="memberillust">
                </div>
                <!-- スクロールアイコン -->
                <div id="page_top">
                    <a href="#">
                        <img class="member_scroll_icon" src="@/assets/image/member_scroll.png" alt="member_scroll_icon">
                    </a>
                </div>
            </div>
        </section>
        <section>
            <div class="concontainer-fluid">
                <div class="row">
                    <!-- 部署ボタン -->
                    <div class="memberlist_range col-10 offset-1 justify-content-center text-center background__white">
                        <!-- 部署ボタン修正 -->
                        <div class="row" id="miiits">
                            <div class="col-12 mx-auto mt-4 font-fot-rodin">
                                <div class="busho_button_oya">
                                    <a href="#miiits"><button class="busho_button_ko">Miiits編集部</button></a>
                                    <a href="#shacho"><button class="busho_button_ko">社長</button></a>
                                    <a href="#saiyo"><button class="busho_button_ko">人事・採用</button></a>
                                    <!-- <a href="#product"><button class="busho_button_ko">プロダクトサービス</button></a> -->
                                </div>
                                <!-- <div class="busho_button_oya">
                                    <a href="#business"><button class="busho_button_ko">ビジネスエンジニアリング</button></a>
                                    <a href="#system"><button class="busho_button_ko">システムエンジニアリング</button></a>
                                </div>
                                <div class="busho_button_oya">
                                    <a href="#infura"><button class="busho_button_ko">インフラ</button></a>
                                    <a href="#eigyou"><button class="busho_button_ko">営業</button></a>
                                    <a href="#lbd"><button class="busho_button_ko">LBD事業</button></a>
                                </div> -->
                            </div>
                        </div>

                        <!-- メンバー一覧 -->
                        <!-- miiits編集部 -->
                        <div class="body__gap--controlmini"></div>
                        <div class="row">
                            <div class="col-12 text-center">
                                <a>
                                    <img class="fukidashi" src="@/assets/image/member_fukidashi_miiits.png" alt="Miiits編集部">
                                </a>
                            </div>
                        </div>
                        <!-- Miiits編集部メンバー一覧 -->
                        <div class="member__list region__control">
                            <div v-for="item in menberListItem(1)" :key="item.id" class="member__list--box">
                                <MemberList
                                    :id = item.id.toString()
                                    :profile_img_maru_path = item.profile_img_maru_path
                                    :job_category_name = item.job_category_name
                                    :pen_name = item.pen_name
                                />
                            </div>
                        </div>

                        <!-- 社長 -->
                        <div class="body__gap--controlmini" id="shacho"></div>
                        <div class="row mt-5">
                            <div class="col-12 mt-5 text-center">
                                <a><img class="fukidashi" src="@/assets/image/member_fukidashi_shacho.png" alt="社長"></a>
                            </div>
                        </div>
                        <div class="member__ilust region__control">
                            <div class="member__ilust--box">
                                <div class="member">
                                    <img class="member_illust3" src="@/assets/image/member_illust3.svg" alt="社長イラスト1">
                                </div>
                            </div>
                            <div v-for="item in menberListItem(2)" :key="item.id" class="member__list--box">
                                <MemberList
                                    :id = item.id.toString()
                                    :profile_img_maru_path = item.profile_img_maru_path
                                    :job_category_name = item.job_category_name
                                    :pen_name = item.pen_name
                                />
                            </div>
                            <div class="member__ilust--box">
                                <div class="member">
                                    <img class="member_illust4" src="@/assets/image/member_illust4.svg" alt="社長イラスト2">
                                </div>
                            </div>
                        </div>

                        <!-- 人事採用 -->
                        <div class="body__gap--controlmini" id="saiyo"></div>
                        <div class="row mt-5">
                            <div class="col-12 text-center mt-5">
                                <a><img class="fukidashi" src="@/assets/image/member_fukidashi_saiyou.png" alt="人事採用"></a>
                            </div>
                        </div>
                        <!-- 人事採用メンバー一覧 -->
                        <div class="member__list region__control">
                            <div v-for="item in menberListItem(3)" :key="item.id" class="member__list--box">
                                <MemberList
                                    :id = item.id.toString()
                                    :profile_img_maru_path = item.profile_img_maru_path
                                    :job_category_name = item.job_category_name
                                    :pen_name = item.pen_name
                                />
                            </div>
                        </div>

                        <!-- プロダクト -->
                        <!-- <div class="body__gap--controlmini" id="product"></div>
                        <div class="row mt-5">
                            <div class="col-12 text-center mt-5">
                                <a><img class="fukidashi" src="@/assets/image/member_fukidashi_product.png" alt="プロダクト"></a>
                            </div>
                        </div> -->
                        <!-- プロダクトメンバー一覧 -->
                        <!-- <div class="member__list region__control">
                            <div v-for="item in menberListItem(4)" :key="item.id" class="member__list--box">
                                <MemberList
                                    :id = item.id.toString()
                                    :profile_img_maru_path = item.profile_img_maru_path
                                    :job_category_name = item.job_category_name
                                    :pen_name = item.pen_name
                                />
                            </div>
                        </div> -->

                        <!-- ビジネスエンジニアリング -->
                        <!-- <div class="body__gap--controlmini" id="business"></div>
                        <div class="row mt-5">
                            <div class="col-12 text-center mt-5">
                                <a><img class="fukidashi" src="@/assets/image/member_fukidashi_business.png" alt="ビジネスエンジニアリング"></a>
                            </div>
                        </div> -->
                        <!-- 金ソメンバー一覧 -->
                        <!-- <div class="member__list region__control">
                            <div v-for="item in menberListItem(6)" :key="item.id" class="member__list--box">
                                <MemberList
                                    :id = item.id.toString()
                                    :profile_img_maru_path = item.profile_img_maru_path
                                    :job_category_name = item.job_category_name
                                    :pen_name = item.pen_name
                                />
                            </div>
                        </div> -->

                        <!-- システムエンジニアリング -->
                        <!-- <div class="body__gap--controlmini" id="system"></div>
                        <div class="row mt-5">
                            <div class="col-12 text-center mt-5">
                                <a id="miiits><img class="fukidashi" src="@/assets/image/member_fukidashi_system.png" alt="システムエンジニアリング"></a>
                            </div>
                        </div> -->
                        <!-- 金ソメンバー一覧 -->
                        <!-- <div class="member__list region__control">
                            <div v-for="item in menberListItem(5)" :key="item.id" class="member__list--box">
                                <MemberList
                                    :id = item.id.toString()
                                    :profile_img_maru_path = item.profile_img_maru_path
                                    :job_category_name = item.job_category_name
                                    :pen_name = item.pen_name
                                />
                            </div>
                        </div> -->

                        <!-- インフラ -->
                        <!-- <div class="body__gap--controlmini" id="infura"></div>
                        <div class="row mt-5">
                            <div class="col-12 text-center mt-5">
                                <a><img class="fukidashi" src="@/assets/image/member_fukidashi_infura.png" alt="インフラ"></a>
                            </div>
                        </div> -->
                        <!-- インフラメンバー一覧 -->
                        <!-- <div class="member__list region__control">
                            <div v-for="item in menberListItem(7)" :key="item.id" class="member__list--box">
                                <MemberList
                                    :id = item.id.toString()
                                    :profile_img_maru_path = item.profile_img_maru_path
                                    :job_category_name = item.job_category_name
                                    :pen_name = item.pen_name
                                />
                            </div>
                        </div> -->

                        <!-- 営業 -->
                        <!-- <div class="body__gap--controlmini" id="eigyou"></div>
                        <div class="row mt-5">
                            <div class="col-12 text-center mt-5">
                                <a><img class="fukidashi" src="@/assets/image/member_fukidashi_eigyou.png" alt="営業"></a>
                            </div>
                        </div> -->
                        <!-- 営業メンバー一覧 -->
                        <!-- <div class="member__list region__control">
                            <div v-for="item in menberListItem(8)" :key="item.id" class="member__list--box">
                                <MemberList
                                    :id = item.id.toString()
                                    :profile_img_maru_path = item.profile_img_maru_path
                                    :job_category_name = item.job_category_name
                                    :pen_name = item.pen_name
                                />
                            </div>
                        </div> -->

                        <!-- LBD -->
                        <!-- <div class="body__gap--controlmini" id="lbd"></div>
                        <div class="row mt-5">
                            <div class="col-12 text-center mt-5">
                                <a><img class="fukidashi" src="@/assets/image/member_fukidashi_lbd.png" alt="LBD"></a>
                            </div>
                        </div> -->
                        <!-- LBDメンバー一覧 -->
                        <!-- <div class="member__list region__control">
                            <div v-for="item in menberListItem(9)" :key="item.id" class="member__list--box">
                                <MemberList
                                    :id = item.id.toString()
                                    :profile_img_maru_path = item.profile_img_maru_path
                                    :job_category_name = item.job_category_name
                                    :pen_name = item.pen_name
                                />
                            </div>
                        </div> -->

                    <div class="body__gap--control"></div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="footer">
        <Footer />
    </div>
    </v-app>
</template>

<script>
import Header from '@/components/molecules/Header.vue'
import Footer from '@/components/molecules/Footer.vue'
// メンバーパーツ
import MemberList from '@/components/molecules/MemberList.vue'
import * as MemberAPI from "../../../apis/MemberListAPI"
export default {
    name: 'app',
    components: {
        Footer,Header,MemberList
    },
	data() {
        return{
            Member: [],
        };
    },
    created: function() {

        MemberAPI.getMemberList()
        .then(response => {
            this.Member = response.payload.data;
        })
        .catch(error => {
            console.log("Error : " + error.response);
        });
    },
    methods: {
            menberListItem(num) {
            return this.Member.filter(value => value.del_flg === false && value.miiits_team_id == num)
        }
    },

    mounted() {
    },
}
</script>

<!-- 共通CSS -->
<style src="../miiits/css/common.css"></style>

<!-- ローカルCSS -->
<style scoped>
/* aタグ初期化 */
a {
    color: #333333 !important;
    text-decoration: none;
}

/* 右の余白をなくす */
.body__wrap{
    overflow: hidden;
}

/* 白の部分に丸みを設定 */
.memberlist_range {
    border-radius: 30px;
    margin-bottom:100px;
}

    @media (max-width: 420px) {
        .memberlist_range {
            margin-bottom:95px;
    }
}

/* ================================ * /
            スクロールアイコン
==================================== */
.member_scroll_icon{
    width:50px;
    z-index: 999;
}
.member_scroll{
    position: fixed;
}

#page_top{
    position: fixed;
    right: 2%;
    bottom: 5%;
    }
    /* ---タブレット--- */
    @media (max-width: 957px) {
        .member_scroll_icon{
            width: 40px;
            right: 10px;
    }
}
/* ================================ * /
            Miiitsメンバーの説明
==================================== */
/* メンバーリストエリア */
.member__list {
    display: flex; /* 子要素を横並べ */
    flex-wrap: wrap;
    justify-content: center;
}

@media screen and (max-width: 420px) {
    .member__list {
        justify-content: space-between;
    }
}

@media screen and (max-width: 349px) {
    .member__list {
        justify-content: center;
    }
}

/* メンバーBOX */
.member__list--box {
    width: 165px;
    background: #FFF;
    border-radius: 10px;
    position: relative;
    flex-direction: column; /* 子要素を縦並べ */
    margin: 3%;
    margin-top: 2rem;
    font-family: ryo-gothic-plusn,sans-serif;
    font-style: normal;
}

@media screen and (max-width: 420px) {
    .member__list--box {
        width: 120px;
        margin: 5%;
    }
}

@media screen and (max-width: 360px) {
    .member__list--box {
        width: 105px;
        margin: 4%;
    }
}

/* 社長の横のイラストエリア */
.member__ilust {
    display: flex; /* 子要素を横並べ */
    justify-content: center;
}

/* 社長横イラストBOX */
.member__ilust--box{
    width: 165px;
    background: #FFF;
    border-radius: 10px;
    position: relative;
    flex-direction: column; /* 子要素を縦並べ */
    margin: 3%;
    margin-top: 2rem;
    font-family: ryo-gothic-plusn,sans-serif;
    font-style: normal;
}

/* 横並び */
.member_first{
    display: flex;
    align-items: flex-start;
    justify-content:center;
    margin-top:30px;
    margin-bottom:30px;
    
}
/* 社長左のイラスト */
.member_illust1{
    width:14%;
    height:auto;
    margin-bottom:50px;
}
/* 社長右のイラスト */
.member_illust2{
    width:14%;
    height:auto;
    margin-bottom:50px;
}
/* PCでは非表示 */
.member_first_spimg{
    display: none;
}
.member_title{
    color: transparent;
    -webkit-text-stroke: 2px #DB4627;
    text-align:center;
    font-size: 60px;
}
.member_describe{
    font-size: 18px;
    text-align:center;
}
.member_describe_range{
    position:relative;
    margin:50px 0 50px 0;;
}
.member_memberillust{
    position:absolute;
    top:40px;
}
/* ---タブレット--- */
@media (max-width: 957px) {
    /* PCのイラストを非表示する */
    .member_illust1 ,.member_illust2 {
    display: none;
    }
    /* イラスト2つの親 */
    .member_first_spimg{
        display: block;
        display: flex;
        align-items: flex-start;
        justify-content:center;
        margin-top:30px;
        margin-bottom:55px;
    }
    /* 左のイラスト */
    .member_spillust1{
    width:20%;
    height:auto;

    }
    /* 右のイラスト */
    .member_spillust2{
        width:20%;
        height:auto;;
    }
    /* Miiitsメンバーの説明 */
    .member_describe_range{
    position: static;
    margin:10px 0 30px 0;
    }
}
/* ---SP--- */
@media screen and (max-width: 420px) {
     /* 左のイラスト */
    .member_spillust1{
    width:30%;
    height:auto;

    }
    /* 右のイラスト */
    .member_spillust2{
        width:30%;
        height:auto;;
    }
    .member_describe{
    font-size: 16px;
    text-align:center;
    }
    .member_title{
    color: transparent;
    -webkit-text-stroke: 1px #DB4627;
    text-align:center;
    font-size: 40px;
    }
}
/* ================================ * /
            部署ボタン
==================================== */
/* ボタン親 */
.busho_button_oya{
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
}

.busho_button_oya a {
    margin: 5px;
}

/* ボタン子 */
.busho_button_ko{
    border: 2px solid #DB4627 !important;
    color:#DB4627 !important;
    border-radius: 20px;
    font-size: 17px;
    padding: 5px 20px 5px 20px;
    transition: 0.2s;
}
/* ボタンがバーした時の動き */
.busho_button_ko:hover{
    background: #DB4627 !important;
    color:#FFFFFF !important;
}
/* ---SP--- */
@media screen and (max-width: 420px) {
    /* ボタン子 */
    .busho_button_ko{
    border: 2px solid #DB4627 !important;
    color:#DB4627 !important;
    border-radius: 20px;
    padding:5px 8px 5px 8px;
    margin: 5px;
    font-size: 14px;
}
}
/* ================================ * /
            メンバー一覧
==================================== */
.member{
    margin-bottom:50px;
}
/* 社長の左のイラスト */
.member_illust3{
    width:200px;
}
/* 社長の右のイラスト */
.member_illust4{
    width:150px;
}

@media screen and (max-width: 768px) {
    /* 社長左のイラスト */
    .member_illust3{
    width:170px;
    margin-top:50px;
    }
    /* 社長右位のイラスト  */
    .member_illust4{
    width:125px;
    margin-top:50px;
    margin-right:20px;
    }
}


@media screen and (max-width: 550px) {
    /* 社長左のイラスト */
    .member_illust3{
    width:70px;
    margin-top:20px;
    }
    /* 社長右位のイラスト  */
    .member_illust4{
    width:48px;
    margin-top:20px;
    margin-right:20px;
    }
}

/* 部署の吹き出し */
.fukidashi{
    width:300px;
}
/* 名前 */
.people_name{
border-bottom: dashed 3px #DB4627; /* ボーダー下部のスタイルを破線で指定する */
padding-bottom:5px;
display:inline;
line-height:2;
}
/* 肩書き */
.people_katagaki{
margin-top:20px;
margin-bottom:0;
}
/* 名前 */
.people_name{
border-bottom: dashed 3px #DB4627; /* ボーダー下部のスタイルを破線で指定する */
padding-bottom:5px;
display:inline;
line-height:2;
}
/* タブレット */
@media screen and(max-width: 957px) {
    .member_describe_range{
    position:relative;
    margin:120px 0 200px 0;;
    }
    .member_describe{
    font-size:15px;
}
    .fukidashi{
    width:250px;
    }
}
/* ---SP--- */
@media screen and (max-width: 420px) {
    /* 吹き出し */
    .fukidashi{
    width:250px;
    }

    .member_people{
        width:120px;
    }
    .people_name{
    border-bottom: dashed 3px #DB4627; /* ボーダー下部のスタイルを破線で指定する */
    padding-bottom:5px;
    display:inline;
    line-height:2;
    font-size:16px;
    }
    .people_katagaki{
    margin-top:20px;
    margin-bottom:0;
    font-size:12px;
    }
}
</style>
