import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import TransportationExpense from './modules/transportationExpense'
import TransportationExpenseAdmin from './modules/transportationExpenseAdmin'
import CommuterPassPrice from './modules/commuterPassPrice'
import Member from './modules/member'
import miiitsStore from './modules/miiitsStore'

Vue.use(Vuex)

const domain = "http://localhost:8000/"
export default new Vuex.Store({
    modules: {
        TransportationExpense,
        TransportationExpenseAdmin,
        CommuterPassPrice,
        miiitsStore,
        Member
    },
    state: {},
    mutations: {
        /**
         * レスポンスから各ステートのエラーメッセージをセットする
         * @param {*} state
         * @param {string, json} param1
         */
        setErrors(state, {
            namespace,
            errors
        }) {
            Object.entries(errors).forEach(([key, value]) => {
                state[namespace].items[key].error = true
                state[namespace].items[key].errorMsg = value
            })
            alert('入力内容に誤りがあります。\nご確認ください。')
        },
        clearErrors(state, {
            namespace,
        }) {
            Object.keys(state[namespace].items).forEach((key) => {
                state[namespace].items[key].error = false
                state[namespace].items[key].errorMsg = null
            })
        },
        /**
         * ストアにlistをセット
         * @param {*} state
         * @param {string, json} param1
         */
        setList(state, {
            namespace,
            list
        }) {
            state[namespace].list = list
        },
        /**
         * ストアのItems内の項目に値をセット
         * @param {*} state
         * @param {string, string, json} param1
         */
        setItem(state, {
            namespace,
            key,
            value
        }) {
            if (Object.keys(state[namespace].items).includes(key)) {
                state[namespace].items[key].value = value
            }
        },
    },
    actions: {
        /**
         * axiosでPOSTするとき用
         * POSTリクエストパラメータはnamespaceで指定されたItemsをそのまま投げる
         * @param {*} param0
         * @param {*} param1
         */
        postAction({
            state,
            commit
        }, {
            apiName,
            namespace,
            thenAction,
            catchAction
        }) {
            commit('clearErrors', {
                namespace: namespace,
            })
            const thenMethod = thenAction == null ? () => { } : thenAction
            const catchMethod = catchAction == null ? () => { } : catchAction
            const token = getAuthToken();
            const csrftoken = getCsrfToken();
            const params = {}
            Object.entries(state[namespace].items).forEach(([key, value]) => {
                Object.assign(params, {
                    [key]: value.value
                })
            })
            axios
                .post(
                    domain + apiName,
                    params, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrftoken,
                        Authorization: "JWT " + token + "",
                    },
                }
                )
                .then((res) => {
                    thenMethod(res, {
                        namespace
                    })
                    commit('clearErrors', {
                        namespace: namespace,
                    })
                })
                .catch((e) => {
                    catchMethod(e, {
                        namespace
                    })
                    commit('setErrors', {
                        namespace: namespace,
                        errors: e.response.data
                    })
                });
        },
        /**
         * axiosでPUTするとき用
         * PUTリクエストパラメータはnamespaceで指定されたItemsをそのまま投げる
         * @param {*} param0
         * @param {*} param1
         */
        putAction({
            state,
            commit
        }, {
            apiName,
            namespace,
            thenAction,
            catchAction
        }) {
            commit('clearErrors', {
                namespace: namespace,
            })
            const thenMethod = thenAction == null ? () => { } : thenAction
            const catchMethod = catchAction == null ? () => { } : catchAction
            const token = getAuthToken();
            const csrftoken = getCsrfToken();
            const params = {}
            Object.entries(state[namespace].items).forEach(([key, value]) => {
                Object.assign(params, {
                    [key]: value.value
                })
            })
            axios
                .put(
                    domain + apiName,
                    params, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrftoken,
                        Authorization: "JWT " + token + "",
                    },
                }
                )
                .then((res) => {
                    thenMethod(res, {
                        namespace
                    })
                    commit('clearErrors', {
                        namespace: namespace,
                    })
                })
                .catch((e) => {
                    catchMethod(e, {
                        namespace
                    })
                    commit('setErrors', {
                        namespace: namespace,
                        errors: e.response.data
                    })
                });
        },
        /**
         * axiosでDELETEするとき用
         * DELETEリクエストパラメータはnamespaceで指定されたItemsをそのまま投げる
         * @param {*} param0
         * @param {*} param1
         */
        deleteAction({
            commit
        }, {
            apiName,
            namespace,
            thenAction,
            catchAction
        }) {
            const thenMethod = thenAction == null ? () => { } : thenAction
            const catchMethod = catchAction == null ? () => { } : catchAction
            const token = getAuthToken();
            const csrftoken = getCsrfToken();
            axios
                .delete(
                    domain + apiName,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-CSRFToken": csrftoken,
                            Authorization: "JWT " + token + "",
                        },
                    }
                )
                .then((res) => {
                    thenMethod(res, {
                        namespace
                    })
                    commit('clearErrors', {
                        namespace: namespace,
                    })
                })
                .catch((e) => {
                    catchMethod(e, {
                        namespace
                    })
                    commit('setErrors', {
                        namespace: namespace,
                        errors: e.response.data
                    })
                });
        },
        /**
         * axiosで論理削除(PUT)するとき用
         * PUTリクエストパラメータは"deleted_flg=1"を投げる
         * @param {*} param0
         * @param {*} param1
         */
        logicalDeleteAction({
            commit
        }, {
            apiName,
            namespace,
            thenAction,
            catchAction
        }) {
            commit('clearErrors', {
                namespace: namespace,
            })
            const thenMethod = thenAction == null ? () => { } : thenAction
            const catchMethod = catchAction == null ? () => { } : catchAction
            const token = getAuthToken();
            const csrftoken = getCsrfToken();
            const params = { ['deleted_flg']: true }
            axios
                .put(
                    domain + apiName,
                    params, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrftoken,
                        Authorization: "JWT " + token + "",
                    },
                }
                )
                .then((res) => {
                    thenMethod(res, {
                        namespace
                    })
                    commit('clearErrors', {
                        namespace: namespace,
                    })
                })
                .catch((e) => {
                    catchMethod(e, {
                        namespace
                    })
                    commit('setErrors', {
                        namespace: namespace,
                        errors: e.response.data
                    })
                });
        },
        /**
         * axiosでGETするとき用
         *
         * @param {*} param0
         * @param {string, string, function, function, json} param1
         */
        getAction({
            state
        }, {
            apiName,
            namespace,
            thenAction,
            catchAction,
            data
        }) {
            state
            const thenMethod = thenAction == null ? () => { } : thenAction
            const catchMethod = catchAction == null ? () => { } : catchAction
            const dataObj = data == null ? () => { } : data
            const token = getAuthToken()
            axios
                .get(
                    domain + apiName, {
                    data: dataObj,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "JWT " + token + "",
                    },
                })
                .then((res) => {
                    thenMethod(res, {
                        namespace
                    })
                })
                .catch((e) => {
                    catchMethod(e, {
                        namespace
                    })
                });
        },

        /**
         * 一覧取得用処理
         * 指定されたAPIにGETリクエストを投げて、戻りを指定されたnamespaceのlistに格納する
         * @param {*} param0
         * @param {string, string} param1
         */
        getList({
            dispatch,
            commit
        }, {
            apiName,
            namespace
        }) {
            const thenAction = function (res, {
                namespace
            }) {
                commit('setList', {
                    namespace: namespace,
                    list: res.data
                })
            }
            dispatch('getAction', {
                apiName: apiName,
                namespace: namespace,
                thenAction: thenAction
            })
        },

        /**
         * ログインユーザの社員情報の取得
         * @param {*} param0
         * @param {*} param1
         */
        getEmployeeInfo({
            dispatch,
            commit
        }, {
            apiName,
            namespace
        }) {
            const thenAction = function (res, {
                namespace
            }) {
                commit('setItem', {
                    namespace: namespace,
                    key: 'employee_no',
                    value: res.data.employee_no
                })
                commit('setItem', {
                    namespace: namespace,
                    key: 'full_name',
                    value: res.data.full_name
                })
            }
            dispatch('getAction', {
                apiName: apiName,
                namespace: namespace,
                thenAction: thenAction
            })
        },

        /**
         * setItemのアクセサ
         * @param {*} param0
         * @param {*} param1
         */
        setItem({
            commit
        }, {
            namespace: namespace,
            key: key,
            value: value
        }) {
            commit('setItem', {
                namespace,
                key,
                value
            })
        },

        setItems({
            commit
        }, {
            namespace: namespace,
            data: data
        }) {
            commit('clearErrors', {
                namespace: namespace,
            })
            Object.entries(data).forEach(([key, value]) => {
                commit('setItem', {
                    namespace,
                    key,
                    value
                })
            })
        }
    },
})

const getCsrfToken = function () {
    var cookies = document.cookie; //全てのcookieを取り出して
    var cookiesArray = cookies.split(";"); // ;で分割し配列に

    for (var c of cookiesArray) {
        //一つ一つ取り出して
        var cArray = c.split("="); //さらに=で分割して配列に
        if (cArray[0] == "csrftoken") {
            // 取り出したいkeyと合致したら
            return cArray[1];
        }
    }
}
const getAuthToken = function () {
    const session = JSON.parse(sessionStorage.getItem("vue-session-key"));
    return session != null ? session.token : null;
}