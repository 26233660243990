<template>
    <div id="app">
        <div class="container-fluid">
        </div>
        <div class="container-fluid background__orange font-ryo-gothic font-color--white">
            <div class="row region__control">
                <!-- フッターロゴ -->
                <div class="col-12 text-center footer__margin">
                    <img src="@/assets/image/miiitslogo_beige.svg" class="footer__logo--image br__pc mt-3">
                    <img src="@/assets/image/miiitslogo_beige_simple.svg" class="footer__logo--image br__sp mt-3">
                </div>
                <!-- 運営会社リンク -->
                <div class="col-12 text-center pb-0">
                    <a href="https://www.mistnet.co.jp/" target="_blank" rel="noopener noreferrer">
                        <p class="footer_link text-center mt-3 mb-0">運営会社</p>
                    </a>
                </div>
                <!-- <div class="col-md-3 text-lg-left text-center footer__margin">
                    <div>
                        <ul class="navbar-nav d-flex pl-0 justify-content-lg-start justify-content-center footer__list">
                            <li>
                                <router-link to="/" class="footer__link mr-5">TOP</router-link>
                            </li>
                            <li class="">
                                <router-link to="/articleList" class="footer__link mr-5">ARTICLE</router-link>
                            </li>
                            <li class="">
                                <router-link to="/about" class="footer__link mr-5">ABOUT</router-link>
                            </li>
                            <li class="">
                                <router-link to="/memberlist" class="footer__link">MEMBER</router-link>
                            </li>
                        </ul>
                        <p class="text-md-left text-center mt-5 pt-3 mb-0">
                            運営会社<span class="ml-5"></span>株式会社MISTsolution
                        </p>
                        <p class="text-md-left footer__marginleft">
                            <a class="text-lg-left text-center" href="https://www.mistnet.co.jp" target="_blank" rel="noopener noreferrer">
                                WEBサイト<i class="fas fa-external-link-alt font-color--white"></i>
                            </a>
                        </p>
                    </div>
                </div> -->
                <!-- 社員専用ページ -->
                <!-- <div class="col-md-3 col-12 text-md-right text-center">
                    <router-link to="/auth" class="footer__link">
                        <img src="@/assets/image/footer_ilust.svg" class="footer__employee--image">
                    </router-link>
                </div> -->
            </div>
            <!-- copyright -->
            <div class="row region__control mt-4">
                <div class="col-12 text-center">
                    <div>
                        <p class="mb-0">©︎2022  Miiits</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    name: "Footer",
    data: () => ({}),
    props: {},
    computed: {},
    mounted: function () {},
    methods: {}
};
</script>

<!-- 共通CSS -->
<style src="../pages/miiits/css/common.css"></style>

<!-- ローカルCSS -->
<style scoped>
/* 既存上書き */
.navbar-nav {
    flex-direction: unset;
}

/* Footerロゴ */
.footer__logo--image {
    width: 230px;
    margin: 0 auto;
}
    @media (max-width: 992px) {
        .footer__logo--image {
            width: 200px;
        }
    }
        @media (max-width: 420px) {
        .footer__logo--image {
            width: 160px;
        }
    }
.footer_link {
    display: inline-block;
    transition: .2s;
}
.footer_link:hover {
    opacity: .8;
}
/* リンク修正 */
a {
    color: #FFF !important;
    text-decoration: none;
}

/* 社員専用ページ画像 */
.footer__employee--image {
    width: 85px;
    margin-top: 3rem;
}

@media (max-width: 960px) {
    .footer__employee--image {
        margin-top: 0;
    }
}

/* リンク */
.footer__list li {
    margin-right: 1.5rem;
}

@media (max-width: 960px) {
    .footer__list li {
        margin-right: 0.5rem;
    }
}

.footer__link {
    font-size: clamp(1rem, 0.955rem + 0.23vw, 1.125rem);
    text-decoration: none;
    color: white;
    font-weight: 600;
}

.footer__text {
    margin-top: 3rem;
}

/* フッター上部margin */
.footer__margin {
    margin-top: 2.5rem;
    z-index: 99; /*MEMBERリンクが社員向けリンクと被ってるので優先度上げる */
}

@media (max-width: 960px) {
    .footer__margin {
        margin-top: 1rem;
    }
}

@media (max-width: 992px) {
    .footer__marginleft {
        margin-left: 23px;
    }
}

</style>