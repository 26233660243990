// initial state
const state = () => ({
    textKeyword: "",
    displayLimit: 5,
    displayPage: 1,
    totalCount: 0,
    articleArrays: null,
    displayLists: null,
    sort: 1,
})

// getters
const getters = {
    textKeyword: (state) => state.textKeyword,
    displayLimit: (state) => state.displayLimit,
    displayPage: (state) => state.displayPage,
    totalCount: (state) => state.totalCount,
    articleArrays: (state) => state.articleArrays,
    displayLists: (state) => state.displayLists,
    sort: (state) => state.sort,
}

// mutations
const mutations = {
    setTextKeyword(state, textKeyword) {
        state.textKeyword = textKeyword;
    },
    setDisplayLimit(state, count) {
        state.displayLimit = count;
    },
    setDisplayPage(state, page) {
        state.displayPage = page;
    },
    setTotalCount(state, count) {
        state.totalCount = count;
    },
    setArticleArrays(state, data) {
        state.articleArrays = data;
    },
    setDisplayLists(state, data) {
        state.displayLists = data;
    },
    setSort(state, sort) {
        state.sort = sort;
    },
}

// actions
const actions = {
    setTextKeyword(context, textKeyword) {
        context.commit('setTextKeyword', textKeyword);
    },
    setDisplayLimit(context, count) {
        context.commit('setDisplayLimit', count);
    },
    setDisplayPage(context, page) {
        context.commit('setDisplayPage', page);
    },
    setTotalCount(context, count) {
        context.commit('setTotalCount', count)
    },
    setArticleArrays(context, data) {
        context.commit('setArticleArrays', data)
    },
    setDisplayLists(context, data) {
        context.commit('setDisplayLists', data)
    },
    setSort(context, sort) {
        context.commit('setSort', sort)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}