<template>
    <div>
        <search-table :header="header" :list.sync="list" item-key="name" :searchWord.sync="searchString" @clickRow="clickRow" />
    </div>
</template>

<script>
import SearchTable from '../atoms/SearchTable.vue';
export default {
    components: { SearchTable },
    name: "TransportationExpense",
    data: () => ({
        header: [],
    }),
    props: {
        list: Array,
        searchWord: String,
    },
    computed: {
        searchString: {
            get: function() {
                return this.searchWord
            }
        }
    },
    mounted: function () {
        this.header = [
            { text: "社員番号", value: "employee_no" },
            { text: "氏名", value: "name" },
            { text: "申請ステータス", value: "status" },
            { text: "合計金額", value: "total" },
        ];
    },
    methods: {
         clickRow(item) {
            this.$emit("clickRow", item)
        },
    }
};
</script>
<style scoped>
    .seachWord {
        width: 50%;
    }
</style>