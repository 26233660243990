<template>
    <v-select
        v-model="selectValue"
        :items="items"
        :error="error"
        :error-messages="errorMsg"
        outlined
        dense
    />
</template>
<script>
export default {
    props: {
        placeholder: String,
        value: [String, Number],
        items: Array,
        readonly: Boolean,
        error: Boolean,
        errorMsg: Array,
    },
    computed: {
        selectValue: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit("update:value", value);
            },
        },
    },
};
</script>
