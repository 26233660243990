<template>
    <commuter-pass-price-list-table :list.sync="list" @clickRow="clickRow" />
</template>

<script>
import CommuterPassPriceListTable from "../molecules/CommuterPassPriceListTable.vue";
export default {
    components: { CommuterPassPriceListTable },
    name: "CommuterPassPrice",
    data: () => ({
        header: [],
        namespace: "CommuterPassPrice",
    }),
    computed: {
        list: {
            get: function () {
                return this.$store.state[this.namespace].list;
            },
        },
    },
    mounted: function () {
        this.getList();
    },
    methods: {
        getList() {
            this.$store.dispatch("CommuterPassPrice/getList");
        },
        clickRow(data) {
            let arr = this.$store.state[this.namespace]['months']
            for (let i = 0; i < arr.length; ++i) {
                if( data['payday'] == arr[i] ) {
                    let result = confirm("選択したデータの修正・削除を開始します。");
                    if (result) {
                        this.$store.dispatch("setItems", {namespace: this.namespace, data: data });
                    }
                    break;
                } else if (i == arr.length -1) {    //配列の最後の要素まで到達し、かつ登録されている日付と一致しない場合
                    let text = "修正期限を過ぎています。\n修正を希望する場合は上長または管理部までご連絡ください。";
                    alert(text);
                }
            }
        }
    },
};
</script>