<template>
    <div class="pb-5"  v-if="employee_no.value != null">
        <v-row>
            <v-col md="2" class="employeeInfoLabel mt-5 py-0"><label>社員番号</label></v-col>
        </v-row>
        <v-row>
            <v-col class="employeeInfoValue py-1">{{ employee_no.value}}</v-col>
        </v-row>
        <v-row>
            <v-col md="2" class="employeeInfoLabel mt-5 py-0"><label>氏名</label></v-col>
        </v-row>
        <v-row>
            <v-col class="employeeInfoValue py-1">{{ full_name.value }}</v-col>
        </v-row>
        <v-row>
            <v-col md="2" class="employeeInfoLabel mt-5 py-0"><label>合計金額</label></v-col>
        </v-row>
        <v-row>
            <v-col class="employeeInfoValue py-1">{{ total_price }}</v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    data: () => ({
        namespace: "TransportationExpense",
    }),
    computed: {
        employee_no: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["employee_no"];
            },
        },
        full_name: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["full_name"];
            },
		},
        total_price: {
            get: function () {
                return this.$store.state[this.namespace]['list'].reduce((total, item) => total + item.price , 0)
            },
		},
    }
};
</script>
<style scoped>
    .employeeInfoLabel,.employeeInfoValue {
        font-size: 0.8em;
    }
    .employeeInfoLabel{
        margin-left: 10px;
        font-weight: bold;
    }
    .employeeInfoValue{
        margin-left: 30px;
    }
</style>