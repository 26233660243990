import axios from "axios";

export function editAccessCount(id) {
    
    return new Promise(resolve => {
        const payload = axios
            .get(`/miiits/AccessCountUpdate/${id}/`, {
                headers: {
                    'content-type': 'application/json',
                },
            })
            .then(response => {
                return { payload: response };
            })
            .catch(error => {
                return { error };
            });
        resolve(payload);
    });
}