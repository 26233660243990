<template>
    <v-app>
        <v-card class="overflow-hidden">
            <v-app-bar
                absolute
                color="deep-orange accent-4 white--text"
                dark
                elevate-on-scroll
                scroll-target="#scrolling-techniques-7"
            >
                <v-app-bar-nav-icon></v-app-bar-nav-icon>
                <v-toolbar-title>MIST PORTAL</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-hover v-slot:default="{ hover }">
                    <v-icon class="py-5" @click="search" :color="(active || hover) ? 'grey lighten-1' : 'white'">mdi-account-search</v-icon>
                </v-hover>
                <v-hover v-slot:default="{ hover }">
                    <v-icon class="py-5 ml-5" large @click="login" :color="(active || hover) ? 'grey lighten-1' : 'white'">mdi-login</v-icon>
                </v-hover>
                <v-menu left bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item v-for="n in 5" :key="n" @click="() => {}">
                            <v-list-item-title>Option {{ n }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-app-bar>
            <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="800">
                <top />
                <team />
                <club />
                <event />
            </v-sheet>
        </v-card>
    </v-app>
</template>

<script>
import router from "../../router"
import Top from "./Top"
import Team from "./Team"
import Club from "./Club"
import Event from "./Event"

export default {
    name: "Portal",
    components: {
      Top,
      Team,
      Club,
      Event,
    },
    methods: {
        login() {
          router.push("/auth");
        },
        search () {
            alert('You clicked search!')
        },
    }
}
</script>