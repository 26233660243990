<template>
  <v-row class="article__list--box--mb">
    <!-- サムネ画像 -->
    <v-col
      class="
        col-11 col-sm-3
        d-flex
        align-items-center
        justify-content-center
        article__list--box--img
      "
    >
      <router-link
        class="articlelist--nextlink"
        :to="{
          name: 'ArticleDetail',
          params: { id: id },
        }"
      >
        <img
          class="article__list--img"
          v-bind:src="'image/' + this.thumbnail_img_path.slice(7)"
          alt="記事サムネイル画像2"
        />
      </router-link>
    </v-col>
    <!-- 記事タイトル・本文・リンク -->
    <v-col
      class="
        col-sm-7 col-11
        d-flex
        align-items-start
        justify-content-center
        article__list--box--content
      "
    >
      <v-row class="row region__control">
        <v-col class="col-8 text-left">
          <p
            @click="emitGetSearchByCategoryList()"
            class="coetgory__text mb-0"
          >
            {{ transCategory(category_id) }}
          </p>
        </v-col>
        <v-col class="col-3 pb-0 text-right font-color--orange font-fot-rodin">
          <p class="mb-0 article__list--date_SP">
            {{ add_datetime | moment }}
          </p>
        </v-col>
        <router-link
          class="articlelist--nextlink"
          :to="{
            name: 'ArticleDetail',
            params: { id: id },
          }"
        >
          <p class="article__list--title px-5 mb-0">
            {{ title }}
          </p>
        </router-link>
        <v-col class="col-6 py-0 text-left px-5">
          <button class="pt-4" @click="plus(id)">
            <div v-if="btn_flg" class="goodbutton_stop">
              <img :src="src2" width="17" height="15" />
              {{ likecount }}
            </div>
            <div v-if="!btn_flg">
              <div v-if="!number_flg" class="goodbutton_anime">
                <div class="heartsize">
                <lottie
                  ref="anim"
                  :options="lottieOptions1"
                  v-on:animCreated="handleAnimation1"
                  class="likeshare_icon"
                /></div>
              {{ likecount }}
              </div>
              <div v-if="number_flg" class="goodbutton_anime">
                <div class="heartsize2">
                <lottie
                  ref="anim"
                  :options="lottieOptions1"
                  v-on:animCreated="handleAnimation1"
                  class="likeshare_icon"
                /></div><div class ="onenumber">
              {{  likecount }}</div>
              </div></div>
          </button>
        </v-col>
        <v-col class="col-6 py-0 text-right pt-1">
          <p class="text-right m-0 pt-3">
            <router-link
              class="articlelist--nextlink"
              :to="{
                name: 'ArticleDetail',
                params: { id: id },
              }"
            >
              記事を読む<span class="mr-1"></span
              ><i class="fa fa-angle-right"> </i>
            </router-link>
          </p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import Lottie from "vue-lottie";
import animationData1 from "@/assets/image/goodicon_anime.json";
import * as ApiMiiitsSearchList from "@/apis/ApiMiiitsSearchList";
import * as LikeCountUpdateAPI from "@/apis/LikeCountUpdateAPI";
import * as ArticleDetailAPI from "@/apis/ArticleDetailAPI";
export default {
  data() {
    return {
      open: false,
      imgPath: process.env.VUE_APP_IMG_BASE,
      categoryArray: [],
      button: [],
      likecount2: "",
      lottieOptions1: {
        animationData: animationData1,
        loop: false,
      },
    };
  },
  props: {
    add_datetime: String,
    title: String,
    category_id: String,
    like_count: String,
    id: String,
    thumbnail_img_path: String,
    index: String,
    src_goodicon: String,
    btn_flg: Boolean,
    number_flg: Boolean,
  },
  components: {
    Lottie,
  },
  computed: {
    src: function () {
      return this.imgPath + this.profile_img_maru_path;
    },
    likecount: function () {
      return this.like_count;
    },
    src2: function () {
      return this.src_goodicon;
    },
  },
  mounted: function () {
    this.getCategoryArray();
  },
  filters: {
    // 日付フォーマット
    moment: function (date) {
      return moment(date).format("YYYY.MM.DD");
    },
  },
  methods: {
    // カテゴリ一覧を取得
    getCategoryArray() {
      ApiMiiitsSearchList.getArticleCategory("articleCategory")
        .then((response) => {
          this.categoryArray = response.payload.data;
        })
        .catch((error) => {
          console.log("Error : " + error.response);
        });
    },

    // カテゴリフォーマット
    transCategory(category) {
      let step = this.categoryArray.length;
      for (var i = 0; i < step; i++) {
        const categoryId = this.categoryArray[i].id;
        if (categoryId == category) {
          return this.categoryArray[i].category_name;
        }
      }
    },

    plus(id) {
      if (document.cookie.indexOf("article" + id + "=liked") == -1) {
        LikeCountUpdateAPI.editLikeCount(id)
          .then(() => {
            document.cookie = "article" + id + "=liked";
            ArticleDetailAPI.getArticleDetail(this.id)
              .then((response) => {
                this.likecount2 = response.payload.data.like_count;
                this.$emit("likeoneplus", this.likecount2, this.index);
              })
              .catch((error) => {
                console.log("Error : " + error.response);
              });
          })
          .catch((error) => {
            console.log("Error : " + error.response);
          });
      }
    },

    like() {
      this.likecount = this.like_count;
    },

    handleAnimation1: function (anim) {
      this.anim = anim;
    },
    // カテゴリ検索
    emitGetSearchByCategoryList() {
        this.$emit('emitEvent');
    },
  },
};
</script>

<!-- 共通CSS -->
<style src="../pages/miiits/css/common.css"></style>

<!-- ローカルCSS -->
<style scoped>
.body__wrap {
  overflow: hidden;
}

/* ================================ * /
            カルーセル
==================================== */
.carousel__background {
  background-image: url("../../assets/image/carousel_background.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

/* サムネイル画像 */
.carousel--thumbnail__size {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  -o-object-fit: cover;
  object-fit: cover;
}

/* sm */
@media (max-width: 600px) {
  .carousel--thumbnail__size {
    height: 200px;
  }
}

/* カルーセル枠 */
.carousel__flame {
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
}

.carousel__inner--height {
  height: 410px;
}

/* sm */
@media (max-width: 600px) {
  .carousel__inner--height {
    height: 620px;
  }
}

/* 記事タイトル調整 */
.carousel__article--head {
  color: #333333;
  font-size: 30px;
  font-weight: 600;
  line-height: 35px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
/* 1500px以上 */
@media (min-width: 1500px) {
  .carousel__article--head {
    font-size: 40px;
    line-height: 45px;
  }
}

/* md */
@media (max-width: 768px) {
  .carousel__article--head {
    font-size: 22px;
    line-height: 35px;
  }
}

/* SP */
@media (max-width: 420px) {
  .carousel__article--head {
    font-size: 20px;
    line-height: 30px;
  }
}

/* 記事本文 */
.carousel__article--text {
  color: #333333;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

/* SP */
@media (max-width: 420px) {
  .carousel__article--text {
    font-size: 14px;
    line-height: 20px;
  }
}

/* 続きを読む */
.carousel__article--link {
  text-decoration: none;
  font-weight: 600;
  color: #db4627;
}

/* カルーセルコントロールボタン */
.carousel-control-prev-icon {
  background-image: url("../../assets/image/carousel_prev.svg");
  top: 46%;
  bottom: 50%;
  opacity: 1;
  right: 97%;
  position: absolute;
  height: 70px;
  width: 70px;
}

@media (max-width: 420px) {
  .carousel-control-prev-icon {
    right: 91%;
    height: 50px;
    width: 50px;
  }
}

.carousel-control-next-icon {
  background-image: url("../../assets/image/carousel_next.svg");
  top: 46%;
  bottom: 50%;
  opacity: 1;
  right: -3.5%;
  position: absolute;
  height: 70px;
  width: 70px;
}

@media (max-width: 420px) {
  .carousel-control-next-icon {
    right: -8.5%;
    height: 50px;
    width: 50px;
  }
}

/* カルーセルインジケーター */
.carousel__indicators--btn {
  background-color: #999;
  border-radius: 1px;
}

.carousel-indicators .active {
  background-color: #db4627;
}

/* ================================ * /
            アクセスの多い記事
==================================== */
/* タイトル */
.ranklist__title {
  position: relative;
}

.ranklist__title p {
  display: inline-block;
  color: #db4627;
  font-weight: 600;
  font-size: 24px;
  background-color: #fff;
  border-radius: 28px;
  padding: 25px;
}

.ranklist__title img {
  position: absolute;
  width: 60px;
  height: auto;
  top: -10%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

/* アクセスの～記事親 */
.article__ranklist {
  display: flex; /* 子要素を横並べ */
  flex-wrap: wrap;
  justify-content: center;
}

/* アクセスの～記事一覧 */
.article__ranklist--box {
  width: 300px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  position: relative;
  flex-direction: column; /* 子要素を縦並べ */
  min-width: 200px;
  margin: 1%;
}

@media (max-width: 957px) {
  .article__ranklist--box {
    margin: 4%;
  }
}

@media (max-width: 420px) {
  .article__ranklist--box {
    margin: 8%;
  }
}

/* アクセスの多い記事サムネイル画像 */
.article__ranklist--img {
  width: 100%;
  height: 200px;
  border-radius: 10px 10px 0 0;
  object-fit: cover;
}

/* カテゴリ名 */
.coetgory__text {
  background-color: #db4627;
  color: #fff;
  display: inline-block;
  border-radius: 10px;
  text-align: center;
  padding-inline: 0.5rem;
  font-weight: 500;
  font-size: 14px;
  padding: 3px 15px;
  cursor: pointer;
  transition: .4s;
}
.coetgory__text:hover {
  opacity: .8;
  transition: .4s;
}

/* 記事タイトル */
.article__ranklist--title {
  color: #333333;
  font-weight: 600;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: 0.7rem;
}

/* ================================ * /
            記事リスト部
==================================== */
/* ドロップダウン */
.articlelistst__dropdown {
  background-color: #fff;
  color: #db4627;
  border-color: transparent;
  transition: 1s all;
  border-radius: 15px;
}
@media (max-width: 992px) {
  .articlelistst__dropdown {
    margin-bottom: 2rem;
  }
}

.articlelistst__dropdown:after {
  margin-left: 5em;
}

.articlelistst__dropdown:focus {
  box-shadow: 0 0 0 0 transparent;
}

/* 既存クラス上書き */
.dropdown-menu {
  color: #db4627;
  padding-left: 0;
  min-width: 10.5rem;
}

/* 記事見出し */
.articlelist__head {
  color: #db4627;
  font-size: 24px;
  font-weight: 600;
}

.articlelist__head--icon {
  width: 70px;
  padding-right: 0.5rem;
  height: auto;
}

.articlelist__head--icon {
  width: 70px;
  height: auto;
}

@media (max-width: 768px) {
  .articlelist__head--icon {
    width: 40px;
  }
}

/* カテゴリ見出し */
.articlelist__cotegory {
  color: #db4627;
  border-bottom: 2px solid #db4627;
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 5px;
  position: relative;
  padding-left: 2rem;
}

.articlelist__cotegory:before {
  background: #db4627;
  content: "";
  height: 24px;
  width: 24px;
  left: 0;
  position: absolute;
  top: 0;
}

/* 続きを読む */
.articlelist--nextlink {
  text-decoration: none;
  font-weight: 600;
  color: #db4627;
  font-size: 15px;
  padding: 0;
}

/* カテゴリアイコン表示調整 */
.article__cotegory--list {
  padding: 7px 0 0px 35px;
  background-repeat: no-repeat;
  background-position: 0 5px;
  background-size: 25px;
}

.article__cotegory--link {
  text-decoration: none;
  color: #db4627;
  font-weight: 500;
}

/* 記事一覧 */
.article__list--box--img {
  background: #fff;
  border-radius: 15px 0px 0px 15px;
  margin: -1%;
}

.article__list--box--content {
  background: #fff;
  border-radius: 0px 15px 15px 0px;
  margin: -1%;
}

@media (max-width: 600px) {
  .article__list--box--img {
    border-radius: 15px 15px 0px 0px;
  }

  .article__list--box--content {
    border-radius: 0px 0px 15px 15px;
    padding-bottom: 1.5rem;
  }
}

@media (max-width: 1023px) {
  .article__list--box--mb {
    margin-bottom: 1rem;
    width: 100%;
  }
}

.article__list--img {
  width: 100%;
  height: 150px;
  border-radius: 10px;
  -o-object-fit: cover;
  object-fit: cover;
  padding: 0.1rem;
}

.article__list--title {
  color: #333333;
  font-weight: 600;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: 0.5rem;
  min-height: 45px;
}

@media (max-width: 992px) {
  .article__list--date_SP {
    margin-left: -1rem;
  }

  .article__list--title {
    margin-top: 0.3rem;
  }
}

.goodbutton_stop{
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 0px;
  margin-left: 1.5px;
}

.goodbutton_anime {
  width:42px;
  height: 24px;
  display: flex;
  align-items: flex-end;
}

.heartsize{
  width: 24.873px;
  height: 25.869px;
}

.heartsize2{
  width: 24.873px;
  height: 25.869px;
  margin-left: 1.5px;
}

.onenumber{
  margin-left: 5px;
}
</style>