<template>
    <v-app>
    <Header />
    <div class="body__wrap background__beige">
        <section>
            <div class="body__gap--control"></div>
            <div class="body__gap--control br__pc"></div>
            <div class="concontainer-fluid">
                <!-- 画面タイトル -->
                <div class="row mt-5 mt-sm-3">
                    <div class="col-12 text-center">
                        <h1 class="message__404--font mb-5">404</h1>
                        <p class="message__notFoundfont--font mt-5">
                            <!-- パラメーターが存在しない場合 -->
                            <span v-if="typeof page_name === 'undefined'">
                                ご指定のページが<br class="br__sp">見つかりませんでした。
                            </span>
                            <!-- 画面もしくはメンバーが存在しない場合 -->
                            <span v-else>
                                ご指定の{{ page_name }}が<br class="br__sp">見つかりませんでした。
                            </span>
                        </p>
                        <p class="message__pageIdou--font">
                            ページが移動、<br class="br__sp">もしくは削除されているかもしれません。
                        </p>
                    </div>
                </div>
                <!-- 404画像 -->
                <div class="row mt-1 mb-5">
                    <div class="col-12 text-center">
                        <img
                            :src="`${imgBasePath}notFoundError/top_about.svg`"
                            class="img__size ml-5"
                            alt="404画像"
                        >
                    </div>
                </div>
                <!-- ボタン -->
                <div class="row">
                    <div class="col-12 text-center">
                        <!-- パラメーターが存在しない場合 -->
                        <router-link
                            v-if="typeof page_name == 'undefined'"
                            to="/"
                            class="link__button mr-5"
                        >
                            トップページへ戻る
                        </router-link>
                        <!-- 画面が存在しない場合 -->
                        <router-link
                            v-else
                            :to="transition_path"
                            class="link__button mr-5"
                        >
                            {{ btn_name }}へ戻る
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="body__gap--control"></div>

        </section>
    </div>
    <Footer />
    </v-app>
</template>
<!--ファーストビューの表示-->
<script>
    // ヘッダーとフッター
    import Header from '@/components/molecules/Header.vue'
    import Footer from '@/components/molecules/Footer.vue'

    export default {
        name: "NotFoundError",
        props: {
            page_name : String,
            btn_name : String,
            transition_path : String,
        },
        components: {
            Header,Footer
        },
        data() {
            return {
                imgBasePath: process.env.VUE_APP_IMG_BASE,
            }
        },
        methods: {
            pagePath() {
                if (this.$route.params.param == 1) {
                    return '/articleList'
                } else {
                    return '/memberList'
                }
            },
        },
    };
</script>

<!-- 共通CSS -->
<style src="../miiits/css/common.css"></style>

<!-- ローカルCSS -->
<style scoped>
    /* 全体 */
    /* 右の余白をなくす */
    .body__wrap{
        overflow: hidden;
    }
    /* 404 フォント */
    .message__404--font{
        font-family: ryo-gothic-plusn,sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 6rem;
        letter-spacing: 0.5rem;
        color: #DB4627;
    }
    @media(max-width:751px){
        .message__404--font {
            font-size: 5rem;
        }
    }

    /* ページが見つかりません フォント */
    .message__notFoundfont--font {
        font-family: ryo-gothic-plusn,sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        color: #DB4627;
    }
    @media(max-width:751px){
        .message__notFoundfont--font {
            font-size: 18px;
        }
    }

    /* 削除されたかもしれません フォント */
    .message__pageIdou--font {
        font-family: ryo-gothic-plusn,sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        color: #DB4627;
    }
    @media(max-width:751px){
        .message__pageIdou--font {
            font-size: 16px;
            margin-top: 2.4rem;
        }
    }

    /* 画像 */
    .img__size {
        width: 20%;
        height: auto;
    }
    @media(max-width:751px) {
        .img__size {
            width: 65%;
        }
    }

    /* オレンジ押し込みリンクボタン */
    .link__button {
        color: #DB4627;
        font-size: 20px;
        font-weight: 900;
        background: #fff;
        padding: 17px 30px;
        border: 3px solid #DB4627;
        box-shadow: 5px 5px 0 #db4627;
        width: 280px;
        height: 70px;
        display: inline-block;
        transition: .3s;
        text-align: center;
        text-decoration: none;
        align-items: center;
        border-radius: 40px;
        font-family: ryo-gothic-plusn,sans-serif;
        font-style: normal;
        font-weight: 600;
    }
    .link__button:hover {
        transform: translateY(5px);
        box-shadow: 0 0 0 #DB4627;
    }
</style>