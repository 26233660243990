<template>
    <v-app>
    <Header />
    <div class="body__wrap background__beige" style="overflow:hidden;">
        <!-- 自己紹介 -->
        <div>
        <MemberDetail
            :id = id
            :miiits_team_id = miiits_team_id
            :pen_name = pen_name
            :pen_name_roman = pen_name_roman
            :job_category_name = job_category_name
            :self_introduct_stat = self_introduct_stat
            :profile_img_path = profile_img_path
        />
        </div>
        <!-- このメンバーが書いた記事 -->
        <section>
            <div v-if="articleList[0]">
                <div class="row">
                    <div class="article__link--area">
                        <div class="article__linkhead">
                            <div class="col-12 text-left">
                                <div class="kanrenpickup_titleicon">
                                    <p class="font-ryo-gothic font-color--orange font-weight--900 font-size--20" style="margin-left:10px;">
                                    このメンバーが書いた記事
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- 記事リンク -->
                        <div class = "article__link">
                            <div v-for="(item,index) in ArticleListItems1()" :key="index" class = "article__link">
                                <MemberDetailArticle
                                    :index = index.toString()
                                    :id = item.id.toString()
                                    :title = item.title
                                    :thumbnail_img_path = item.thumbnail_img_path.slice(7)
                                    :category_name = item.category_id.category_name
                                    :like_count = item.like_count.toString()
                                    :add_datetime_y = item.add_datetime.toString().slice(0,4)
                                    :add_datetime_m = item.add_datetime.toString().slice(5,7)
                                    :add_datetime_d = item.add_datetime.toString().slice(8,10)
                                    :btn_flg="btn_flg[ (pageno-1) * 6 + index]"
                                    :src_goodicon="src_goodicon[ (pageno-1) * 6 + index]"
                                    @likeoneplus = "likeoneplus"
                                />
                            </div>
                        </div>
                        <div class = "article__link">
                            <div v-for="(item,index) in ArticleListItems2()" :key="index" class = "article__link">
                                <MemberDetailArticle
                                    :index = (index+2).toString()
                                    :id = item.id.toString()
                                    :title = item.title
                                    :thumbnail_img_path = item.thumbnail_img_path.slice(7)
                                    :category_name = item.category_id.category_name
                                    :like_count = item.like_count.toString()
                                    :add_datetime_y = item.add_datetime.toString().slice(0,4)
                                    :add_datetime_m = item.add_datetime.toString().slice(5,7)
                                    :add_datetime_d = item.add_datetime.toString().slice(8,10)
                                    :btn_flg="btn_flg[ (pageno-1) * 6 + index + 2]"
                                    :src_goodicon="src_goodicon[ (pageno-1) * 6 + index + 2]"
                                    @likeoneplus = "likeoneplus"
                                />
                            </div>
                        </div>
                        <div class = "article__link">
                            <div v-for="(item,index) in ArticleListItems3()" :key="index" class = "article__link">
                                <MemberDetailArticle
                                    :index ="(index+4).toString()"
                                    :id ="item.id.toString()"
                                    :title ="item.title"
                                    :thumbnail_img_path ="item.thumbnail_img_path.slice(7)"
                                    :category_name ="item.category_id.category_name"
                                    :like_count ="item.like_count.toString()"
                                    :add_datetime_y ="item.add_datetime.toString().slice(0,4)"
                                    :add_datetime_m ="item.add_datetime.toString().slice(5,7)"
                                    :add_datetime_d ="item.add_datetime.toString().slice(8,10)"
                                    :btn_flg="btn_flg[ (pageno-1) * 6 + index + 4]"
                                    :src_goodicon="src_goodicon[ (pageno-1) * 6 + index + 4]"
                                    @likeoneplus = "likeoneplus"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="body__gap--controlmini"></div>
                <pagination 
                    @pageno = "pagenoupd"
                />
            </div>
        </section>
        <div class="body__gap--control"></div>

        <!-- メンバー一覧へボタン -->
        <section>
            <div class="concontainer-fluid">
                <div class="row">
                        <div class="col-12 text-center mt-5">
                            <router-link :to="{ name: 'MemberList'}">
                                <a class="button font-ryo-gothic">
                                メンバー一覧へ
                                <img class="kijiall_yajirushi" src="/image/memberDetail/kijiall_yajirushi.png" alt="kijiall_yajirushi">
                                </a>
                            </router-link>
                        </div>
                </div>
            </div>
        </section>
        <div class="body__gap--control"></div>
        <div class="body__gap--controlmini"></div>
    </div>
    <Footer />
    </v-app>
</template>

<script>
import Header from '@/components/molecules/Header.vue'
import Footer from '@/components/molecules/Footer.vue'
import MemberDetailArticle from '@/components/molecules/MemberDetailArticle.vue'
import MemberDetail from '@/components/molecules/MemberDetail.vue'
import * as MemberDetailAPI from "../../../apis/MemberDetailAPI"
import * as MemberDetailArticleAPI from "../../../apis/MemberDetailArticleAPI"
import Pagination from "@/components/molecules/Pagination.vue";

export default {
    name: 'app',
    components: {
        Footer, Header, MemberDetailArticle, MemberDetail, Pagination
    },
    data() {
        return{
            id:'',
            miiits_team_id:'',
            pen_name:'',
            pen_name_roman :'',
            job_category_name :'',
            self_introduct_stat :'',
            profile_img_path :'',
            articleList : [],
            pageno: "",
            src_goodicon:[],
            btn_flg:[],
        };
    },
    methods: {
        ArticleListItems1() {
            if(this.$store.state.miiitsStore.displayLists){
                return this.$store.state.miiitsStore.displayLists.slice(0,2);
            }
        },
        ArticleListItems2() {
            if(this.$store.state.miiitsStore.displayLists){
                return this.$store.state.miiitsStore.displayLists.slice(2,4);
            }
        },
        ArticleListItems3() {
            if(this.$store.state.miiitsStore.displayLists){
                return this.$store.state.miiitsStore.displayLists.slice(4,6);
            }
        },
        //ページャーの番号取得
        pagenoupd(arg1){
            this.pageno = arg1;
            this.setgoodicon(this.pageno);
        },

        //いいね数更新
        likeoneplus(arg1,arg2){
            let a = Number(arg2) + 6*(Number(this.pageno) - 1);
            this.articleList[a].like_count = arg1;
            this.btn_flg[a] = false;
        },

        //いいね済み記事のアイコンを赤くする
        setgoodicon(pageno){
        let limit = this.$store.state.miiitsStore.displayLimit;
        let max = pageno*limit;
        if(this.articleList.length < max){
            max = this.articleList.length;
        }
        for(let i = (pageno - 1)*limit; i < max; i++){
            if(document.cookie.indexOf('article'+ this.articleList[i].id + '=liked') == -1){
                this.src_goodicon[i] = require("@/assets/image/goodicon_before.svg")
                this.btn_flg[i] = true;
            }else{
                this.src_goodicon[i] = require("@/assets/image/goodicon_after.svg")
                this.btn_flg[i] = true;
            }
        }
        },
    },

    created: function() {
        MemberDetailAPI.getMemberDetail(this.$route.params.id)
        .then(response => {
            this.id = response.payload.data.id.toString();
            this.miiits_team_id = response.payload.data.miiits_team_id.toString();
            this.pen_name = response.payload.data.pen_name;
            this.pen_name_roman = response.payload.data.pen_name_roman;
            this.job_category_name = response.payload.data.job_category_name;
            this.self_introduct_stat = response.payload.data.self_introduct_stat;
            this.profile_img_path = response.payload.data.profile_img_path;
        })
        .catch(error => {
                // 対象がない場合、404に遷移
                this.$router.push({
                    name: 'ApiNotFoundError',
                    params: {
                        page_name : "ページ",
                        btn_name : "メンバー一覧",
                        transition_path : "/memberList",
                    }
                }),
            // }
            console.log("Error : " + error);
        });

        MemberDetailArticleAPI.getMemberDetailArticleList(this.$route.params.id)
        .then(response => {
            this.articleList = response.payload.data;
            // 6件ずつで表示
            this.$store.dispatch("miiitsStore/setDisplayLimit", 6);
            // 取得件数をstoreに保存する
            this.$store.dispatch("miiitsStore/setTotalCount", this.articleList.length);
            // 取得配列をstoreに保存する（分割する前）
            this.$store.dispatch("miiitsStore/setArticleArrays", this.articleList);
            // 配列を分割して、DisplayListsに保存する
            this.$store.dispatch("miiitsStore/setDisplayLists", this.articleList.slice(0,this.$store.state.miiitsStore.displayLimit));
            //いいね済み記事のアイコンを赤くする
            this.setgoodicon(1)
        })
        .catch(error => {
            console.log("Error : " + error.response);
        });
    },
    mounted(){
        this.pageno = 1;
    }
}
</script>

<!-- 共通CSS -->
<style src="../miiits/css/common.css"></style>

<!-- ローカルCSS -->
<style scoped>
/* 関連記事リンク部 */
.menberd__link--area {
    width: 70%;
    margin: 0 auto;
    max-width: 800px;
}

@media (max-width: 992px) {
    .menberd__link--area {
        width: 80%;
    }
}

@media (max-width: 576px) {
    .menberd__link--area {
        width: 90%;
    }
}



/* この人が書いた記事文言 */
.memberd__linkhead {
    margin: 0 auto;
    padding-top: 4rem;
}

@media (max-width: 420px) {
    .memberd__linkhead {
        padding-left: 2rem;
    }
}

@media (max-width: 350px) {
    .memberd__linkhead {
        padding-left: 0.5rem;
    }
}



/* メンバー一覧へボタン */
.button {
    color:#DB4627;
    font-size: 20px;
    font-weight: 900;
    background: #fff;
    padding: 18px 30px;
    border: 3px solid #DB4627;
    box-shadow: 5px 5px 0 #DB4627;
    border-radius: 80px;
    width:150px;
    height:150px;
    display: inline-block;
    transition: .3s;
    text-align: center;
    text-decoration: none;
}
.button:hover {
    transform: translateY(5px);
    box-shadow: 0 0 0 #DB4627;
}

.kijiall_yajirushi{
    padding-top:10px;
    width:60%;

}
/* ================================ * /
        PICKUP、関連記事
==================================== */
/* タブレット */
@media (max-width: 820px) {
/* 自己紹介 */
/* 自己紹介画像 */
.memberdetails_people_img{
    width:300px;
    height:300px;
    object-fit: cover;
    border-radius:50px;
    position: absolute;
    bottom:220px;
    right:350px;

}
/* 基本情報 */
.member_introductionabout{
    margin-top:100px;
}



/* 全体 白背景*/
.container-fluid{
    max-width: 700px;
}
/* .memberlist_range{
    max-width:503px;
    position:absolute;
} */

}

/* SP */
@media screen and (max-width: 526px) {

.menberd__link--area {
    width: 100%;
}

/* 自己紹介 */
.member_introduction{
    margin-top:0px;
    background-color:#FCF8F2;
    border-radius: 30px;
    padding: 25px 10px 25px 10px;
}
.member_introduction_text{
    text-align:center;
    font-size:13px;

}

.member_introductionabout{
    margin-top:130px;
    margin-bottom:20px;
}

/* 自己紹介文の枠*/
.member_introduction{
    margin-top:50px;
    background-color:#FCF8F2;
    border-radius: 30px;
    padding: 20px;
}
/* 自己紹介文 */
.member_introduction_text{
    text-align:left;
    margin-bottom:0;
}
/* 基本情報紹介 */
.member_introductionabout{
    /* margin-top:30px; */
}
/* 自己紹介画像 */
.memberdetails_people_img{
    width:200px;
    height:200px;
    object-fit: cover;
    border-radius:200px;
    position: absolute;
    bottom:500px;
    left:50px;
}

/* 自己紹介文の枠*/
.member_introduction{
    background-color:#FCF8F2;
    border-radius: 30px;
    padding: 20px;
}
/* 自己紹介文 */
.member_introduction_text{
    text-align:left;
    margin-bottom:0;
}

/* メンバー一覧ボタン */
    .button {
    color:#DB4627;
    font-size: 11px;
    font-weight: 800;
    background: #fff;
    padding: 8px 20px;
    border: 3px solid #DB4627;
    box-shadow: 5px 3px 0 #DB4627;
    border-radius: 80px;
    width:90px;
    height:90px;
    display: inline-block;
    transition: .3s;
    text-align: center;
    text-decoration: none;
    }
    .button:hover {
    transform: translateY(5px);
    box-shadow: 0 0 0 #DB4627;
    }

    .kijiall_yajirushi{
    padding-top:5px;
    width:60%;
    }

}

.goodbutton_stop{
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1.5px;
  margin-left: 1px;
}

.goodbutton_anime {
    width:42px;
    height: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
}

.heartsize{
  width: 24.873px;
  height: 26.9095px;
}

</style>