<template>
    <v-app>
        <v-sheet>
    <v-toolbar color="brown" dark>
        <v-toolbar-title>SNS</v-toolbar-title>
  </v-toolbar>
  <v-timeline>
    <v-timeline-item
      v-for="n in 3"
      :key="n"
      color="red lighten-2"
      large
    >
      <template v-slot:opposite>
        <span>Tus eu perfecto</span>
      </template>
      <v-card class="elevation-2">
        <v-card-title class="headline">
          Lorem ipsum
        </v-card-title>
        <v-card-text>
          Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando voluptatibus, vix an salutandi sententiae.
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
  </v-sheet>
  </v-app>
</template>

<script>
  export default {
    name: 'Sns',
    data: () => ({
      items: [
        {
          color: 'red lighten-2',
          icon: 'mdi-star',
        },
        {
          color: 'purple darken-1',
          icon: 'mdi-book-variant',
        },
        {
          color: 'green lighten-1',
          icon: 'mdi-airballoon',
        },
        {
          color: 'indigo',
          icon: 'mdi-buffer',
        },
      ],
    }),
  }
</script>