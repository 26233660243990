import { render, staticRenderFns } from "./FormLabelText.vue?vue&type=template&id=444e24d5&"
import script from "./FormLabelText.vue?vue&type=script&lang=js&"
export * from "./FormLabelText.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports