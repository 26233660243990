<template>
    <v-layout fluid fill-height justify-center>
        <v-sheet class="pa-12" color="grey lighten-3" fill-height width="100%">
            <v-sheet :elevation="1" width="80%" class="mx-auto">
                <transportation-employee-search />
            </v-sheet>
            <v-sheet :elevation="1" width="80%" class="mx-auto mt-8">
                <transportation-summary-info />
            </v-sheet>
            <v-sheet :elevation="1" width="80%" class="mx-auto mt-8">
                <transportation-expense-admin-list />
            </v-sheet>
            <v-sheet :elevation="1" width="80%" class="mx-auto mt-8">
                <v-expansion-panels accordion>
                    <v-expansion-panel @click="reset">
                        <v-expansion-panel-header></v-expansion-panel-header>
                        <v-expansion-panel-content>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-sheet>
            <v-sheet :elevation="1" width="80%" class="mt-8 mx-auto">
            </v-sheet>
        </v-sheet>
    </v-layout>
</template>

<script>
import TransportationSummaryInfo from '../organisms/TransportationSummaryInfo.vue';
import TransportationEmployeeSearch from "../organisms/TransportationEmployeeSearch.vue";
import TransportationExpenseAdminList from '../organisms/TransportationExpenseAdminList.vue';
export default {
    name: "TransportationExpenseAdminTemplate",
    components: {
        TransportationEmployeeSearch,
        TransportationExpenseAdminList,
        TransportationSummaryInfo,
    },
    data: () => ({
        namespace: "TransportationExpense",
    }),
    methods: {
        reset: function() {
            this.$store.commit('TransportationExpense/resetItem')
        }
    }
};
</script>