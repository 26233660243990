<template>
<div>
    <v-data-table :items="list" :headers="header" item-key="name" :search="searchWord" :custom-filter="filterOnlyCapsText" @click:row="clickRow" />
</div>
</template>
<script>
export default {
    props: {
        header: Array,
        list: Array,
        searchWord: String
    },
    methods: {
        filterOnlyCapsText (value, search) {
            return value != null &&
                search != null &&
                typeof value === 'string' &&
                value.toString().indexOf(search) !== -1
      },
      clickRow(data) {
          this.$emit("clickRow", data)
      },
    }
};
</script>
