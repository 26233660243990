<template>
    <v-app>
        <v-sheet>
            <v-toolbar color="teal" dark>
                <v-toolbar-title>TEAM</v-toolbar-title>
            </v-toolbar>
            <v-list>
                <v-list-group :value="false" prepend-icon="mdi-account-cog" no-action >
                    <template v-slot:activator>
                        <v-list-item-title>管理本部</v-list-item-title>
                    </template>

                    <v-list-item
                        v-for="([title], i) in manages"
                        :key="i"
                        link
                    >
                        <v-list-item-title v-text="title"></v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-group :value="false" prepend-icon="mdi-account-cog" no-action>
                    <template v-slot:activator>
                        <v-list-item-title>営業本部</v-list-item-title>
                    </template>

                    <v-list-item
                        v-for="([title], i) in sales"
                        :key="i"
                        link
                    >
                        <v-list-item-title v-text="title"></v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-group :value="false" prepend-icon="mdi-apple-airplay">
                    <template v-slot:activator>
                        <v-list-item-title>ICTサービス本部</v-list-item-title>
                    </template>

                    <v-list-group :value="false" no-action sub-group>
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>ソリューションサービス事業部</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item
                            v-for="([title], i) in solutions"
                            :key="i"
                            link
                        >
                            <v-list-item-title v-text="title"></v-list-item-title>
                        </v-list-item>
                    </v-list-group>

                    <v-list-group :value="false" no-action sub-group>
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>プロダクトサービス事業部</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item
                            v-for="([title], i) in products"
                            :key="i"
                            link
                        >
                            <v-list-item-title v-text="title"></v-list-item-title>
                        </v-list-item>
                    </v-list-group>

                    <v-list-group :value="false" no-action sub-group>
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>関西・四国事業部</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item
                            v-for="([title], i) in easts"
                            :key="i"
                            link
                        >
                            <v-list-item-title v-text="title"></v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </v-list-group>
                <v-list-group :value="false" prepend-icon="mdi-charity" no-action>
                    <template v-slot:activator>
                        <v-list-item-title>LBDサービス本部</v-list-item-title>
                    </template>

                    <v-list-item
                        v-for="([title], i) in lbds"
                        :key="i"
                        link
                    >
                        <v-list-item-title v-text="title"></v-list-item-title>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-sheet>
    </v-app>
</template>

<script>
export default {
    name: "Team",
    data: () => ({
      manages: [
        ['管理部'],
        ['人事部'],
        ['法務コンプライアンス部'],
      ],
      solutions: [
        ['金融ソリューション部門'],
        ['インフラソリューション部門'],
        ['ITサービスマネージメント部門'],
      ],
      lbds: [
        ['スポーツBPO事業'],
        ['アグリ事業'],
        ['メディア事業'],
        ['地域ポータル事業'],
      ],
    }),
};
</script>