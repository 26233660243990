<template>
    <commuter-pass-price-template />
</template>

<script>
import CommuterPassPriceTemplate from '../templates/CommuterPassPriceTemplate.vue'
export default {
    name: "CommuterPassPrice",
    components: {
        CommuterPassPriceTemplate,
    },
};
</script>