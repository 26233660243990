<template>
    <div>
        <transportation-expense-list-table
            :list.sync="list"
            @editItem="editItem"
            @deleteItem="deleteItem"
        />
        <v-dialog
            v-model="dialog"
            fullscreen
            transition="dialog-bottom-transition"
        >
            <v-card width="70%">
                <transportation-expense-form />
                <transportation-submit-buttons @submitEvent="closeDialog" @updateEvent="closeDialog" @deleteEvent="closeDialog"/>
            </v-card>
            <v-btn @click="dialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-dialog>
    </div>
</template>

<script>
import TransportationExpenseListTable from "../molecules/TransportationExpenseListTable.vue";
import TransportationExpenseForm from "./TransportationExpenseForm.vue";
import TransportationSubmitButtons from './TransportationSubmitButtons.vue';
export default {
    components: { TransportationExpenseListTable, TransportationExpenseForm, TransportationSubmitButtons },
    name: "TransportationExpense",
    data: () => ({
        header: [],
        namespace: "TransportationExpense",
        dialog: false,
    }),
    computed: {
        employee_no: {
            get: function() {
                return this.$store.state[this.namespace]['items']['employee_no'].value
            }
        },
        list: {
            get: function () {
                return this.$store.state[this.namespace].list;
            },
        },
    },
    watch: {
        employee_no() {
            this.getList()
        }
    },
    methods: {
        getList() {
            this.$store.dispatch("TransportationExpense/getList");
        },
        editItem(item) {
            this.dialog = true
            this.$store.dispatch("setItems", {
                namespace: this.namespace,
                data: item,
            });
        },
        deleteItem(item) {
            this.dialog = true
            this.$store.dispatch("setItems", {
                namespace: this.namespace,
                data: item,
            });
        },
        closeDialog() {
            this.dialog = false
        }
    },
};
</script>