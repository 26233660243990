<template>
  <v-app>
      <v-sheet fluid>
        <v-toolbar color="yellow">
          <v-toolbar-title>CLUB</v-toolbar-title>
        </v-toolbar>
        <v-row v-masonry>
          <v-col v-for="(item, index) in items" :key="index" cols="12" sm="6">
            <v-card height="100%" color="gray" class="pt-1 pb-1" @click.stop="dialog = true">
              <v-card-title>{{item.title}}</v-card-title>
              <v-img :src="item.img" @load="$redrawVueMasonry()"></v-img>
              <v-card-text>{{item.text}}</v-card-text>
            </v-card>
            <v-dialog v-model="dialog" scrollable max-width="600px">
        <v-card>
            <v-img :src="item.img" @load="$redrawVueMasonry()"></v-img>
            <v-divider></v-divider>
            <v-card-text>{{item.text}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat small @click="dialog = false">CLOSE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
          </v-col>
        </v-row>
      </v-sheet>
  </v-app>
</template>

<script>

export default {
  name: 'Club',
    data() {
    return {
      items: [
        {
          id: 1,
          title: "フットサル部",
          img: require('@/assets/image/sample.jpg'),
          text:
            "次回の活動日:5/29(Fri)"
        },
        {
          id: 2,
          title: "ゲーム部",
          img: require('@/assets/image/sample.jpg'),
          text: "次回の活動日:5/29(Fri)"
        },
        {
          id: 3,
          title: "バスケ部",
          img: require('@/assets/image/sample.jpg'),
          text: "次回の活動日:5/29(Fri)"
        },
        {
          id: 4,
          title: "超人部",
          img: require('@/assets/image/sample.jpg'),
          text: "次回の活動日:5/29(Fri)"
        }
      ],
      dialog: false
    };
  },
  mounted() {
    this.repaint();
  },
  watch: {
    items: function() {
      this.repaint();
    }
  },
  methods: {
    repaint() {
      setTimeout(() => this.$redrawVueMasonry(), 1500);
    }
  }
}
</script>