<template>
    <submit-button-with-snackbar
        :submitButton="id.value == null"
        :updateButton="id.value != null"
        :deleteButton="id.value != null"
        :snackbar.sync="snackbar"
        @snackbar="snackbarUpdate"
        @submitEvent="submitEvent()"
        @updateEvent="updateEvent()"
        @deleteEvent="deleteEvent()"
    />
</template>
<script>
import SubmitButtonWithSnackbar from "../molecules/SubmitButtonWithSnackbar.vue";

export default {
    components: { SubmitButtonWithSnackbar },
    name: "TransportationExpense",
    data: () => ({
        namespace: "TransportationExpense",
    }),
    computed: {
        id: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["id"];
            },
        },
        snackbar: {
            get: function () {
                return this.$store.state[this.namespace]["snackbar"];
            },
		}
    },
    methods: {
        submitEvent: function () {
            this.$store.dispatch("TransportationExpense/submit");
            this.$emit('submitEvent')
        },
        updateEvent: function () {
            this.$store.dispatch("TransportationExpense/update");
            this.$emit('updateEvent')
        },
        deleteEvent: function () {
            this.$store.dispatch("TransportationExpense/delete");
            this.$emit('deleteEvent')
        },
        snackbarUpdate: function (data) {
            this.snackbar.isDisplay = data;
            this.snackbar.method = null;
        },
    },
};
</script>
