<template>
  <v-app>
      <v-card>
        <v-toolbar color="red" dark>
          <v-toolbar-title>ALBUM</v-toolbar-title>
        </v-toolbar>
        <div class="images-brick-layout">
          <brick-layout-photo :image_url="image_url" v-for="(image_url,index) in filteredimages" :key="index"></brick-layout-photo>
        </div>
      </v-card>
  </v-app>
</template>

<script>
import BrickLayoutPhoto from './BrickLayoutPhoto.vue'

export default {
  name: 'Album',
  components:{
    BrickLayoutPhoto
  },

  data: () => ({
    images: [
      // 画像のurl1
      require('@/assets/image/sample.jpg'),
      require('@/assets/image/hanabi.jpg'),
      require('@/assets/image/pasta.jpg'),
      require('@/assets/image/uchiage.jpg')
    ],
  }),

  computed: {
  filteredimages() {
    return this.images.filter(image => image != null)
  }
}
}
</script>

<style lang="scss">
.images-brick-layout{
  display: flex;
  flex-wrap: wrap;
  &:after{
    content: '';
    flex-grow: 999999999;
  }
  .images-brick-item{
    cursor: pointer;
    margin: 2px;
    position: relative;
  }
  i{
    display: block;
  }
  img {
    top: 0;
    width: 100%;
    vertical-align: bottom;
  }
}
</style>