<template>
  <div class="images-brick-item" :style="{ width: width, flexGrow: flexGrow }" v-if="image_url">
    <i :style="{ paddingBottom: paddingBottom}"></i>
    <img v-lazy="image_url" alt="" class="brick-item-image">
  </div>
</template>

<script>
export default {
  name: 'BrickLayoutPhoto',
  props:{
    image_url: {
        type     : String,
        require  : true,
        'default': () => ({ count: 0 }) // Objectを生成する関数を指定する
    }
  },

  data: () => ({
    width: '',
    height: 280,
    imageWidth: 200,
    imageHeight: 200,
    flexGrow: 0,
    paddingBottom: 0,
  }),

  created() {
    if (this.image_url) {
      this.setDivWidth(this.image_url)
      this.setDivFlexGrow(this.image_url)
      this.setIPaddingBottom(this.image_url)
    }
  },

  methods:{
    async setDivWidth(image_url) {
      const res = await this.loadImage(image_url)
      this.width = (res.width*this.height/res.height) + 'px'
    },

    async setDivFlexGrow(image_url) {
      const res = await this.loadImage(image_url)
      this.flexGrow = res.width*this.height/res.height
    },

    async setIPaddingBottom(image_url) {
      const res = await this.loadImage(image_url)
      this.paddingBottom = res.height/res.width*100
    },

    loadImage(src) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (e) => reject(e);
        img.src = src;
      });
    },
  }
}
</script>