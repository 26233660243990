// ドキュメントアップロート
export function documentUpload(repository, fileData) {
    return new Promise(resolve => {
        const payload = this.$axios
            .post(`/api/${repository}/`, fileData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            })
            .then(response => {
                return { payload: response };
            })
            .catch(error => {
                return { error };
            });
        resolve(payload);
    });
}

// ドキュメント一覧
export function getDocumentList(repository) {
    return new Promise(resolve => {
        const payload = this.$axios
            .get(`/api/${repository}/`, {
                headers: {
                    'content-type': 'application/json',
                },
            })
            .then(response => {
                return { payload: response };
            })
            .catch(error => {
                return { error };
            });
        resolve(payload);
    });
}

// ファイル名、カテゴリ更新
export function editDocument(repository, id, fileData) {
    return new Promise(resolve => {
        const payload = this.$axios
            .patch(`/api/${repository}/${id}/`, fileData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            })
            .then(response => {
                return { payload: response };
            })
            .catch(error => {
                return { error };
            });
        resolve(payload);
    });
}

// ドキュメント削除(DBのfile_status更新)
export function deleteDocument(repository, id, fileData) {
    return new Promise(resolve => {
        const payload = this.$axios
            .patch(`/api/${repository}/${id}/`, fileData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            })
            .then(response => {
                return { payload: response };
            })
            .catch(error => {
                return { error };
            });
        resolve(payload);
    });
}

// ダウントード
export function downloadDocument(repository) {
    return new Promise(resolve => {
        const payload = this.$axios
            .get(`/api/${repository}`, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
            })
            .then(response => {
                return { payload: response };
            })
            .catch(error => {
                return { error };
            });
        resolve(payload);
    });
}