<!-- eslint-disable -->
<template>
    <v-data-table :items="list" :headers="header" :disable-sort="disable_sort" @click:row="clickRow">
        <template v-slot:item.alert="{ item }">
            <v-tooltip  v-if="!item.approval_status && (item.admin_comments != null && item.admin_comments != '') " bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="red" v-bind="attrs" v-on="on"> mdi-comment-alert </v-icon>
                </template>
                <span>[管理者からのコメント]<br> {{ item.admin_comments}}</span>
            </v-tooltip>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-btn outlined @click="editItem(item)"> {{ buttonLabel }} </v-btn>
        </template>
    </v-data-table>
</template>
<script>
export default {
    props: {
        header: Array,
        list: Array,
        disable_sort: Boolean,
        button_label: String,
    },
    computed: {
        buttonLabel: {
            get: function() {
                return this.button_label != null ? this.button_label : "更新/削除"
            }
        }
    },
    methods: {
        clickRow: function (data) {
            this.$emit("clickRow", data);
        },
        editItem: function (item) {
            this.$emit("editItem", item);
        },
    },
};
</script>
