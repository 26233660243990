<template>
    <div v-if="employee_no != null">
        <transportation-expense-list-table
            button_label="却下コメントを書く"
            :list.sync="list"
            @editItem="editItem"
        />
        <v-dialog
            v-model="dialog"
            fullscreen
            transition="dialog-bottom-transition"
        >
            <v-card width="70%">
                <transportation-expense-admin-form :prop_employee_no.sync="this.employee_no"/>
                <transportation-admin-submit-buttons @updateEvent="updateEvent"/>
            </v-card>
            <v-btn @click="dialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-dialog>
    </div>
</template>

<script>
import TransportationExpenseListTable from "../molecules/TransportationExpenseListTable.vue";
import TransportationExpenseAdminForm from "./TransportationExpenseAdminForm.vue";
import TransportationAdminSubmitButtons from './TransportationAdminSubmitButtons.vue';
export default {
    components: { TransportationExpenseListTable, TransportationExpenseAdminForm, TransportationAdminSubmitButtons },
    name: "TransportationExpenseAdmin",
    data: () => ({
        header: [],
        namespace: "TransportationExpense",
        dialog: false,
    }),
    computed: {
        list: {
            get: function () {
                return this.$store.state[this.namespace].list;
            },
        },
        employee_no: {
            get: function() {
                return this.$store.state[this.namespace]['items']['employee_no'].value;
            }
        },
    },
    watch: {
        employee_no: function() {
            this.getList()
        },
    },
    methods: {
        getList() {
            this.$store.dispatch("TransportationExpense/getList");
        },
        editItem(item) {
            this.dialog = true
            this.$store.dispatch("setItems", { namespace: "TransportationExpense", data: item, });
        },
        updateEvent() {
            this.dialog = false
        },
    },
};
</script>