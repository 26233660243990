<template>
  <div id="app">
    <nav
      class="
        navbar navbar-light navbar-expand-lg
        header__background
        fixed-top
        header__position
      "
      id="header__position"
    >
      <a href="/">
        <img
          src="@/assets/image/miiits_logo_m.svg"
          class="header__logo"
          id="header__logo"
        />
        <img
          src="@/assets/image/miiits_logo_m_orange.svg"
          class="header__logo-SP"
          id="header__logo"
        />
      </a>
      <!-- menu bar -->
      <div
        class="justify-content-end collapse navbar-collapse justify-content-end"
      >
        <ul class="navbar-nav ml-auto">
          <li>
            <router-link to="/about" class="header__link">ABOUT</router-link>
          </li>
          <li>
            <router-link to="/articleList" class="header__link"
              >ARTICLE</router-link
            >
          </li>
          <li>
            <router-link to="/memberList" class="header__link"
              >MEMBER</router-link
            >
          </li>
        </ul>
        <form class="d-flex mx-5 searchform font-ryo-gothic">
          <!-- enterキーで画面リロードしないため、追加する Begin -->
          <input id="hiddenText" type="text" style="display: none" />
          <!-- enterキーで画面リロードしないため、追加する End -->
          <input
            class="form-control me-2 searchform__input"
            type="search"
            placeholder="気になる記事を検索"
            aria-label="Search"
            maxlength="30"
            v-model="textKeyword"
            @keydown="checkNull($event)"
          />
          <button
            type="button"
            @click="setSearchKeyword()"
            v-bind:style="{ cursor: selectedCursor }"
          >
            <img src="@/assets/image/search.png" alr="search" />
          </button>
        </form>
      </div>

      <!-- ハンバーガーメニュー -->
      <button
        type="button"
        class="menusp__btn"
        v-on:click="open = !open"
        v-bind:class="{ 'is-active': open }"
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div class="menusp" v-bind:class="{ 'is-active': open }">
        <!-- ロゴ（TOPへのリンク） -->
        <router-link to="/" class="header__link">
          <img class="spmenu__logo" src="@/assets/image/miiitslogo_beige.svg" />
        </router-link>
        <!-- メニュー -->
        <ul class="navbar-nav mx-auto">
          <li class="mb-5">
            <router-link to="/about" class="header__link">ABOUT</router-link>
          </li>
          <li class="mb-5">
            <router-link to="/articleList" class="header__link"
              >ARTICLE</router-link
            >
          </li>
          <li class="mb-5">
            <router-link to="/memberList" class="header__link"
              >MEMBER</router-link
            >
          </li>
        </ul>
        <form class="d-flex mx-5 searchform font-ryo-gothic mt-5">
          <!-- enterキーで画面リロードしないため、追加する Begin -->
          <input id="hiddenText" type="text" style="display: none" />
          <!-- enterキーで画面リロードしないため、追加する End -->
          <input
            class="form-control me-2 searchform__input"
            type="search"
            placeholder="気になる記事を検索"
            aria-label="Search"
            maxlength="30"
            v-model="textKeyword"
            @keydown="checkNull($event)"
          />
          <button
            type="button"
            @click="setSearchKeyword()"
            v-bind:style="{ cursor: selectedCursor }"
          >
            <img src="@/assets/image/search.png" alr="search" />
          </button>
        </form>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      open: false,
      textKeyword: "",
    };
  },
  props: {},
  computed: {
    // キーワードが空白の際に、禁用マークを表示
    selectedCursor() {
      if (this.textKeyword == "") {
        return "not-allowed";
      } else {
        return "pointer";
      }
    },
  },
  methods: {
    // Enterキーの制御
    checkNull(event) {
      if (event.keyCode === 13 && this.textKeyword == "") {
        event.returnValue = false;
      } else if (event.keyCode === 13 && this.textKeyword != "") {
        this.setSearchKeyword();
      }
    },
    // キーワードを検索機能に渡す
    setSearchKeyword() {
      if (this.textKeyword != "") {
        this.$store.dispatch("miiitsStore/setTextKeyword", this.textKeyword);
        if (
          this.textKeyword != "" &&
          this.$route.path === "/articleSearchResult"
        ) {
          window.scroll({ top: 0, behavior: "auto" });
          if (
            window.matchMedia &&
            window.matchMedia("(max-device-width: 992px)").matches
          ) {
            this.open = !this.open;
          }
          // }
        } else {
          this.$router.push({ path: "/articleSearchResult" });
        }
      }
    },

    getPathName() {
      var header__position = document.querySelector("#header__position");
      var pathName = this.$route.path;
      if (pathName != "/") {
        header__position.style.opacity = "1";
      } else if (pathName == "/") {
        window.addEventListener("scroll", this.handleScroll);
      }
    },

    // スクロール値を取得
    handleScroll() {
      var pathName = this.$route.path;
      var screenWidth = screen.width;
      var header__position = document.querySelector("#header__position");
      var header__logo = document.querySelector("#header__logo");
      if (screenWidth > 1100) {
        if (document.documentElement.scrollTop > 50) {
          header__position.style.transitionDelay = "500ms";
          header__position.style.opacity = "1";
          header__position.style.transition = "0.5s";
          header__logo.style.opacity = "1";
        } else if (document.documentElement.scrollTop != 0) {
          if (pathName != "/") {
            header__position.style.opacity = "1";
          } else {
            // 画面トップにもっとたら、ロゴを初期設定に戻る
            header__position.style.opacity = "0";
            header__position.style.transition = "0.5s";
          }
        }
      } else {
        if (document.documentElement.scrollTop > 0) {
          header__position.style.transitionDelay = "500ms";
          header__position.style.opacity = "1";
          header__position.style.transition = "0.5s";
          header__logo.style.transitionDelay = "500ms";
          header__logo.style.opacity = "1";
          header__logo.style.transition = "0.5s";
        } else if (document.documentElement.scrollTop == 0) {
          if (pathName != "/") {
            header__position.style.opacity = "1";
          } else {
            // 画面トップにもっとたら、ロゴを初期設定に戻る
            header__logo.style.opacity = "0";
            header__logo.style.transition = "0.5s";
          }
        }
      }
    },
  },
  mounted() {
    this.getPathName();
  },
};
</script>

<!-- 共通CSS -->

<!-- ローカルCSS -->
<style scoped>
/* ヘッダー背景 */
.header__background {
  background-color: #db4627;
}

@media (max-width: 992px) {
  .header__background {
    background-color: transparent;
  }
}

/* リンク */
.header__link {
  font-size: 22px;
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-family: ryo-gothic-plusn, sans-serif;
  font-style: normal;
  margin-right: 8rem;
}
@media (max-width: 992px) {
  .header__link {
    margin-right: 0;
    margin-bottom: 3rem;
  }
}

/* PCヘッダーロゴ */
@media (min-width: 1200px) {
  .header__logo {
    display: block;
    width: 50px;
    margin: 0.5rem 0 0 1.3rem;
  }
  .header__logo-SP {
    display: none;
  }
}
.header__position {
  opacity: 0;
}

/* 検索バーエリア */
.searchform {
  position: relative;
  min-width: 200px;
}

input::placeholder {
  color: #d6d6d6;
}
/* 検索ボタン */
.searchform button {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
}
.searchform img {
  width: 50%;
}

/* 検索バー入力エリア */
.searchform__input {
  padding-right: 50px;
  border-radius: 20px;
}
/* SP文字サイズ変更 */
@media (max-width: 992px) {
  .searchform__input {
    font-size: 14px;
  }
}

/* ハンバーガーメニュー*/
/* SPヘッダーロゴ色修正 */
@media (max-width: 1199px) {
  .header__logo {
    display: none;
  }
  .header__logo-SP {
    display: block;
    width: 50px;
    margin: 0.5rem 0 0 1.3rem;
  }
}
/* SPロゴ */
.spmenu__logo {
  width: 200px;
  height: auto;
  margin-bottom: 2rem;
}

/* SPハンバーガーボタン */
@media (max-width: 992px) {
  .menusp__btn {
    display: block;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 3;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #fff;
    border-radius: 20px;
    border: solid 1px #db4627;
  }
}

@media (max-width: 992px) {
  /* ハンバーガーの線→☓ */
  .menusp__btn span {
    display: block;
    margin: 0;
    border: none;
    width: 50%;
    height: 2px;
    background: #db4627;
    transform-origin: 0% 50%;
    position: absolute;
    top: 12px;
    left: 25%;
    transition: 0.3s;
  }

  .menusp__btn span:nth-of-type(2) {
    top: 18px;
  }
  .menusp__btn span:nth-of-type(3) {
    top: 25px;
  }

  .menusp__btn.is-active span:nth-of-type(1) {
    top: 10px;
    transform: rotate(45deg);
    width: 70%;
  }
  .menusp__btn.is-active span:nth-of-type(2) {
    opacity: 0;
  }
  .menusp__btn.is-active span:nth-of-type(3) {
    top: 28px;
    transform: rotate(-45deg);
    width: 70%;
  }
}

/* メニュー本体 */
.menusp {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #de614c;
}
.menu__item {
  width: 100%;
  height: auto;
  padding: 0.5em 1em;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
}
/*----------------------------
* アニメーション部分
*----------------------------*/

/* アニメーション前のメニューの状態 */
.menusp {
  transform: translateX(100vw);
  transition: all 0.3s linear;
  text-align: center;
}

.menusp ul {
  padding: 0;
  margin: 0;
}
/* アニメーション後のメニューの状態 */
.menusp.is-active {
  transform: translateX(0);
}

/* img disable */
.img-onlyRead {
  pointer-events: none;
}
</style>