// initial state
const state = () => ({
    list: [],
    snackbar: { isDisplay: false, method: null, },
    defaults: {
        id: { value: null, error: false, errorMsg: null },
        employee_no: { value: null, error: false, errorMsg: null },
        full_name: { value: null, error: false, errorMsg: null },
        date: { value: null, error: false, errorMsg: null },
        visit_target: { value: null, error: false, errorMsg: null },
        requirement: { value: null, error: false, errorMsg: null },
        price: { value: null, error: false, errorMsg: null },
        transportation_facilities: { value: null, error: false, errorMsg: null },
        route_from: { value: null, error: false, errorMsg: null },
        route_to: { value: null, error: false, errorMsg: null },
        admin_comments: { value: null, error: false, errorMsg: null },
        approval_status: { value: false, error: false, errorMsg: null },
    },
    items: {
        id: { value: null, error: false, errorMsg: null },
        employee_no: { value: null, error: false, errorMsg: null },
        full_name: { value: null, error: false, errorMsg: null },
        date: { value: null, error: false, errorMsg: null },
        visit_target: { value: null, error: false, errorMsg: null },
        requirement: { value: null, error: false, errorMsg: null },
        price: { value: null, error: false, errorMsg: null },
        transportation_facilities: { value: null, error: false, errorMsg: null },
        route_from: { value: null, error: false, errorMsg: null },
        route_to: { value: null, error: false, errorMsg: null },
        admin_comments: { value: null, error: false, errorMsg: null },
        approval_status: { value: false, error: false, errorMsg: null },
    },
})

// getters
const getters = {}

// actions
const actions = {
    submit ({ state, dispatch, commit }) {
        const thenMethod = function () {
            commit('resetItem')
            state['snackbar'] = { isDisplay: true, method: "create" }
            dispatch('getList')
        }

        dispatch('postAction', {
            apiName: 'api/transeport-expences/'  + state.items['employee_no'].value + '/',
            namespace: 'TransportationExpense',
            thenAction: thenMethod
        }, {
            root: true
        })
    },
    update({ state, commit, dispatch }) {
        const thenMethod = function () {
            commit('resetItem')
            state['snackbar'] = { isDisplay: true, method: "update" }
            dispatch('getList')
        }

        const apiName = 'api/transeport-expence/' + state.items["id"].value + "/"
        dispatch('putAction', { apiName: apiName, namespace: 'TransportationExpense', thenAction: thenMethod }, { root: true })
    },
    delete({ state, commit, dispatch }) {
        const thenMethod = function () {
            commit('resetItem')
            state['snackbar'] = { isDisplay: true, method: "delete" }
            dispatch('getList')
        }

        const apiName = 'api/transeport-expence/' + state.items["id"].value + "/"
        dispatch('deleteAction', { apiName: apiName, namespace: 'TransportationExpense', thenAction: thenMethod }, { root: true })
    },
    getList({ state, dispatch }) {
        dispatch('getList', { apiName: 'api/transeport-expences/' + state.items['employee_no'].value + '/', namespace: 'TransportationExpense' }, { root: true })
    },
    getEmployeeNo({ dispatch }) { dispatch('getEmployeeInfo', { apiName: 'api/employee_no/', namespace: 'TransportationExpense' }, { root: true })
    },
}

// mutations
const mutations = {
    resetItem(state) {
        state.defaults.employee_no = state.items.employee_no
        state.defaults.full_name = state.items.full_name
        state.items = JSON.parse(JSON.stringify(state.defaults))
    },
    setEmployeeNo(state, {employee_no}) {
        state.items.employee_no.value = employee_no
    },
    setName (state, { full_name }) {
        state.items.full_name.value = full_name
    },
    setTotal (state, { total_price }) {
        state.items.price.value = total_price
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}