<template>
    <div class="article__link--box">
        <router-link :to="{ name: 'ArticleDetail', params: {id: id } }">
            <img class="article__link--img" :src="src" alt="記事サムネイル画像2">
        </router-link>
        <div class="row mt-2 px-5">
            <div class="col-8 pb-0 text-left">
                <p class="article__link--category mb-0">{{ category_name }}</p>
            </div>
            <div class="col-4 pb-0 text-right font-color--orange">
                <p class="mb-0">{{ add_datetime_y }}.{{ add_datetime_m }}.{{ add_datetime_d }}</p>
            </div>
        </div>
        <router-link :to="{ name: 'ArticleDetail', params: {id: id } }">
            <p class="article__link--title px-5 mb-0">{{ title }}</p>
        </router-link>
        <div class="row mt-1 px-5 pb-2">
            <div class="col-6 pb-5 pt-2 text-left">
                <p class="mb-0"><button @click="plus(id)">
                    <div v-if="btn_flg" class="goodbutton_stop">
                    <img :src="src_goodicon" width="17" height="15" />{{ like_count }}</div>
                    <div v-if="!btn_flg" class="goodbutton_anime">
                        <div class="heartsize">
                        <lottie
                            ref="anim"
                            :options="lottieOptions1"
                            v-on:animCreated="handleAnimation1"
                            class="likeshare_icon"
                        /></div>{{ like_count }}</div>
                    </button></p>
            </div>
            <div class="col-6 pb-5 pt-2 text-left">
                <p class="text-right mb-0">
                    <router-link :to="{ name: 'ArticleDetail', params: {id: id } }">
                        <a class="article__link--next">記事を読む&emsp;<i class="fa fa-angle-right"></i></a>
                    </router-link>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import * as LikeCountUpdateAPI from "@/apis/LikeCountUpdateAPI"
import * as ArticleDetailAPI from "@/apis/ArticleDetailAPI"
import Lottie from 'vue-lottie';
import animationData1 from '@/assets/image/goodicon_anime.json';
export default {
    components: {Lottie},
    data() {
        return {
            open :false,
            imgPath: process.env.VUE_APP_IMG_BASE,
            likecount2: "",
            lottieOptions1: {
                animationData: animationData1,
                loop: false,
            },
        }
    },
    props: {
        index: String,
        id: String,
        thumbnail_img_path: String,
        category_name: String,
        title: String,
        like_count: String,
        add_datetime_y: String,
        add_datetime_m: String,
        add_datetime_d: String,
        btn_flg: Boolean,
        src_goodicon: String,
    },
    computed: {
        src:function() {
            return  this.imgPath + this.thumbnail_img_path
        }
    },
    methods: {
        handleAnimation1: function (anim) {
            this.anim = anim;
        },

        plus(id){
            if(document.cookie.indexOf('article' + id + '=liked') == -1){
                LikeCountUpdateAPI.editLikeCount(id)
                .then( () =>{
                    document.cookie ='article' + id + '=liked';
                    ArticleDetailAPI.getArticleDetail(id)
                    .then(response => {
                        this.likecount2 = response.payload.data.like_count;
                        this.$emit("likeoneplus",this.likecount2,this.index);
                    }).catch(error => {
                        console.log("Error : " + error.response);
                    });    
                })
                .catch(error => {
                    console.log("Error : " + error.response);
                });
                
            }
        },
    },

}
</script>

<!-- 共通CSS -->
<style src="../pages/miiits/css/common.css"></style>

<!-- ローカルCSS -->
<style scoped>
/* 全体 白背景*/
.container-fluid{
    /* margin-right:300px;
    margin-left:300px; */
    max-width: 1055px;
}
.memberlist_range{
    max-width:1033px;
    position:absolute;
}

/* プロフィール名前日本語 */
.member__introduction--nama-ja {
    color: #DB4627;
    font-size: 60px;
    margin-bottom: 0;
}
@media screen and (max-width: 526px) {
    .member__introduction--nama-ja {
        font-size: 40px;
    }
}

/* 自己紹介画像 */
.memberdetails_people_img{
    width:500px;
    height:300px;
    object-fit: cover;
    border-radius:20px;
    position: absolute;
    bottom:220px;
    right:570px;
}

/* 自己紹介文の枠*/
.member_introduction{
    background-color:#FCF8F2;
    border-radius: 30px;
    padding: 20px;
    margin-top: 100px;
    margin-bottom:20px;
}
/* 自己紹介文 */
.member_introduction_text{
    text-align:left;
    margin-bottom:0;
}
/* 基本情報紹介 */
.member_introductionabout{
    margin-top:30px;
}

/* メンバー一覧へボタン */
.button {
    color:#DB4627;
    font-size: 20px;
    font-weight: 900;
    background: #fff;
    padding: 18px 30px;
    border: 3px solid #DB4627;
    box-shadow: 5px 5px 0 #DB4627;
    border-radius: 80px;
    width:150px;
    height:150px;
    display: inline-block;
    transition: .3s;
    text-align: center;
    text-decoration: none;
}
.button:hover {
    transform: translateY(5px);
    box-shadow: 0 0 0 #DB4627;
}

.kijiall_yajirushi{
    padding-top:10px;
    width:60%;

}
/* ================================ * /
        PICKUP、関連記事
==================================== */
/* 見出しに本線アイコン */
.kanrenpickup_titleicon{
    border-left: double 10px #DB4627;
    padding-right:100px;
    margin-top:50px;
}

/* タブレット */
@media (max-width: 820px) {
/* 自己紹介 */
/* 自己紹介画像 */
.memberdetails_people_img{
    width:300px;
    height:300px;
    object-fit: cover;
    border-radius:50px;
    position: absolute;
    bottom:220px;
    right:350px;

}
/* 基本情報 */
.member_introductionabout{
    margin-top:100px;
}



/* 全体 白背景*/
.container-fluid{
    max-width: 700px;
}
/* .memberlist_range{
    max-width:503px;
    position:absolute;
} */

}

.goodbutton_stop{
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1.5px;
  margin-left: 1px;
}

.goodbutton_anime {
  display: flex;
  align-items: flex-end;
}

.heartsize{
  width: 24.873px;
  height: 25.869px;
}

/* SP */
@media screen and (max-width: 526px) {

.container-fluid{
    max-width: 300px;
}
/* 自己紹介 */
.member_introduction{
    margin-top:0px;
    background-color:#FCF8F2;
    border-radius: 30px;
    padding: 25px 10px 25px 10px;
}
.member_introduction_text{
    text-align:center;
    font-size:13px;

}

.member_introductionabout{
    margin-top:130px;
    margin-bottom:20px;
}

/* 自己紹介文の枠*/
.member_introduction{
    margin-top:50px;
    background-color:#FCF8F2;
    border-radius: 30px;
    padding: 20px;
}
/* 自己紹介文 */
.member_introduction_text{
    text-align:left;
    margin-bottom:0;
}
/* 基本情報紹介 */
.member_introductionabout{
    /* margin-top:30px; */
}
/* 自己紹介画像 */
.memberdetails_people_img{
    width:200px;
    height:200px;
    object-fit: cover;
    border-radius:200px;
    position: absolute;
    bottom:500px;
    left:50px;
}

/* 自己紹介文の枠*/
.member_introduction{
    background-color:#FCF8F2;
    border-radius: 30px;
    padding: 20px;
}
/* 自己紹介文 */
.member_introduction_text{
    text-align:left;
    margin-bottom:0;
}

/* メンバー一覧ボタン */
    .button {
    color:#DB4627;
    font-size: 11px;
    font-weight: 800;
    background: #fff;
    padding: 18px 20px;
    border: 3px solid #DB4627;
    box-shadow: 5px 3px 0 #DB4627;
    border-radius: 80px;
    width:90px;
    height:90px;
    display: inline-block;
    transition: .3s;
    text-align: center;
    text-decoration: none;
    }
    .button:hover {
    transform: translateY(5px);
    box-shadow: 0 0 0 #DB4627;
    }

    .kijiall_yajirushi{
    padding-top:5px;
    width:60%;
    }

}

</style>