import axios from "axios";
 
//記事一覧画面、記事一覧呼び出しAPI
export function getManyAccessArticleList() {
    return new Promise(resolve => {
        const payload = axios
            .get(`/miiits/ManyAccessArticleList/`, {
                headers: {
                    'content-type': 'application/json',
                },
            })
            .then(response => {
                return { payload: response };
            })
            .catch(error => {
                return { error };
            });
        resolve(payload);
    });
}