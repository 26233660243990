<template>
    <v-app>
        <v-card class="mx-auto mt-10" min-width="344">
            <v-card-text class="mt-10">
                <p class="text-h4 text--primary">LoginTest</p>
                <p>こんにちは {{ this.firstname }} {{ this.lastname }}</p>
            </v-card-text>
            <v-card-actions align="center" class="mt-10">
                <v-btn rounded outlined @click="logout()"> ログアウト </v-btn>
            </v-card-actions>
        </v-card>
    </v-app>
</template>

<script>
import axios from 'axios';
import router from '../../router';

export default {
    name: 'LoginTest',
    data: () => ({
        username: "",
        firstname: "",
        lastname: "",
        errorMsg: "",
    }),
    mounted: function() {
        this.loginTest(JSON.parse(sessionStorage.getItem('vue-session-key')).token)
    },
    methods: {
        loginTest: function(token) {
            axios.get("http://localhost:8000/api/getUserId/", {data:{}, headers: { 'Content-Type': 'application/json', 'Authorization': 'JWT ' + token + '' }}).then(res => {
                this.username = res.data.username
                this.firstname = res.data.firstname
                this.lastname = res.data.lastname
            }).catch(e => {
                this.errorMsg = e
            })
        },
        logout: function() {
            this.$session.destroy()
            router.push('/login')
        }
    }
}
</script>