<template>
    <v-container>
        <v-alert
        border="bottom"
        color="pink darken-1"
        v-if="admin_comments.value != null && admin_comments.value != ''"
        dark
        >
            <div class="text-h6">
                管理者からのコメント
            </div>
            <div class="ml-4">
                {{ admin_comments.value }}
            </div>
        </v-alert>
        <form-label-text
            label="訪問先"
            placeholder="例）Mist Solution"
            v-bind:value.sync="visit_target.value"
            :error.sync="requirement.error"
            :errorMsg.sync="requirement.errorMsg"
        />
        <form-label-text
            label="要件"
            placeholder="例）帰社のため"
            v-bind:value.sync="requirement.value"
            :error.sync="requirement.error"
            :errorMsg.sync="requirement.errorMsg"
        />
        <form-datepicker
            label="日付"
            v-bind:value.sync="date.value"
            :error.sync="date.error"
            :errorMsg.sync="date.errorMsg"
        />
        <form-label-text
            class="mt-5"
            label="金額"
            placeholder="例）1000"
            v-bind:value.sync="price.value"
            :error.sync="price.error"
            :errorMsg.sync="price.errorMsg"
        />
        <form-label-text
            label="交通機関"
            placeholder="例）JR"
            v-bind:value.sync="transportation_facilities.value"
            :error.sync="transportation_facilities.error"
            :errorMsg.sync="transportation_facilities.errorMsg"
        />
        <form-label-text
            label="経路（From）"
            placeholder="例）東京"
            v-bind:value.sync="route_from.value"
            :error.sync="route_from.error"
            :errorMsg.sync="route_from.errorMsg"
        />
        <form-label-text
            label="経路（To）"
            placeholder="例）神田"
            v-bind:value.sync="route_to.value"
            :error.sync="route_to.error"
            :errorMsg.sync="route_to.errorMsg"
        />
    </v-container>
</template>

<script>
import FormDatepicker from "../molecules/FormDatepicker.vue";
import FormLabelText from "../molecules/FormLabelText.vue";
export default {
    components: { FormLabelText, FormDatepicker },
    name: "TransportationExpense",
    props: {
        prop_employee_no: [Number, String],
    },
    data: () => ({
        namespace: "TransportationExpense",
    }),
    computed: {
        id: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["id"];
            },
        },
        list: {
            get: function () {
                return this.$store.state[this.namespace]["list"];
            },
        },
        snackbar: {
            get: function () {
                return this.$store.state[this.namespace]["snackbar"];
            },
        },
        employee_no: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["employee_no"];
            },
            set: function (value) {
                this.$store.dispatch("setItem", {
                    namespace: this.namespace,
                    key: "employee_no",
                    value: value,
                });
            },
        },
        date: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["date"];
            },
            set: function (value) {
                this.$store.dispatch("setItem", {
                    namespace: this.namespace,
                    key: "date",
                    value: value,
                });
            },
        },
        visit_target: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["visit_target"];
            },
            set: function (value) {
                this.$store.dispatch("setItem", {
                    namespace: this.namespace,
                    key: "visit_target",
                    value: value,
                });
            },
        },
        requirement: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["requirement"];
            },
            set: function (value) {
                this.$store.dispatch("setItem", {
                    namespace: this.namespace,
                    key: "requirement",
                    value: value,
                });
            },
        },
        price: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["price"];
            },
            set: function (value) {
                this.$store.dispatch("setItem", {
                    namespace: this.namespace,
                    key: "price",
                    value: value,
                });
            },
        },
        transportation_facilities: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["transportation_facilities"];
            },
            set: function (value) {
                this.$store.dispatch("setItem", {
                    namespace: this.namespace,
                    key: "transportation_facilities",
                    value: value,
                });
            },
        },
        route_from: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["route_from"];
            },
            set: function (value) {
                this.$store.dispatch("setItem", {
                    namespace: this.namespace,
                    key: "route_from",
                    value: value,
                });
            },
        },
        route_to: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["route_to"];
            },
            set: function (value) {
                this.$store.dispatch("setItem", {
                    namespace: this.namespace,
                    key: "route_to",
                    value: value,
                });
            },
        },
        admin_comments: {
            get: function() {
                return this.$store.state[this.namespace]["items"]["admin_comments"];
            }
        }
    },
    mounted: function () {
        if (this.prop_employee_no == null) {
            this.getEmployeeNo()
        } else {
            this.employee_no.value = this.prop_employee_no
        }

    },
    watch: {
        prop_employee_no() {
            this.employee_no.value = this.prop_employee_no
        }
    },
    methods: {
        getEmployeeNo() {
            this.$store.dispatch("TransportationExpense/getEmployeeNo");
        },
    },
};
</script>