<template>
    <v-date-picker
        v-model="date"
        outlined
        locale="jp-ja"
        elevation="2"
        full-width
        no-title
        :day-format="(date) => new Date(date).getDate()"
    />
</template>
<script>
export default {
    props: {
        value: String,
    },
    computed: {
        date: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit("update:value", value);
            },
        },
    },
};
</script>
