<template>
    <v-app>
    <Header />
    <div class="body__wrap background__beige">
        <!-- 記事一覧 -->
        <section>
            <div class="concontainer-fluid">
                <div class="row region__control">
                    <div class="memberlist_range col-md-8 offset-md-2 col-sm-10 offset-sm-1 col-10 offset-1 stify-content-center text-center background__white p-md-5 p-3">
                        <div class="article__area mx-auto">

                            <!-- パンくずリスト -->
                            <div class="row">
                                <div class="col-md-12">
                                    <ol class="breadcrumb" itemscope itemtype="https://schema.org/BreadcrumbList">
                                        <li itemprop="itemListElement" itemscope
                                            itemtype="https://schema.org/ListItem">
                                            <a itemprop="item" href="/articleList">
                                                <span itemprop="name">記事一覧</span>
                                            </a>
                                            <meta itemprop="position" content="1" />
                                        </li>

                                        <li itemprop="itemListElement" itemscope
                                            itemtype="https://schema.org/ListItem">
                                            <a itemprop="item" v-bind:href="'/articleDetail' + id">
                                                <span itemprop="name">{{ title }}</span>
                                            </a>
                                            <meta itemprop="position" content="2" />
                                        </li>
                                    </ol>
                                </div>
                            </div>

                            <div class="row">
                                <!-- カテゴリ名 -->
                                <div class="col-6 text-left">
                                    <p class="aiticle_category">{{ category_id.category_name }}</p>
                                </div>
                                <!-- 日付 -->
                                <div class="col-6">
                                    <p class="date">{{ add_datetime.slice(0,4) }}.{{ add_datetime.slice(5,7) }}.{{ add_datetime.slice(8,10) }}</p>
                                </div>
                            </div>
                            <!-- 記事部分 -->
                            <div class="row">
                                <!-- タイトル -->
                                <div class="col-12">
                                    <p class="titlename text-left font-ryo-gothic font-weight--800 font-size--34 font-color--black">
                                        {{ title }}
                                    </p>
                                </div>
                                <!-- 記事本文 -->
                                <p class="article__txt" v-html=appendImgPath(content)></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- 関連サービス -->
        <section>
            <div v-if="related_service_id">
                <div v-if="related_service_id.name">
                    <div class="row">
                        <div class="col-md-10 offset-md-1 col-sm-10 offset-sm-1 col-12">
                            <div class="row">
                                <div class="col-md-2 offset-md-3 col-sm-1 offset-sm-1 col-2 offset-1">
                                    <p class="kanren_tab font-fot-rodin">関連サービス</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-7 col-md-8 col-sm-9 col-9 text-center mx-auto background__white">
                                    <div class="kanren_range">
                                        <div class="row">
                                            <div class="col-md-5 my-auto">
                                                <img class="service_logo_img" :src="src" alt="DISCaVa_logo">
                                            </div>
                                            <div class="col-md-7">
                                                <div class="kanren_textall">
                                                    <p class="kanren_title1 font-fot-rodin font-color--black">{{ related_service_id.catch_copy }}</p>
                                                    <p class="kanren_title2 font-ryo-gothic font-color--black font-weight--800  font-size--20">{{ related_service_id.name }}</p>
                                                    <p
                                                        class="kanren_text1 font-ryo-gothic"
                                                        v-html= related_service_id.description
                                                    >
                                                    </p>
                                                    <a class="font-fot-rodin font-color--black kanren_link" target="_blank" rel="noopener noreferrer" v-bind:href="related_service_id.site_url">WEBサイトはこちら<i class="fas fa-external-link-alt ml-2"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="body__gap--control"></div>
        <!-- この記事を書いたMiiitsメンバー -->
        <section>
            <div class="concontainer-fluid  text-center">
                <div class="row">
                    <div class="col-md-10 offset-md-1 col-sm-10 offset-sm-1 col-12">
                        <div class="row">
                            <div class="col-lg-7 col-md-8 col-sm-9 col-10 mx-auto p-4 background__white">
                                <div class="member_box1">
                                    <img class="writtingmember_img" v-bind:src="'/image/' + this.member.profile_img_maru_path" alt="TOP1">
                                    <div class="member_box2">
                                        <p class="member_texttitle font-ryo-gothic font-color--orange font-weight--600  font-size--16">この記事を書いたMiiitsメンバー</p>
                                        <p class="member_textname font-ryo-gothic font-color--black font-weight--600  font-size--16">{{this.member.pen_name}}</p>
                                    </div>
                                </div>
                                <div class="member_box3">
                                    <p class="member_text1 font-ryo-gothic font-color--black font-weight--400  font-size--16"
                                        v-html=this.member.self_introduct_stat
                                    ></p>
                                    <router-link :to="{ name: 'MemberDetail', params: {id: member.id } }" style="text-decoration:none;"> 
                                        <ul class="writingmember">
                                            <li>
                                                <p class="member_text2 font-ryo-gothic font-color--orange font-weight--600  font-size--16">{{this.member.pen_name}}の記事を見る</p>
                                            </li>
                                            <li>
                                                <img class="yajirushi_icon" src="@/assets/image/yajirushi.png" alt="yajirushi">
                                            </li>
                                        </ul>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- like,shareボタン -->
        <section>
            <div class="concontainer-fluid  text-center">
                <div class="row  font-color--black font-weight--600 font-ryo-gothic">
                    <div class="col-md-3 offset-md-3 col-sm-3 offset-sm-3">
                        <p class="likeshare">LIKE!</p>
                        <div style="margin-left:auto; margin-right:auto;">
                            <div class="LIKE_all">
                                <div>
                                    <button @click="plus(id)">
                                        <div v-if="button_good" class="biglike">
                                        <img class="likeshare_icon" :src="src2" alt="like_icon"></div>
                                        <div v-if="!button_good" class="goodbutton_anime">
                                        <lottie
                                            ref="anim"
                                            :options="lottieOptions1"
                                            v-on:animCreated="handleAnimation1"
                                            class="likeshare_icon"
                                        /></div>
                                    </button>
                                </div>
                                <div>
                                    <p class="like_number">{{ like_count }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3 col-sm-3">
                        <p class="likeshare">SHARE!</p>
                            <div style="margin-left:auto; margin-right:auto;">
                                <div class="SHARE_all">
                                    <!-- LINE -->
                                    <div>
                                        <a :href="`https://social-plugins.line.me/lineit/share?url=${baseUrl}articleDetail${$route.params.id}&text=${title}`"
                                            target="_blank" rel="nofollow noopener noreferrer">
                                            <img class="likeshare_icon" src="@/assets/image/line_share.svg" alt="line_icon">
                                        </a>
                                    </div>
                                    <!-- facebook -->
                                    <div>
                                        <a :href="`http://www.facebook.com/share.php?u=${baseUrl}articleDetail${$route.params.id}&text=${title}`"
                                            target="_blank" rel="nofollow noopener noreferrer">
                                            <img class="likeshare_icon" src="@/assets/image/facebook_share.svg" alt="facebook_icon">
                                        </a>
                                    </div>
                                    <!-- twitter -->
                                    <div>
                                        <a :href="`https://twitter.com/intent/tweet?url=${baseUrl}articleDetail${$route.params.id}%0a&text=${title}`"
                                            target="_blank" rel="noopener noreferrer">
                                            <img class="likeshare_icon" src="@/assets/image/twitter_share.svg" alt="twitter_icon">
                                        </a>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- 関連記事 -->
            <!-- <section>
                <div class="row region__control">
                    <div class="article__link--area"> -->
                        <!-- 関連記事head-->
                        <!-- <div class="article__linkhead">
                            <div class="col-12 text-left">
                                <div class="kanrenpickup_titleicon">
                                    <p class="font-ryo-gothic font-color--orange font-weight--900 font-size--20" style="margin-left:10px;">
                                        関連記事
                                    </p>
                                </div>
                            </div>
                        </div> -->
                        <!-- 記事リンク -->
                        <!-- <div class="article__link">
                            <div class="article__link--box">
                                <div v-if="ArticleDetailArticleList[0]">
                                    <a v-bind:href="'/articleDetail' + this.PickupArticleList[0].id">
                                        <img class="article__link--img" v-bind:src="'image/' + this.ArticleDetailArticleList[0].thumbnail_img_path.slice(7)" alt="記事サムネイル画像2">
                                    </a>
                                    <div class="row mt-2 px-5">
                                        <div class="col-8 pb-0 text-left">
                                            <p class="article__link--category mb-0">{{ this.ArticleDetailArticleList[0].category_id.category_name }}</p>
                                        </div>
                                        <div class="col-4 pb-0 text-right font-color--orange">
                                            <p class="mb-0">{{ this.ArticleDetailArticleList[0].add_datetime.slice(0,4) }}.{{ this.ArticleDetailArticleList[0].add_datetime.slice(5,7) }}.{{ this.ArticleDetailArticleList[0].add_datetime.slice(8,10) }}</p>
                                        </div>
                                    </div>
                                    <a v-bind:href="'/articleDetail' + this.PickupArticleList[0].id">
                                        <p class="article__link--title px-5 mb-0">{{ this.ArticleDetailArticleList[0].title }}</p>
                                    </a>
                                    <div class="row mt-1 px-5 pb-2">
                                        <div class="col-6 pb-5 pt-2 text-left">
                                            <p><button @click="plus1(0)"><div v-if="button_ada[0]"><img :src="src3[0]" width="17" height="15">&ensp;{{ this.ArticleDetailArticleList[0].like_count }}</div>
                                            <div v-if="!button_ada[0]" class="goodbutton_anime2">
                                                <lottie
                                                    ref="anim"
                                                    :options="lottieOptions1"
                                                    :width="35"
                                                    v-on:animCreated="handleAnimation1"
                                                    class="likeshare_icon"
                                                /></div>
                                            &ensp;{{ this.ArticleDetailArticleList[0].like_count }}</button></p>
                                        </div>
                                        <div class="col-6 pb-0 pt-2 text-right">
                                            <p class="text-right mb-0">
                                                <a class="article__link--next" v-bind:href="'/articleDetail' + this.ArticleDetailArticleList[0].id">記事を読む&emsp;<i class="fa fa-angle-right"></i></a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="article__link--box">
                                <div v-if="ArticleDetailArticleList[1]">
                                    <a v-bind:href="'/articleDetail' + this.PickupArticleList[0].id">
                                        <img class="article__link--img" v-bind:src="'image/' + this.ArticleDetailArticleList[1].thumbnail_img_path.slice(7)" alt="記事サムネイル画像2">
                                    </a>
                                    <div class="row mt-2 px-5">
                                        <div class="col-8 pb-0 text-left">
                                            <p class="article__link--category mb-0">{{ this.ArticleDetailArticleList[1].category_id.category_name }}</p>
                                        </div>
                                        <div class="col-4 pb-0 text-right font-color--orange">
                                            <p class="mb-0">{{ this.ArticleDetailArticleList[1].add_datetime.slice(0,4) }}.{{ this.ArticleDetailArticleList[0].add_datetime.slice(5,7) }}.{{ this.ArticleDetailArticleList[0].add_datetime.slice(8,10) }}</p>
                                        </div>
                                    </div>
                                    <a v-bind:href="'/articleDetail' + this.PickupArticleList[0].id">
                                        <p class="article__link--title px-5 mb-0">{{ this.ArticleDetailArticleList[1].title }}</p>
                                    </a>
                                    <div class="row mt-1 px-5 pb-2">
                                        <div class="col-6 pb-5 pt-2 text-left">
                                            <p><button @click="plus1(1)"><div v-if="button_ada[0]"><img :src="src3[1]" width="17" height="15">&ensp;{{ this.ArticleDetailArticleList[1].like_count }}</div>
                                            <div v-if="!button_ada[0]" class="goodbutton_anime2">
                                                <lottie
                                                    ref="anim"
                                                    :options="lottieOptions1"
                                                    :width="35"
                                                    v-on:animCreated="handleAnimation1"
                                                    class="likeshare_icon"
                                                /></div>&ensp;{{ this.ArticleDetailArticleList[1].like_count }}</button></p>
                                        </div>
                                        <div class="col-6 pb-0 pt-2 text-right">
                                            <p class="text-right mb-0">
                                                <a class="article__link--next" v-bind:href="'/articleDetail' + this.ArticleDetailArticleList[1].id">記事を読む&emsp;<i class="fa fa-angle-right"></i></a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> -->

        <!-- PICK UP -->
        <section>
            <div class="row region__control">
                    <div class="article__link--area">
                        <div class="article__linkhead">
                            <div class="col-12 text-left">
                                <div class="kanrenpickup_titleicon">
                                    <p class="font-ryo-gothic font-color--orange font-weight--900 font-size--20" style="margin-left:10px;">
                                    PICK UP
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- 記事リンク -->
                        <div class="article__link">
                            <div class="article__link--box">
                                <div v-if="PickupArticleList[0]">
                                    <a  v-bind:href="'/articleDetail' + this.PickupArticleList[0].id">
                                        <img class="article__link--img" v-bind:src="'image/' + this.PickupArticleList[0].thumbnail_img_path.slice(7)" alt="記事サムネイル画像2">
                                    </a>
                                    <div class="row mt-2 px-5">
                                        <div class="col-8 pb-0 text-left">
                                            <p class="article__link--category mb-0">{{ this.PickupArticleList[0].category_id.category_name }}</p>
                                        </div>
                                        <div class="col-4 pb-0 text-right font-color--orange">
                                            <p class="mb-0">{{ this.PickupArticleList[0].add_datetime.slice(0,4) }}.{{ this.PickupArticleList[0].add_datetime.slice(5,7) }}.{{ this.PickupArticleList[0].add_datetime.slice(8,10) }}</p>
                                        </div>
                                    </div>
                                    <a class="article__link--next" v-bind:href="'/articleDetail' + this.PickupArticleList[0].id">
                                        <p class="article__link--title px-5 mb-0">{{ this.PickupArticleList[0].title }}</p>
                                    </a>
                                    <div class="row mt-1 px-5 pb-2">
                                        <div class="col-6 pb-5 pt-2 text-left">
                                            <p class="mb-0"><button @click="plus2(0)"><div v-if="button_pu[0]" class="goodbutton_stop"><img :src="src4[0]" width="17" height="15">&ensp;{{ this.PickupArticleList[0].like_count }}</div>
                                            <div v-if="!button_pu[0]" class="goodbutton_anime2">
                                                <div class="heartsize">
                                                <lottie
                                                    ref="anim"
                                                    :options="lottieOptions1"
                                                    v-on:animCreated="handleAnimation1"
                                                    class="likeshare_icon"
                                                /></div>{{ this.PickupArticleList[0].like_count }}</div></button></p>
                                        </div>
                                        <div class="col-6 pb-0 pt-2 text-right">
                                            <p class="text-right mb-0">
                                                <a class="article__link--next" v-bind:href="'/articleDetail' + this.PickupArticleList[0].id">記事を読む&emsp;<i class="fa fa-angle-right"></i></a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="article__link--box">
                                <div v-if="PickupArticleList[1]">
                                    <a  v-bind:href="'/articleDetail' + this.PickupArticleList[0].id">
                                        <img class="article__link--img" v-bind:src="'image/' + this.PickupArticleList[1].thumbnail_img_path.slice(7)" alt="記事サムネイル画像2">
                                    </a>
                                    <div class="row mt-2 px-5">
                                        <div class="col-8 pb-0 text-left">
                                            <p class="article__link--category mb-0">{{ this.PickupArticleList[1].category_id.category_name }}</p>
                                        </div>
                                        <div class="col-4 pb-0 text-right font-color--orange">
                                            <p class="mb-0">{{ this.PickupArticleList[1].add_datetime.slice(0,4) }}.{{ this.PickupArticleList[1].add_datetime.slice(5,7) }}.{{ this.PickupArticleList[1].add_datetime.slice(8,10) }}</p>
                                        </div>
                                    </div>
                                    <a class="article__link--next" v-bind:href="'/articleDetail' + this.PickupArticleList[0].id">
                                        <p class="article__link--title px-5 mb-0">{{ this.PickupArticleList[1].title }}</p>
                                    </a>
                                    <div class="row mt-1 px-5 pb-2">
                                        <div class="col-6 pb-5 pt-2 text-left">
                                            <p class="mb-0"><button @click="plus2(1)"><div v-if="button_pu[1]" class="goodbutton_stop"><img :src="src4[1]" width="17" height="15">&ensp;{{ this.PickupArticleList[1].like_count }}</div>
                                            <div v-if="!button_pu[1]" class="goodbutton_anime2">
                                                <div class="heartsize">
                                                <lottie
                                                    ref="anim"
                                                    :options="lottieOptions1"
                                                    v-on:animCreated="handleAnimation1"
                                                    class="likeshare_icon"
                                                /></div>{{ this.PickupArticleList[1].like_count }}
                                            </div></button></p>
                                        </div>
                                        <div class="col-6 pb-0 pt-2 text-right">
                                            <p class="text-right mb-0">
                                                <a class="article__link--next" v-bind:href="'/articleDetail' + this.PickupArticleList[1].id">記事を読む&emsp;<i class="fa fa-angle-right"></i></a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        <!-- 記事一覧ボタン -->
        <section>
            <div class="concontainer-fluid">
                <div class="row">
                    <div class="col-12 text-center text-center" style="margin-top:50px; margin-bottom:200px;">
                        <router-link :to="{ name: 'ArticleList'}">
                            <a class="button font-ryo-gothic">
                                記事一覧
                            <img class="kijiall_yajirushi" src="@/assets/image/kijiall_yajirushi.png" alt="kijiall_yajirushi">
                            </a>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <Footer />
    </v-app>
</template>

<script>
import Header from '@/components/molecules/Header.vue'
import Footer from '@/components/molecules/Footer.vue'
import Lottie from 'vue-lottie';
import animationData1 from '@/assets/image/goodicon_anime.json';
import * as ArticleDetailAPI from "../../../apis/ArticleDetailAPI"
// import * as ArticleDetailArticleListAPI from "../../../apis/ArticleDetailArticleAPI"
import * as PickupArticleListAPI from "../../../apis/PickUpArticleAPI"
import * as MemberDetailAPI from "../../../apis/MemberDetailAPI"
import * as LikeCountUpdateAPI from "../../../apis/LikeCountUpdateAPI"
import * as AccessCountUpdateAPI from "../../../apis/AccessCountUpdateAPI"
export default {
    data() {
        return{
            id:'',
            title:'',
            content:'',
            category_id:'',
            thumbnail_img_path:'',
            member_id:'',
            like_count:'',
            access_count:'',
            pick_up_flg:'',
            site_type:'',
            related_service_id:'',
            approval_status:'',
            add_datetime:'',
            member:'',
            ArticleDetailArticleList:[],
            PickupArticleList:[],
            like_flg:false,
            baseUrl: process.env.VUE_APP_URL_BASE,
            imgPath: process.env.VUE_APP_IMG_BASE,
            src2: require("@/assets/image/goodicon_before.svg"),
            src3: [],
            src4: [],
            button_good: true,
            button_ada:[],
            button_pu:[],
            lottieOptions1: {
                    animationData: animationData1,
                    loop: false,
                },
        };
    },
    name: "ArticleDetail",
    components: {
        Header,Footer,Lottie
    },
    methods: {
        handleAnimation1: function (anim) {
                this.anim = anim;
            },
        
        plus(id){
            if(document.cookie.indexOf('article'+ id + '=liked') == -1){
                LikeCountUpdateAPI.editLikeCount(id)
                .then(() => {
                    this.like_count = this.like_count + 1;
                    document.cookie ='article'+ id + '=liked';
                    this.button_good = false;
                })
                .catch(error => {
                    console.log("Error : " + error.response);
                });
            }
        },
        plus1(id){
            var num = this.ArticleDetailArticleList[id].id
            if(document.cookie.indexOf('article'+ num + '=liked') == -1){
                LikeCountUpdateAPI.editLikeCount(num)
                .then(() => {
                    this.ArticleDetailArticleList[id].like_count = (Number(this.ArticleDetailArticleList[id].like_count) + 1).toString();
                    this.button_ada[id] = false;
                    document.cookie ='article'+ num + '=liked';
                })
                .catch(error => {
                    console.log("Error : " + error.response);
                });
            }
        },
        plus2(id){
            var num = this.PickupArticleList[id].id
            if(document.cookie.indexOf('article'+ num + '=liked') == -1){
                LikeCountUpdateAPI.editLikeCount(num)
                .then(() => {
                    this.PickupArticleList[id].like_count = (Number(this.PickupArticleList[id].like_count) + 1).toString();
                    this.button_pu[id] = false;
                    document.cookie ='article'+ num + '=liked';
                    
                })
                .catch(error => {
                    console.log("Error : " + error.response);
                });
            }
        },
        appendImgPath:function(content) {
            let str = content
            str = str.split('${imgPath}')
            return str.join(this.imgPath)
        },

        donmai_icon_first(id){
            if(document.cookie.indexOf('article'+ id + '=liked') != -1){
                this.src2 = require("@/assets/image/goodicon_after.svg");
            }
        },

        donmai_icon_kanren_first(index){
            if(document.cookie.indexOf('article'+ this.ArticleDetailArticleList[index].id + '=liked') != -1){
                this.src3[index] = require("@/assets/image/goodicon_after.svg");
            }else{
                this.src3[index] = require("@/assets/image/goodicon_before.svg");
            }
        },

        donmai_icon_pu_first(index){
            if(document.cookie.indexOf('article'+ this.PickupArticleList[index].id + '=liked') != -1){
                this.src4[index] = require("@/assets/image/goodicon_after.svg");
            }else{
                this.src4[index] = require("@/assets/image/goodicon_before.svg");
            }
        },
    },
    mounted() {
    },

    created: function() {
        ArticleDetailAPI.getArticleDetail(this.$route.params.id)
        .then(response => {
            if (response.payload.data.del_flg == false) {
                this.add_datetime = response.payload.data.add_datetime.toString();
                this.approval_status = response.payload.data.approval_status;
                this.id = response.payload.data.id;
                this.title = response.payload.data.title;
                this.content = response.payload.data.content;
                this.category_id = response.payload.data.category_id;
                this.thumbnail_img_path = response.payload.data.thumbnail_img_path;
                this.member_id = response.payload.data.member_id;
                this.like_count = Number(response.payload.data.like_count);
                this.access_count = response.payload.data.access_count;
                this.pick_up_flg = response.payload.data.pick_up_flg;
                this.site_type = response.payload.data.site_type.toString();
                this.related_service_id = response.payload.data.related_service_id;
                //ArticleDetailArticleListAPI.getArticleDetailArticleList(this.category_id.id,this.id)
                //.then(response => {
                //    this.ArticleDetailArticleList = response.payload.data;
                //    this.donmai_icon_kanren_first(0);
                //    this.donmai_icon_kanren_first(1);
                // })
                //.catch(error => {
                //    console.log("Error : " + error.response);
                //});
                MemberDetailAPI.getMemberDetail(this.member_id)
                .then(response => {
                    this.member = response.payload.data;
                })
                .catch(error => {
                    console.log("Error : " + error.response);
                });
                PickupArticleListAPI.getPickupArticleList(this.id)
                .then(response => {
                    this.PickupArticleList = response.payload.data;
                    this.donmai_icon_pu_first(0);
                    this.donmai_icon_pu_first(1);
                })
                .catch(error => {
                    console.log("Error : " + error.response);
                });
                AccessCountUpdateAPI.editAccessCount(this.id)
                .catch(error => {
                    console.log("Error : " + error.response);
                });
                this.donmai_icon_first(this.id);
                this.button_ada[0] = true;
                this.button_ada[1] = true;
                this.button_pu[0] = true;
                this.button_pu[1] = true;
            } else {
                // 対象が削除した場合
                this.$router.push({
                    name: "ApiNotFoundError",
                    params: {
                    page_name: "記事",
                    btn_name: "記事一覧",
                    transition_path: "/articleList",
                    },
                });
            }
        })
        .catch(error => {
            // 対象がない場合、404に遷移
            this.$router.push({
                name: 'ApiNotFoundError',
                params: {
                    page_name : "記事",
                    btn_name : "記事一覧",
                    transition_path : "/articleList",
                }
            }),
            console.log("Error : " + error.response);
        });
    
    },
    computed: {
        src:function() {
            return this.imgPath + this.related_service_id.icon_path
        }
    },
};
</script>

<!-- 共通CSS -->
<style src="../miiits/css/common.css"></style>

<!-- ローカルCSS -->
<style scoped>
/* 全体 */
.body__wrap{
    overflow: hidden;
}
.memberlist_range{
    margin-top:100px;
    margin-bottom:100px;

}
/* ================================ * /
            パンクズリスト
==================================== */
.breadcrumb {
    /* margin: 40px 0 0 30px; */
    padding: 0;
    list-style: none;
}
.breadcrumb li {
    display: inline;/*横に並ぶように*/
    list-style: none;
    font-weight: bold;/*太字*/
}
/* >のcss */
.breadcrumb li:after {/* >を表示*/
    font-family: "Font Awesome 5 Free";
    content: "\f105";
    padding: 0.1rem 1.3em;
    color: #DB4627;
    font-size: 14px;
}
.breadcrumb li:last-child:after {
    content: '';
}
/* 文字の色 */
.breadcrumb li a {
    text-decoration: none;
    color: #A7A7A7;/*色*/
    font-family: ryo-gothic-plusn,sans-serif;
    font-style: normal;
    font-size:12px;
}
/* ---SP--- */
@media screen and (max-width: 420px) {

    .breadcrumb {
        /* margin:40px 0 0 20px; */
        padding: 0;
        list-style: none;
        /* テキストが長いとき省略 */
        display: block;
        width: 80%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

    }
    .breadcrumb li {
        display: inline;/*横に並ぶように */
        list-style: none;
        font-weight: bold;/*太字*/
    }
    /* >のcss */
    .breadcrumb li:last-child:after {
        content: '';
    }
    /* 文字の色 */
    .breadcrumb li a {
        text-decoration: none;
        color: #A7A7A7;/*色*/
        font-family: ryo-gothic-plusn,sans-serif;
        font-style: normal;
        font-size:12px;
    }

}
/* ================================ * /
            日付
==================================== */
.date{
    text-align: right;
}
/* ---SP--- */
@media screen and (max-width: 420px) {
/* 日付 */
.date{
    text-align: right;
    font-size:12px;
    margin-top:5px;
}
}

/* ================================ * /
            一番上の画像
==================================== */
.article_img{
    width:100%;
    border-radius:20px;
    margin: 3em 0
}
/* ================================ * /
            概要の文章
==================================== */
.bodytext_1{
    margin-top:50px;
}
/* ================================ * /
            本文部分
==================================== */
/* 本文 */
.bodytext{
    text-align: left;
}
/* 見出し */
.titleicon{
    border-left: double 10px #DB4627;
    margin:50px 0 50px 0;
    padding-right:100px;
}
/* 人のアイコン */
.icon_img{
    width:80%;
    height:auto;
}
/* 関連サービス */
/* ロゴの画像 */
.service_logo_img {
    width: 70%;
}
/* 見出し */
.titlefont{
    font-size:14px;
    text-align:left;
    padding-left:10px;
}
/* ---タブレット--- */
@media (max-width: 820px) {
        /* 記事タイトル */
    .titlename{
        font-size:23px;
    }
    /* 見出し */
    .titlefont{
        font-size:14px;
        text-align:left;
        padding-left:10px;
    }
}
/* ---SP--- */
@media screen and (max-width: 420px) {
    /* 記事タイトル */
    .titlename{
        font-size:16px;
    }
    /* 人の名前 */
    .peoplename{
        font-size:11px;
    }
    /* 見出しの2本線アイコン */
    .titleicon{
    border-left: double 5px #DB4627;
    margin-top:50px;
    padding-right:5px;
    }
    /* 見出しのタイトル */
    .titlefont{
    font-size:14px;
    text-align:left;
    padding-left:10px;
    }
}


/* ================================ * /
            関連サービス
==================================== */
/* 右のテキスト全体 */
.kanren_textall{
    padding:50px 10px 50px 10px;
}

/* 関連サービスWEBサイトリンク */
.kanren_link {
    color: #333333;
    transition: 0.3s;
}

@media screen and (max-width: 420px) {
    /* サービスのロゴ */
    .kanren_link {
        color: #DB4627;
    }
}

.kanren_textall a:hover {
    opacity: 0.6;
}

/* 関連サービス説明文 */
.kanren_text1 {
    color: #333333;
    font-size: clamp(0.875rem, 0.784rem + 0.45vw, 1.125rem);
    font-weight: 700;
}

.access_icon{
    width:15px;
    margin-bottom:5px;
}
li{
    list-style: none;
}
ul{
    display:flex;
}
/* 関連サービスのオレンジの見出し */
.kanren_tab{
    color:#FFFFFF;
    background-color:#DB4627;
    text-align:center;
    position:absolute;
    padding:10px 20px 10px 20px;
    display: flex;
    z-index: 99;
}
.kanren_range{
    position:relative;
    padding:0;
}
/* ---タブレット--- */
@media (max-width: 820px) {
    /* 関連サービス赤の見出し */
    .kanren_redtab{
    color:#FFFFFF;
    font-size:20px;
    background-color:#DB4627;
    text-align:center;
    position:absolute;
    left:10px;
    font-size:18px;
    padding:10px 20px 10px 20px;
    }
    /* 関連サービスの右のテキスト全体 */
    .kanren_textall{
        padding: 0px 10px 25px 10px;
    }
    /* サービスのロゴ */
    .service_logo_img {
    width:70%;
    }
    /* webサイトはこちら */
    ul{
    margin-left:120px;
    margin-right:12z0px;
    }
}
/* ---SP--- */
@media screen and (max-width: 420px) {
    /* サービスのロゴ */
    .service_logo_img {
    width:170px;
    margin-bottom:0;
    margin-top:50px;
    }
        /* webサイトはこちら */
    .kanrenweb{
    margin-left:20px;
    margin-right:20px;
    }
}
/* ================================ * /
        この記事を書いたMiiitsメンバー
==================================== */
/* メンバーの写真 */
.writtingmember_img{
    width: 100px;
    height: 100px;
    margin: 50px 0 50px 0;
    object-fit: cover;
    border-radius: 50%;
}
.yajirushi_icon{
    width:15px;
}
.member_text1{
    margin-left:10px;
    text-align:left;
}
.member_box1{
    margin-left:40px;
    display: flex;
    margin-top:30px;
    position:absolute;
}
.member_textname{
    text-align: left;
}
.member_box2{
    margin-left:20px;
}
.member_box3{
margin-left:150px;
margin-top:100px;
position:relative;
}
/* ---タブレット--- */
@media (max-width: 820px) {
    /* メンバーの記事を見る */

    .member_text2{
        font-size:13px;
        margin-left:10px;
    }
        /* メンバーの記事を見るul */
    .writingmember{
        margin-left:0px;
        padding-left:0;
    }
        /* 矢印アイコン */
    .yajirushi_icon{
        margin-bottom:8px;
    }
}

@media screen and (max-width: 576px) {
    /* 写真、この記事を書いたMiiitsメンバー、名前 */
    .member_box1{
        margin-left:0px;
        display: flex;
        margin-top:30px;
        position:absolute;
    }
}


/* ---SP--- */
@media screen and (max-width: 420px) {
    /* メンバーの写真 */
    .writtingmember_img{
        width:70px;
        height: 70px;
        margin:0px 0px 50px 0px;
    }
    /* この記事を書いたメンバー */
    .member_texttitle{
        font-size: 14px;
    }
    .member_textname{
        font-size:18px;
    }
    .member_text1{
        margin-left:0px;
        font-size:14px;
    }
    .member_textname{
        text-align: left;
    }
    /* この記事を書いたMiiitsメンバー、名前を縦並び */
    .member_box2{
        margin-left:8px;
    }
    /* テキスト、記事を見るボタン */
    .member_box3{
        margin-left:0px;
        margin-top:130px;
    }

    .member_text2 {
        font-size:13px;
        margin-left: 0;
    }


}
/* ================================ * /
        like,shareボタン
==================================== */
/* likeアイコン */
.likeshare_icon{
    width:30px;
}
.likeshare{
    margin-top:40px;
    margin-bottom:0;
}
.like_number{
    background-color:#FFFFFF;
    width:60px;
    margin-top:5px;
}
.LIKE_all{
    display: flex;
    justify-content: center;
    padding: 5px;
}
.LIKE_all>div{
    padding: 5px;
}
/* shareアイコン */
.SHARE_all{
    display: flex;
    justify-content: center;
    padding: 5px;
}
.SHARE_all>div{
    padding: 5px;
}
.goodbutton_anime{
    width:39px;
    display: flex;
    margin-bottom: 1.5px;
}

.goodbutton_stop{
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1.5px;
  margin-left: 1px;
}

.goodbutton_anime2 {
  width:42px;
  height: 24px;
  display: flex;
  align-items: flex-end;
}

.heartsize{
  width: 24.873px;
  height: 25.869px;
}

.biglike{
    margin-top: 5.5px;
    margin-right: 4px;
}
/* ================================ * /
        記事一覧ボタン
==================================== */
.button {
    color:#DB4627;
    font-size: 20px;
    font-weight: 900;
    background: #fff;
    padding: 18px 30px;
    border: 3px solid #DB4627;
    box-shadow: 10px 5px 0 #DB4627;
    border-radius: 80px;
    width:150px;
    height:150px;
    display: inline-block;
    transition: .3s;
    text-align: center;
    text-decoration: none;
}
.button:hover {
    transform: translateY(5px);
    box-shadow: 0 0 0 #DB4627;
}
.kijiall_yajirushi{
    padding-top:10px;
    width:60%;
}

/* ---SP--- */
@media screen and (max-width: 420px) {
/* 記事一覧ボタン */
    .button {
        color:#DB4627;
        font-size: 16px;
        font-weight: 800;
        background: #fff;
        padding: 18px 20px;
        border: 3px solid #DB4627;
        box-shadow: 5px 3px 0 #DB4627;
        border-radius: 80px;
        width:110px;
        height:110px;
        display: inline-block;
        transition: .3s;
        text-align: center;
        text-decoration: none;
    }
    .button:hover {
    transform: translateY(5px);
    box-shadow: 0 0 0 #DB4627;
    }
    .kijiall_yajirushi{
    padding-top:7px;
    width:60%;
    }
}

/* タブレット */
@media (max-width: 768px) {
/* PICKUP 関連記事 */
    .kanrenkiji_date{
        margin-top:5px;
        margin-left:5px;
    }
    .good_icon{
        width:30px;
        margin-right:10px;
    }
    .yajirushi_icon{
        width:15px;
    }
    .kanrenkiji_kijiwoyommu{
        margin-left:200px;
    }
}

.aiticle_category {
    color: #fff;
    background-color: #DB4627;
    font-family: fot-rodin-pron,sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    border-radius: 15px;
    padding: 10px 0px 10px 0px;
    display: inline-block;
    margin-right: 50px;
    margin-bottom:0;
}

.article__txt {
    line-height: 2rem;
}
</style>