// initial state
const state = () => ({
    list: [],
    snackbar: { isDisplay: false, method: null, },
    defaults: {
        id: { value: null, error: false, errorMsg: null },
        employee_no: { value: null, error: false, errorMsg: null },
        miites_team_id: { value: null, error: false, errorMsg: null },
        post_id: { value: null, error: false, errorMsg: null },
        division_id: { value: null, error: false, errorMsg: null },
        team_in_division_id: { value: null, error: false, errorMsg: null },
        pen_name: { value: null, error: false, errorMsg: null },
        pen_name_roman: { value: null, error: false, errorMsg: null },
        self_introduct_stat: { value: null, error: false, errorMsg: null },
        profile_img_path: { value: null, error: false, errorMsg: null },
        profile_img_maru_path: { value: null, error: false, errorMsg: null },
        add_datatime: { value: null, error: false, errorMsg: null },
        add_account: { value: null, error: false, errorMsg: null },
        upd_datatime: { value: null, error: false, errorMsg: null },
        upd_account: { value: null, error: false, errorMsg: null },
        del_flg: { value: false, error: false, errorMsg: null },
    },
    items: {
        id: { value: null, error: false, errorMsg: null },
        employee_no: { value: null, error: false, errorMsg: null },
        miites_team_id: { value: null, error: false, errorMsg: null },
        post_id: { value: null, error: false, errorMsg: null },
        division_id: { value: null, error: false, errorMsg: null },
        team_in_division_id: { value: null, error: false, errorMsg: null },
        pen_name: { value: null, error: false, errorMsg: null },
        pen_name_roman: { value: null, error: false, errorMsg: null },
        self_introduct_stat: { value: null, error: false, errorMsg: null },
        profile_img_path: { value: null, error: false, errorMsg: null },
        profile_img_maru_path: { value: null, error: false, errorMsg: null },
        add_datatime: { value: null, error: false, errorMsg: null },
        add_account: { value: null, error: false, errorMsg: null },
        upd_datatime: { value: null, error: false, errorMsg: null },
        upd_account: { value: null, error: false, errorMsg: null },
        del_flg: { value: false, error: false, errorMsg: null },
    },
})

// getters
const getters = {}

// actions
const actions = {
    getList({ dispatch }) {
        dispatch('getList', { apiName: 'miiits/memberList/', namespace: 'Member' }, { root: true })
    },
}

// mutations
const mutations = {
    resetItem(state) {
        state.defaults.employee_no = state.items.employee_no
        state.defaults.pen_name = state.items.pen_name
        state.items = JSON.parse(JSON.stringify(state.defaults))
    },
    setEmployeeNo(state, {employee_no}) {
        state.items.employee_no.value = employee_no
    },
    setName (state, { pen_name }) {
        state.items.pen_name.value = pen_name
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}