<template>
    <div>
        <form-label-text label="名前" placeholder="名前で絞り込み" :value.sync="searchStr" />
        <employee-search-table :list.sync="list" :searchWord="searchStr" @clickRow="setEmployeeNo" />
    </div>
</template>
<script>
import EmployeeSearchTable from '../molecules/EmployeeSearchTable.vue';
import FormLabelText from '../molecules/FormLabelText.vue';

export default {
    components: { EmployeeSearchTable, FormLabelText },
    data: () => ({
        namespace: "TransportationExpenseAdmin",
        searchStr: "",
    }),
    computed: {
        list: {
            get: function() {
                return this.$store.state[this.namespace]['list']
            },
        },
        transportationExpenseList: {
            get: function() {
                return this.$store.state['TransportationExpense']['list']
            }
        }
    },
    watch: {
        transportationExpenseList() {
            this.$store.dispatch("TransportationExpenseAdmin/getList");
        },
    },
    mounted() {
        this.$store.dispatch("TransportationExpenseAdmin/getList");
    },
    methods: {
        getTransPortationList() {
            this.$store.dispatch("TransportationExpense/getList");
        },
        setEmployeeNo (data) {
            this.$store.commit("TransportationExpense/setEmployeeNo", { employee_no: data.employee_no })
            this.$store.commit("TransportationExpense/setName", { full_name: data.name })
            this.$store.commit("TransportationExpense/setTotal", { total_price: data.total })
        }
    }
};
</script>