import Vue from 'vue'
import Router from 'vue-router'
// import VueGtag from 'vue-gtag'

import Auth from '@/components/pages/Auth'
import Portal from '@/components/pages/Portal'
import EmployeePortal from '@/components/pages/EmployeePortal'
import Test from '@/components/pages/Test'
import SwitchApplication from '@/components/pages/SwitchApplication'
import TransportationExpense from '@/components/pages/TransportationExpense'
import TransportationExpenseAdmin from '@/components/pages/TransportationExpenseAdmin'
import CommuterPassPrice from '@/components/pages/CommuterPassPrice'
import DocumentList from '@/components/pages/document/DocumentList'
import Main from '@/components/pages/miiits/Main'
import About from '@/components/pages/miiits/About'
import MemberList from '@/components/pages/miiits/MemberList'
import ArticleList from '@/components/pages/miiits/ArticleList'
import ArticleSearchResult from '@/components/pages/miiits/ArticleSearchResult'
import ArticleDetail from '@/components/pages/miiits/ArticleDetail'
import MemberDetail from '@/components/pages/miiits/MemberDetail'
import NotFoundError from '@/components/pages/miiits/NotFoundError'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        // ===== Miiits =====
        {
            path: '/',
            name: 'Main',
            component: Main
        },
        // {
        //     path: '/about',
        //     name: 'About',
        //     component: About
        // },
        // {
        //     path: '/articleList',
        //     name: 'ArticleList',
        //     component: ArticleList
        // },
        // {
        //     path: '/articleDetail:id',
        //     name: 'ArticleDetail',
        //     component: ArticleDetail
        // },
        // {
        //     path: '/articleSearchResult',
        //     name: 'ArticleSearchResult',
        //     component: ArticleSearchResult
        // },
        // {
        //     path: '/memberList',
        //     name: 'MemberList',
        //     component: MemberList
        // },
        // {
        //     path: '/memberDetail:id?',
        //     name: 'MemberDetail',
        //     component: MemberDetail,
        // },
        // 404(画面が存在しない場合)
        {
            path: '*',
            name: 'NotFoundError',
            component: NotFoundError,
        },
        // 404(APIエラー)
        {
            path: '/notFoundError',
            name: 'ApiNotFoundError',
            component: NotFoundError,
            props: true
        }
        // ===== 社内ポータル =====
        // {
        //     path: '/auth',
        //     name: 'Auth',
        //     component: Auth
        // },
        // {
        //     path: '/portal',
        //     name: 'Portal',
        //     component: Portal,
        //     meta: { requiresAuth: true }
        // },
        // {
        //     path: '/employee',
        //     name: 'EmployeePortal',
        //     component: EmployeePortal,
        //     meta: { requiresAuth: true }
        // },
        // {
        //     path: '/test',
        //     name: 'test',
        //     component: Test,
        //     meta: { requiresAuth: true }
        // },
        // {
        //     path: '/switchApplication',
        //     name: 'switchApplication',
        //     component: SwitchApplication,
        //     meta: { requiresAuth: true }
        // },
        // {
        //     path: '/transportationExpense',
        //     name: 'transportationExpense',
        //     component: TransportationExpense,
        //     meta: { requiresAuth: true }
        // },
        // {
        //     path: '/transportationExpenseAdmin',
        //     name: 'transportationExpenseAdmin',
        //     component: TransportationExpenseAdmin,
        //     meta: { requiresAuth: true }
        // },
        // {
        //     path: '/commuterPassPrice',
        //     name: 'commuterPassPrice',
        //     component: CommuterPassPrice,
        //     meta: { requiresAuth: true }
        // },
        // {
        //     path: '/documentList',
        //     name: 'DocumentList',
        //     component: DocumentList
        // },
    ],

    // 画面遷移時の表示位置
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }

})

router.beforeEach((to, from, next) => {
    const session = JSON.parse(sessionStorage.getItem('vue-session-key'))
    const token = session != null ? session.token : null
    if (to.matched.some(record => record.meta.requiresAuth) && token == null) {
        next({ path: '/auth', query: { redirect: to.fullPath } });
    } else {
        next();
    }
});


// Vue.use(VueGtag, {
//     config: {id: 'G-PQVHV4ZK43'}
// }, router);

export default router
