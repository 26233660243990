<template>
  <div>
    <div class="dropdown">
      <button
        class="btn btn-secondary dropdown-toggle articlelistst__dropdown"
        type="button"
        id="dropdownMenu"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ this.selectedSort }}
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenu">
        <li>
          <button
            class="dropdown-item"
            type="button"
            @click="setSortToDisplay(1)"
          >
            新着順
          </button>
        </li>
        <li>
          <button
            class="dropdown-item"
            type="button"
            @click="setSortToDisplay(2)"
          >
            人気順
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    selectedSort() {
      var selectedSort = "新着順";
      var storeSortNow = this.$store.state.miiitsStore.sort;
      if (storeSortNow == "sortByAccessCountCount" || storeSortNow == 2) {
        selectedSort = "人気順";
      } else {
        selectedSort = "新着順";
      }
      return selectedSort;
    },
  },
  methods: {
    // ソート順処理(デフォルト：新着順)
    setSortToDisplay(sortBy) {
      let sortArray = this.$store.state.miiitsStore.articleArrays;
      // 選択したソートをstoreに保存
      this.$store.dispatch("miiitsStore/setSort", sortBy);
      let storeSort = this.$store.state.miiitsStore.sort;
      if (storeSort == "sortByAccessCountCount" || storeSort === 2) {
        // 人気順：いいね数＋アクセス数
        sortArray.sort(function (a, b) {
          return (
            parseInt(b.access_count) +
            parseInt(b.like_count) -
            (parseInt(a.access_count) + parseInt(a.like_count))
          );
        });
        this.$store.dispatch("miiitsStore/setSort", "sortByAccessCountCount");

        //ソートした配列をstoreに保存する
        this.$store.dispatch(
          "miiitsStore/setDisplayLists",
          sortArray.slice(0, this.$store.state.miiitsStore.displayLimit)
        );
        this.$emit("setgood_sort");
      } else {
        sortArray.sort(function (a, b) {
          return new Date(b.add_datetime) - new Date(a.add_datetime);
        });
        this.$store.dispatch("miiitsStore/setSort", "sortByAddDate");

        //ソートした配列をstoreに保存する
        this.$store.dispatch(
          "miiitsStore/setDisplayLists",
          sortArray.slice(0, this.$store.state.miiitsStore.displayLimit)
        );
        this.$emit("setgood_sort");
      }
    },
  },
  watch: {},
};
</script>
<!-- 共通CSS -->
<style src="../pages/miiits/css/common.css"></style>

<!-- ローカルCSS -->
<style scoped>
.body__wrap {
  overflow: hidden;
}
/* ================================ * /
            ドロップダウン
==================================== */
.articlelistst__dropdown {
  background-color: #fff;
  color: #db4627;
  border-color: transparent;
  transition: 1s all;
  border-radius: 15px;
}
@media (max-width: 992px) {
  .articlelistst__dropdown {
    margin-bottom: 2rem;
  }
}

.articlelistst__dropdown:after {
  margin-left: 5em;
}

.articlelistst__dropdown:focus {
  box-shadow: 0 0 0 0 transparent;
}

/* 既存クラス上書き */
.dropdown-menu {
  color: #db4627;
  padding-left: 0;
  min-width: 10.5rem;
}
</style>