<template>
    <div>
        <label> {{ label }} </label>
        <text-field
            readonly
            :value.sync="date"
            :error="error"
            :errorMsg="errorMsg"
            placeholder="下のカレンダーで該当の日付を入力してください"
        />
        <date-picker v-bind:value.sync="date" />
    </div>
</template>
<script>
import DatePicker from "../atoms/DatePicker.vue";
import TextField from "../atoms/TextField.vue";
export default {
    components: { DatePicker, TextField },
    props: {
        handle: String,
        label: String,
        value: String,
        placeholder: String,
        error: Boolean,
        errorMsg: Array,
    },
    computed: {
        date: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit("update:value", value);
            },
        },
    },
};
</script>
