<template>
    <v-main app>
        <transportation-expense-template />
    </v-main>
</template>

<script>
import TransportationExpenseTemplate from "../templates/TransportationExpenseTemplate.vue";
export default {
    name: "TransportationExpense",
    components: {
        TransportationExpenseTemplate,
    },
    mounted: function () {
        this.getEmployeeNo()
    },
    methods: {
        getEmployeeNo() {
            this.$store.dispatch("TransportationExpense/getEmployeeNo")
        },
    },
};
</script>