import { monthList } from "./monthLastDay"

// initial state
const state = () => ({
    list: [],
    months: [monthList[0]],
    types: ['JR/私鉄', 'バス', 'その他'],
    snackbar: {
        isDisplay: false,
        method: null,
    },
    defaults: {
        id: {
            value: null,
            error: false,
            errorMsg: null
        },
        employee_no: {
            value: null,
            error: false,
            errorMsg: null
        },
        payday: {
            value: null,
            error: false,
            errorMsg: null
        },
        type: {
            value: null,
            error: false,
            errorMsg: null
        },
        transportation_facilities: {
            value: null,
            error: false,
            errorMsg: null
        },
        visit_target: {
            value: null,
            error: false,
            errorMsg: null
        },
        route_from: {
            value: null,
            error: false,
            errorMsg: null
        },
        route_to: {
            value: null,
            error: false,
            errorMsg: null
        },
        date_from: {
            value: null,
            error: false,
            errorMsg: null
        },
        date_to: {
            value: null,
            error: false,
            errorMsg: null
        },
        price: {
            value: null,
            error: false,
            errorMsg: null
        },
    },
    items: {
        id: {
            value: null,
            error: false,
            errorMsg: null
        },
        employee_no: {
            value: null,
            error: false,
            errorMsg: null
        },
        payday: {
            value: null,
            error: false,
            errorMsg: null
        },
        type: {
            value: null,
            error: false,
            errorMsg: null
        },
        transportation_facilities: {
            value: null,
            error: false,
            errorMsg: null
        },
        visit_target: {
            value: null,
            error: false,
            errorMsg: null
        },
        route_from: {
            value: null,
            error: false,
            errorMsg: null
        },
        route_to: {
            value: null,
            error: false,
            errorMsg: null
        },
        date_from: {
            value: null,
            error: false,
            errorMsg: null
        },
        date_to: {
            value: null,
            error: false,
            errorMsg: null
        },
        price: {
            value: null,
            error: false,
            errorMsg: null
        },
    },
})

    // getters
const getters = {}

// actions
const actions = {
    // submit ({ state, dispatch, commit }) {
    submit ({
        state,
        commit,
        dispatch
    }) {
        if (validateForms(state)) {
            const thenMethod = function () {
                commit('resetItem')
                state['snackbar'] = { isDisplay: true, method: "create" }
                dispatch('getList')
            }
            dispatch('postAction', {
                apiName: 'api/create-commuter-pass-price/',
                namespace: 'CommuterPassPrice',
                thenAction: thenMethod
            }, {
                root: true
            })
        }
    },
    update({
        state,
        commit,
        dispatch
    }) {
        if (validateForms(state)) {
            const thenMethod = function () {
                commit('resetItem')
                state['snackbar'] = { isDisplay: true, method: "update" }
                dispatch('getList')
            }
            const apiName = 'api/commuter-pass-price/' + state.items["id"].value + "/"
            dispatch('putAction', {
                apiName: apiName,
                namespace: 'CommuterPassPrice',
                thenAction: thenMethod
            }, { root: true })
        }
    },
    delete({
        state,
        commit,
        dispatch
    }) {
        const thenMethod = function () {
            commit('resetItem')
            state['snackbar'] = { isDisplay: true, method: "delete" }
            dispatch('getList')
        }
        const apiName = 'api/delete-commuter-pass-price/' + state.items["id"].value + "/"
        dispatch('logicalDeleteAction', {
            apiName: apiName,
            namespace: 'CommuterPassPrice',
            thenAction: thenMethod
        }, { root: true })
    },
    cancel({
        state,
        commit,
    }) {
        commit('resetItem')
        state['snackbar'] = { isDisplay: true, method: "cancel" }
    },
    getList({
        dispatch
    }) {
        dispatch('getList', {
            apiName: 'api/create-commuter-pass-price/',
            namespace: 'CommuterPassPrice'
        }, {
            root: true
        })
    },
    getEmployeeNo({
        dispatch
    }) {
        dispatch('getEmployeeInfo', {
            apiName: 'api/employee_no/',
            namespace: 'CommuterPassPrice'
        }, {
            root: true
        })
    },
}

function validateForms(state) {
    var errFlg = true
    if(state.items.date_from.value > state.items.date_to.value) {
        var dateFromError = ['日付(To)以前の日付を選択してください']
        state.items.date_from.error = true
        state.items.date_from.errorMsg = dateFromError
        var dateToError = ['日付(From)以降の日付を選択してください']
        state.items.date_to.error = true
        state.items.date_to.errorMsg = dateToError
        errFlg = false
    }
    return errFlg
}

// mutations
const mutations = {
    resetItem (state) {
        state.defaults.employee_no = state.items.employee_no
        state.items = JSON.parse(JSON.stringify(state.defaults))
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}