<template>
    <submit-button-with-snackbar
        :updateButton="id.value != null"
        :snackbar.sync="snackbar"
        @snackbar="snackbarUpdate"
        @updateEvent="updateEvent()"
    />
</template>
<script>
import SubmitButtonWithSnackbar from "../molecules/SubmitButtonWithSnackbar.vue";

export default {
    components: { SubmitButtonWithSnackbar },
    name: "TransportationExpense",
    data: () => ({
        namespace: "TransportationExpense",
    }),
    computed: {
        id: {
            get: function () {
                return this.$store.state[this.namespace]["items"]["id"];
            },
        },
        snackbar: {
            get: function () {
                return this.$store.state[this.namespace]["snackbar"];
            },
		}
    },
    methods: {
        updateEvent: function () {
            this.$store.dispatch("TransportationExpense/update");
            this.$emit('updateEvent')
        },
        snackbarUpdate: function (data) {
            this.snackbar.isDisplay = data;
            this.snackbar.method = null;
        },
    },
};
</script>
