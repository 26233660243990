<template>
  <div class="pager__ml_SP">
    <v-pagination
      v-model="page"
      :length="length"
      :total-visible="7"
      prev-icon="mdi-chevron-double-left"
      next-icon="mdi-chevron-double-right"
      color="#DB4627"
      id="custom_pagination"
    >
    </v-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    length() {
      this.checkCurrentPage();
      return Math.ceil(
        this.$store.state.miiitsStore.totalCount /
          this.$store.state.miiitsStore.displayLimit
      );
    },
  },
  methods: {
    checkCurrentPage() {
      this.$store.state.miiitsStore.sort;
      this.page = 1;
    },
  },
  watch: {
    // 表示されてるページ目数
    page(value) {
      this.$store.dispatch("miiitsStore/setDisplayPage", value);
      this.$emit("pageno", this.$store.state.miiitsStore.displayPage);
      this.$store.dispatch(
        "miiitsStore/setDisplayLists",
        this.$store.state.miiitsStore.articleArrays.slice(
          this.$store.state.miiitsStore.displayLimit *
            (this.$store.state.miiitsStore.displayPage - 1),
          this.$store.state.miiitsStore.displayLimit *
            this.$store.state.miiitsStore.displayPage
        )
      );
    },
  },
};
</script>
<!-- 共通CSS -->
<style src="../pages/miiits/css/common.css"></style>

<!-- ローカルCSS -->
<style scoped>
#custom_pagination ~ ul {
  background-color: rgb(11, 117, 64);
}

/* SP版位置修正 */
.pager__ml_SP {
  margin-left: -3rem;
}
</style>