<template>
    <div>
        <list-table :header="header" :list.sync="list" @editItem="editItem" :button_label="button_label" />
    </div>
</template>

<script>
import ListTable from "../atoms/ListTable.vue";
export default {
    components: { ListTable },
    name: "TransportationExpense",
    data: () => ({
        header: [],
    }),
    props: {
        list: Array,
        button_label: String,
    },
    mounted: function () {
        this.header = [
            { text: "" , value: "alert"},
            { text: "日付", value: "date" },
            { text: "訪問先", value: "visit_target" },
            { text: "要件", value: "requirement" },
            { text: "金額", value: "price" },
            { text: "交通機関", value: "transportation_facilities" },
            { text: "経路（FROM）", value: "route_from" },
            { text: "経路（TO）", value: "route_to" },
            { text: "操作", value: "actions" },
        ];
    },
    methods: {
        clickRowEvent: function (data) {
            this.$emit("clickRow", data)
        },
        editItem: function(item) {
            this.$emit("editItem", item)
        },
    }
};
</script>