<template>
    <v-app>
        <v-container grid-list-md>
            <v-row justify="center" align-content="center">
                <v-col text-center>
                    Hello,MIST太郎
                </v-col>
            </v-row>
            <v-row justify="center" align-content="center">
                <img v-bind:src="top_image">
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
export default {
    name: "Top",
    data(){
      return {
        top_image: require('@/assets/image/sample.jpg')
      }
    }
};
</script>