import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=572de65e&scoped=true&"
import script from "./Pagination.vue?vue&type=script&lang=js&"
export * from "./Pagination.vue?vue&type=script&lang=js&"
import style0 from "../pages/miiits/css/common.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./Pagination.vue?vue&type=style&index=1&id=572de65e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "572de65e",
  null
  
)

export default component.exports