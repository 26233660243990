<template>
    <v-layout fluid fill-height justify-center>
        <v-sheet class="pa-12" color="grey lighten-3" fill-height width="100%">
            <v-sheet :elevation="1" width="80%" class="mx-auto">
                <transportation-summary-info />
            </v-sheet>
            <v-sheet :elevation="1" width="80%" class="mx-auto mt-8">
                <v-expansion-panels accordion>
                    <v-expansion-panel @click="reset">
                        <v-expansion-panel-header
                            >新規登録</v-expansion-panel-header
                        >
                        <v-expansion-panel-content>
                            <transportation-expense-form />
                            <transportation-submit-buttons />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-sheet>
            <v-sheet :elevation="1" width="80%" class="mt-8 mx-auto">
                <transportation-expense-list />
            </v-sheet>
        </v-sheet>
    </v-layout>
</template>

<script>
import TransportationSummaryInfo from "../organisms/TransportationSummaryInfo.vue";
import TransportationExpenseForm from "../organisms/TransportationExpenseForm.vue";
import TransportationExpenseList from "../organisms/TransportationExpenseList.vue";
import TransportationSubmitButtons from "../organisms/TransportationSubmitButtons.vue";
export default {
    name: "TransportationExpenseTemplate",
    components: {
        TransportationExpenseForm,
        TransportationExpenseList,
        TransportationSubmitButtons,
        TransportationSummaryInfo,
    },
    data: () => ({
        namespace: "TransportationExpense",
    }),
    methods: {
        reset: function() {
            this.$store.commit('TransportationExpense/resetItem')
        }
    }
};
</script>