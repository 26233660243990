<template>
    <div>
        <v-layout justify-center>
            <v-btn v-show="submitButton" outlined class="mb-5" @click="submitEvent">登録</v-btn>
            <v-btn v-show="updateButton" outlined class="mb-5" @click="updateEvent">更新</v-btn>
            <v-btn v-show="deleteButton" outlined class="mb-5 ml-8" @click="deleteEvent">削除</v-btn>
        </v-layout>
        <v-snackbar v-model="isDisplaySnackbar" :timeout="2000" color="success">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="isDisplaySnackbar = false"
                >
                    閉じる
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
export default {
    name: "SubmitButtonWithSnackbar",
    props: {
        submitButton: Boolean,
        updateButton: Boolean,
        deleteButton: Boolean,
        cancelButton: Boolean,
        snackbar: Object,
    },
    computed: {
        isDisplaySnackbar: {
            get:function () {
                return this.snackbar.isDisplay
            },
            set:function (value) {
                this.$emit("snackbar", value)
            }
        },
        message: {
            get: function() {
                if (this.snackbar.method == "create") {
                    return "登録しました"
                }
                if (this.snackbar.method == "update") {
                    return "更新しました"
                }
                if (this.snackbar.method == "delete") {
                    return "削除しました"
                }
                if (this.snackbar.method == "cancel") {
                    return "編集を取り消しました"
                }
                return ""
            }
        }

    },
    methods: {
        submitEvent: function() {
            this.$emit("submitEvent")
        },
        updateEvent: function() {
            this.$emit("updateEvent")
        },
        deleteEvent: function() {
            this.$emit("deleteEvent")
        },
        cancelEvent: function() {
            this.$emit("cancelEvent")
        },
        snackbarClose: function() {
            this.isDisplaySnackbar = false
        }

    }
};
</script>
