<template>
    <v-app>
        <v-container grid-list-md>
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex>
                    <v-row style="max-width: 1000px; margin: auto;">
                        <v-col>
                            <commuter-pass-price-form />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <commuter-pass-price-list />
                        </v-col>
                    </v-row>
                </v-flex>
            </v-layout>
        </v-container>
    </v-app>
</template>

<script>
import CommuterPassPriceForm from "../organisms/CommuterPassPriceForm.vue";
import CommuterPassPriceList from "../organisms/CommuterPassPriceList.vue";
export default {
    name: "CommuterPassPriceTemplate",
    components: { CommuterPassPriceForm, CommuterPassPriceList },
    data: () => ({
        namespace: "CommuterPassPrice",
    }),
    mounted: function () {
        this.getEmployeeNo();
    },
    methods: {
        getEmployeeNo() {
            this.$store.dispatch("CommuterPassPrice/getEmployeeNo");
        },
    },
};
</script>