<template>
    <div>
        <div class="member__link">
            <router-link :to="{ name: 'MemberDetail', params: {id: id } }">
                <img class="memberlist__imag--icon member__link" :src="src" alt="メンバー写真">
            </router-link>
        </div>
        <p class="people_katagaki font-ryo-gothic font-weight--500 font-size--16">
            {{ job_category_name }}
        </p>
        <router-link :to="{ name: 'MemberDetail', params: {id: id } }">
            <p class="people_name font-ryo-gothic font-weight--800 font-size--20">
                {{ pen_name }}
            </p>
        </router-link>
    </div>

</template>

<script>
export default {
    data() {
        return {
            open :false,
            imgPath: process.env.VUE_APP_IMG_BASE,
        }
    },
    props: {
        id: String,
        profile_img_maru_path: String,
        job_category_name: String,
        pen_name: String,
    },
    computed: {
        src:function() {
            return this.imgPath + this.profile_img_maru_path
        }
    },
    mounted: function () {},
    methods: {}
}
</script>

<!-- ローカルCSS -->
<style scoped>

/* メンバーリストアイコン写真 */
.memberlist__imag--icon {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    object-fit: cover;
}

@media screen and (max-width: 420px) {
    .memberlist__imag--icon {
        width: 120px;
        height: 120px;
    }
}

@media screen and (max-width: 360px) {
    .memberlist__imag--icon {
        width: 100px;
        height: 100px;
    }
}




    /* aタグ初期化 */
    a {
        color: #333333 !important;
        text-decoration: none;
    }
    /* メンバーリストエリア */
    .member__list {
        display: flex; /* 子要素を横並べ */
        flex-wrap: wrap;
        justify-content: center;
    }
    /* メンバーBOX */
    .member__list--box {
        width: 165px;
        background: #FFF;
        border-radius: 10px;
        position: relative;
        flex-direction: column; /* 子要素を縦並べ */
        margin: 3%;
        margin-top: 2rem;
        font-family: ryo-gothic-plusn,sans-serif;
        font-style: normal;
    }
    @media screen and (max-width: 420px) {
        .member__list--box {
            width: 120px;
            margin: 5%;
        }
    }
    .member{
        margin-bottom:50px;
    }
    /* 社長の左のイラスト */
    .member_illust3{
        width:150px;
    }
    /* 社長の右のイラスト */
    .member_illust4{
        width:110px;
    }

    @media screen and (max-width: 550px) {
        /* 社長左のイラスト */
        .member_illust3{
        width:70px;
        margin-top:50px;
        }
        /* 社長右位のイラスト  */
        .member_illust4{
        width:48px;
        margin-top:50px;
        margin-right:20px;
        }
    }

    /* 部署の吹き出し */
    .fukidashi{
        width:300px;
    }

    /* 名前 */
    .people_name{
    border-bottom: dotted 3px #DB4627; /* ボーダー下部のスタイルを破線で指定する */
    padding-bottom:5px;
    display:inline;
    line-height:2;
    }
    /* 肩書き */
    .people_katagaki{
    margin-top:20px;
    margin-bottom:0;
    }
    /* タブレット */
    @media screen and(max-width: 957px) {
        .member_describe_range{
        position:relative;
        margin:120px 0 200px 0;;
        }
        .member_describe{
        font-size:15px;
    }
        .fukidashi{
        width:250px;
        }
    }

    .member__link {
        opacity: 1;
                transition: 0.3s;
    }

    .member__link :hover {
        opacity: 0.8;
    }

    /* ---SP--- */
    @media screen and (max-width: 420px) {
        /* 吹き出し */
        .fukidashi {
            width: 200px;
        }
        .people_name {
            font-size: 16px;
        }
        .people_katagaki {
            margin-top: 20px;
            margin-bottom: 0;
            font-size: 12px;
        }
    }

    /* 350px以下 */
    @media screen and (max-width: 349px) {
        .member_people {
            width: 100px;
        }
    }

</style>