<template>
    <v-app>
    <div class="body__wrap background__beige">
        <!-- ファーストビュー -->
        <section class="scrollify">
            <div class="concontainer-fluid">
                <div class="row">
                    <div class="col-12 fvlogo__img--box d-flex align-items-center background__orange">
                        <div class="row">
                            <div class="col-12 p-0 text-center">
                                <!-- PC版ロゴ -->
                                <img class="fvlogo__img--size mx-auto" src="@/assets/image/top_fv.svg" alt="PCMiiitsロゴ">
                                <!-- PCscroll -->
                                <lottie
                                    ref="anim"
                                    :options="lottieOptions"
                                    @:animCreated="handleAnimation"
                                    class="top__scrollanime"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- ABOUT -->
        <section class="scrollify fadein">
            <div class="body__wrap background__beige">
                <div class="concontainer-fluid">
                    <div class="firstview_range">
                        <div class="row">
                            <div class="col-md-12" style= "padding:0;">
                                <!-- ファーストビュー -->
                                <lottie
                                    ref="anim"
                                    :options="lottieOptions1"
                                    @:animCreated="handleAnimation1"
                                    class="firstviewpc"
                                />
                                <lottie
                                    ref="anim"
                                    :options="lottieOptions2"
                                    @:animCreated="handleAnimation2"
                                    class="firstviewsp"
                                    style="height: 100%;"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- サービス名 -->
        <section>
            <div class="container-fluid mx-auto">
                <div class="row about__sizecontroll">
                    <div class="about__servicebox text-center fadein">
                        <img class="about--img__size mx-5" src="@/assets/image/top/miiits_discava.png" alt="DISCaVa">
                        <a href="https://www.discava.net/index.html" target="_blank" rel="noopener noreferrer"><p class="font-ryo-gothic top__service--head">DISCaVa</p></a>
                        <p class="font-ryo-gothic top__service--subhead">WEBサイト制作<br>格安サブスクサービス</p>
                    </div>
                    <div class="about__servicebox text-center fadein">
                        <img class="about--img__size mx-5" src="@/assets/image/top/miiits_management.png" alt="マネージドサービス">
                        <p class="font-ryo-gothic top__service--head">マネージドサービス</p>
                        <p class="font-ryo-gothic top__service--subhead">ITのお困りごと<br>なんでもおまかせ</p>
                    </div>
                    <div class="about__servicebox text-center fadein">
                        <img class="about--img__size mx-5" src="@/assets/image/top/miiits_bounce.png" alt="studiobounce">
                        <a href="https://studio-bounce.jp/" target="_blank" rel="noopener noreferrer"><p class="font-ryo-gothic top__service--head">studio Bounce</p></a>
                        <p class="font-ryo-gothic top__service--subhead">デザインで、<br>はずませる</p>
                    </div>
                    <div class="about__servicebox text-center fadein">
                        <img class="about--img__size mx-5" src="@/assets/image/top/miiits_newtral.png" alt="newtral">
                        <p class="font-ryo-gothic top__service--head">Newtral</p>
                        <p class="font-ryo-gothic top__service--subhead">新しい手のつなぎ方<br>新しいふつう</p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="row">
                <div class="newservice_range fadein">
                    <p class="newservice_title">新サービス案、続々発掘中！</p>
                </div>
            </div>
            <div class="row fadein">
                <div class="newservice_wrap">
                    <img class="newservice_img nonde anime-fuwafuwa1" src="@/assets/image/new-nonde.svg">
                    <img class="newservice_img ending anime-fuwafuwa2" src="@/assets/image/new-ending.svg">
                    <img class="newservice_img omiai anime-fuwafuwa1" src="@/assets/image/new-omiai.svg">
                    <img class="newservice_img kiitemi anime-fuwafuwa2" src="@/assets/image/new-kiitemi.svg">
                </div>
            </div>
            <div class="row">
                <div class="text-center mb-2">
                    <img class="people_img" src="@/assets/image/about_3_people.png">
                </div>
            </div>
        </section>
    </div>
    <Footer />
    </v-app>
</template>

<script>
import Header from '@/components/molecules/Header.vue'
import Footer from '@/components/molecules/Footer.vue'
// import MainMistterList from '@/components/molecules/MainMistterList.vue'
// vue-lottieをimport
import Lottie from 'vue-lottie';
// JSONデータ
import scrollanimetion from '@/assets/image/top_scroll_white.json';
import animationData1 from '@/assets/image/about_pc_firstview.json';
import animationData2 from '@/assets/image/about_sp_firstview.json';

    export default {
        name: 'app',
        components: {Lottie,Footer,Header
        },
        data() {
            return {
                lottieOptions: {
                    animationData: scrollanimetion,
                    loop: true,
                    autoplay: true,
                    rendererSettings: {
                        className: 'top__scrollanime'
                    }
                },
                lottieOptions1: {
                    animationData: animationData1,
                    loop: true,
                    autoplay: true,
                },
                lottieOptions2: {
                    animationData: animationData2,
                    loop: true,
                    autoplay: true,
                }
            }
        },
        methods: {
            handleAnimation1: function (anim) {
                this.anim = anim;
            },
            handleAnimation2: function (anim) {
                this.anim = anim;
            },
        },
        mounted() {},
    }
</script>

<!-- 共通CSS -->
<style src="../miiits/css/common.css?v=2"></style>

<!-- ローカルCSS -->
<style scoped>
/* ================================ * /
            ファーストビュー
==================================== */
/* ファーストビューのロゴ表示調整 */
.fvlogo__img--box {
    position: relative;
    height: 103vh;
    width: 100%;
    z-index: 99;
}

@media (max-width: 768px) {
  .fvlogo__img--box {
    height: 103dvh;
  }
}

/* ファーストビューロゴ画像サイズ */
.fvlogo__img--size{
    width: 40%;
    max-width: 450px;
    min-width: 240px;
    height: auto;
}

/* PCスクロールアニメ */
.top__scrollanime {
    position: absolute;
    width: 10% !important;
    max-width: 300px;
    top: 40%;
}

@media (max-width: 959px) {
    .top__scrollanime {
        width: 120px !important;
    }
}

@media (max-width: 768px) {
    .top__scrollanime {
    }
}


/* SPスクロール */
.top__scroll {
    position: absolute !important;
    width: 85px;
    top: 80%;
    right: 80%;
    display: none;
}

@media (max-width: 768px) {
    .top__scroll {
        display: block;
    }
}

@media (max-width: 510px) {
    .top__scroll {
        top: 70%;
        right: 70%;
    }
}


/* ファーストビュー画像サイズ */
.fv__img--box{
    position: relative;
    height: 100vh;
    width: 100%;
}

@media (max-width: 768px) {
  .fv__img--box {
    height: 60vh;
  }
}

/* ファーストビュー記事サムネ画像サイズ */
.fv__img--siz {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

/* ファーストビュー記事親ボックス */
.fv__article--box {
    position: absolute;
    height: auto;
    width: 430px;
    background-color: #FFFFFF;
    padding: 0.7rem;
    top: 60%;
    right: 0;
    padding-right: 2rem;
}

    @media (min-width: 1200px) {
        .fv__article--box {
            width: 500px;
            top: 65%;
        }
    }

    @media (max-width: 576px) {
        .fv__article--box {
            width: 240px;
            top: 53%;
            padding-right: 0.5rem;
        }
    }

/* ファーストビュー記事category名 */
.fv__article--category {
    font-size: 28px;
    text-align: left;
}

    @media (max-width: 576px) {
        .fv__article--category {
        font-size: 20px;
        }
    }

/* ファーストビュー記事タイトル名 */
.fv__article--title {
    font-size: clamp(1rem, 0.818rem + 0.91vw, 1.5rem);
    text-align: left;
    font-weight: 600;
    color: #333333;
}

/* ファーストビュー画像サイズ */
.fv--thumbnail__size{
    height: 100vh;
    width: 50%;
}


/* ================================ * /
            miiitsjooob
==================================== */
/* miiitsjooob画像サイズ */
.job--img__size{
    width: 50%;
    height: auto;
}

/* MiiitsJooobエリア */
.top--background__area{
    margin: 2rem;
    background-color: #FFFFFF;
    border-radius: 10px;
    height: 100%;
    padding: 1rem;
}

.job__head__title {
    line-height: 5rem;
    font-size: 90px;
}

@media (max-width: 568px) {
    .job__head__title {
    line-height: 4rem;
    font-size: 72px;
    }
}


.job__head p {
    color: #DB4627;
    font-size: 34px;
    text-align: left;
    border-left: double 4px #DB4627;
    padding-left: 1rem;
    font-weight: 600;
}

@media (max-width: 420px) {
    .job__head p {
    font-size: 24px;
    }
}

.job__img {
    width: 90%;
    height: 220px;
    border-radius: 10px;
    object-fit: cover;
}

.job__title {
    color: #333333;
    font-size: 18px;
        overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-align: left;
    margin-top: 0.7rem;
}
@media (max-width: 420px) {
    .job__title {
    font-size: 16px;
    }
}

/* MiiitsJouのMiiitsを支える～箇所 */
.job__subhead {
    font-size: 20px;
    margin-top: 1.3rem;
}

@media (max-width: 420px) {
    .job__subhead {
    font-size: 16px;
    margin-top: 1.1rem;
    }
}

/* MiiitsJobの記事を読む部 */
.job__article--link {
    text-decoration: none;
    font-weight: 600;
    color: #DB4627;
    font-size: clamp(1rem, 0.909rem + 0.45vw, 1.25rem);
}

/* MiiitsJobのカテゴリ */
.job__article--category {
    font-size: 20px;
    margin-top: 1rem;
    margin-bottom: 0;
}

/* オレンジ押し込みリンクボタン */
.link__button {
    color: #DB4627;
    font-size: 24px;
    font-weight: 900;
    background: #fff;
    padding: 20px 30px;
    border: 3px solid #DB4627;
    box-shadow: 5px 5px 0 #db4627;
    width: 250px;
    height: 80px;
    display: inline-block;
    transition: .3s;
    text-align: center;
    text-decoration: none;
    align-items: center;
    border-radius: 40px;
}

@media (max-width: 576px) {
    .link__button {
        color: #DB4627;
        font-size: 20px;
        font-weight: 900;
        background: #fff;
        padding: 17px 30px;
        border: 3px solid #DB4627;
        box-shadow: 5px 5px 0 #db4627;
        width: 200px;
        height: 70px;
        display: inline-block;
        transition: .3s;
        text-align: center;
        text-decoration: none;
        align-items: center;
        border-radius: 40px;
    }
}


.link__button:hover {
    transform: translateY(5px);
    box-shadow: 0 0 0 #DB4627;
}


/* ================================ * /
            Mistter
==================================== */
/* Mistter小さいロゴ */
.mistter__logo--s {
    width: 200px;
    height: auto;
}

@media (max-width: 959px) {
    .mistter__logo--s {
    display: none;
    }
}

.mistter__logo {
    width: 70%;
}

@media (max-width: 992px) {
    .mistter__logo {
    width: 50%;
    }
}

.mistter__subhead {
    font-size: 20px;
    padding-top: 1rem;
}

@media (max-width: 420px) {
    .mistter__subhead {
    font-size: 16px;
    margin-bottom: 0.4rem;
    }
}



/* Mistter表示部 */
.mistter__area {
    background-color: #FCF8F2;
    border-radius: 10px;
    height: 500px;
    flex-direction: column;
}

@media (max-width: 420px) {
    .mistter__area {
        width: 90%;
        height: 400px;
        border-radius: 40px;
        padding: 1.1rem;
        padding-top: 3rem;
        flex-direction: unset;
        padding: 2.4rem 1rem;
    }
}

/* Mistter投稿部 */
.mistter__box {
    width: 85%;
    height: 75%;
    background-color: #FCF8F2;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* スクロールバーCSS */
.mistter__box::-webkit-scrollbar {
    width: 10px;
}

@media (max-width: 420px) {
    .mistter__box::-webkit-scrollbar {
        width: 11px;
    }
}

.mistter__box::-webkit-scrollbar-track {
    background-color: #FFF;
    border-radius: 5px;
}

.mistter__box::-webkit-scrollbar-thumb {
    background-color: #DB4627;
    border-radius: 5px;
}


@media (max-width: 768px) {
    .mistter__box {
        width: 95%;
        height: 100%;
    }
}


.mistter__box--textarea {
    position: relative;
    background-color: #FFFFFF;
    width: 95%;
    border-radius: 10px;
    padding-top: 1rem;
    margin: 0 auto;
}


.mistter__box--textarea::before {
	content:'';
	position:absolute;
	width:0;
	height:0;
	left:-20px;
	top:20px;
	border-left:10px solid transparent;
	border-top:10px solid transparent;
	border-bottom:10px solid transparent;
	border-right:10px solid white;}


.mistter__box--textarea p {
    display: inline-block
}

.mistter__user--icon {
    width: 100%;
}

.mistter__donmai--icon {
    width: 50px;
    height: auto;
    display: inline-block;
}

.moreread__button {
    color: #FCF8F2;
    font-size: 16px;
    font-weight: 600;
    background: #DB4627;
    padding: 13px 32px;
    width: 150px;
    height: 50px;
    display: inline-block;
    transition: .3s;
    text-align: center;
    text-decoration: none;
    align-items: center;
    border-radius: 40px;
}

/* ================================ * /
            サービス紹介
==================================== */
.about__container {
    margin-right: auto !important;
    margin-left: auto !important;
    padding-right: 50px;
    padding-left: 50px;
}

/* 横幅調整 */
.about__sizecontroll {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

/* 各サービスボックス */
.about__servicebox {
    width: calc(100%/5);
    overflow: hidden;
    margin: 1%;
}

    /* タブレット以下で2列に */
    @media (max-width: 885px) {
        .about__servicebox {
        width: 45%;
        }
    }


/* サービス一覧アイコンサイズ */
.about--img__size{
    width: 90%;
    height: auto;
}

    /* タブレット */
    @media (max-width: 885px) {
        .about--img__size {
        width: 65%;
        }
    }

    /* SP */
    @media (max-width: 420px) {
        .about--img__size {
        width: 75%;
        }
    }


/* 見出し調整 */
.head__regulation{
    font-size: 60px;
    line-height: 1.2;
}

/* lg */
@media (max-width: 992px) {
.head__regulation {
    font-size: 42px;
    line-height: 1.2;
}
}

/* md */
@media (max-width: 768px) {
.head__regulation {
    font-size: 38px;
    line-height: 1.2;
}
}

/* SP */
@media (max-width: 420px) {
.head__regulation {
    font-size: 28px;
    line-height: 1.2;
}
}

/* 小見出し調整 */
.subhead__regulation{
    font-size: clamp(0.875rem, 0.739rem + 0.68vw, 1.25rem);
    font-weight: 500;
}

/* md */
@media (max-width: 768px) {
.subhead__regulation {
    line-height: 1.2;
}
}

/* SP */
@media (max-width: 420px) {
.subhead__regulation {
    line-height: 1.5;
    margin-top: 1.4rem;
}
}


/* Miiitsと出会う～背景画像設定 */
/* PC背景画像設定 */
.top--background{
    background-image: url("../../../assets/image/top/miiits_about.png");
    background-position: 75% 72%;
    background-size: 30%;
    background-repeat: no-repeat;
}

/* lg */
@media (max-width: 992px) {
.top--background {
    background-position: 95% 90%;
}
}

/* タブ */
    @media (max-width: 768px) {
    .top--background {
        background-image: none;
        margin: 0 auto;
        width: 93%;
    }
}

/* SP */
    @media (max-width: 370px) {
    .top--background {
        background-image: none;
        margin: 0 auto;
        width: 97%;
    }
}

/* SP背景画像設定 */
.top--image__sp{
    width: 80%;
    text-align: center;
    margin: 0 auto;
}

/* サービス紹介見出し */
.top__service--head {
    font-size: 18px;
    color: #333333;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 0.5rem;
    white-space: nowrap;
}

/* SP */
    @media (max-width: 450px) {
    .top__service--head {
        font-size: 14px;
    }
}

/* サービス紹介小見出し */
.top__service--subhead {
    font-size: 16px;
    color: #333333;
    margin-top: 0.4rem;
}

/* SP */
    @media (max-width: 450px) {
    .top__service--subhead {
        font-size: 14px;
    }
}
/* PCとSPでファーストビューを切り替える */
@media(min-width:751px){
    .firstviewsp{
        display: none !important;
        height: 100% !important;
    }
}
@media(max-width:750px){
    .firstviewpc{
        display: none !important;
        width: 100% !important;
    }
}
.firstview_range{
    position:relative; 
    /* margin-top: 1rem; */
}
.newservice_name {
    margin: 0;
}
.people_img{
    width: 50%;
    height: auto;
}
/* 新サービス発掘中 */

.newservice_range{
    position: relative;
    width: 100%;
    margin-top:100px;
    margin-right: auto;
    margin-left: auto;
    flex: unset !important;
}

.newservice_namerange{
    border-radius: 60px;
    padding:20px 20px;
    margin-right: auto; 
    margin-left: auto;
    max-width:700px;
    background: #FFFFFF; 
    text-align: center;
}
.newservice_title{
    text-align: center;
    color: #DB4627;
    font-family: ryo-gothic-plusn,sans-serif;
    font-style: normal;
    font-weight: 600;
    letter-spacing: .3rem;
    font-size: clamp(1.125rem, 0.761rem + 1.82vw, 2.125rem);
}
.fadein {
    opacity: 0;
    flex: 0 1 22%;
    transform: translateY(10px);
    transition: all 1.0s;
}
.fadein.show {
    opacity: 1;
    transform: translateY(0);
}
.fadein.show:nth-of-type(1) {
    transition-delay: 0s;
}
.fadein.show:nth-of-type(2) {
    transition-delay: 0.1s;
}
.fadein.show:nth-of-type(3) {
    transition-delay: 0.2s;
}
.fadein.show:nth-of-type(4) {
    transition-delay: 0.3s;
}
.fadein.show:nth-of-type(5) {
    transition-delay: 0.4s;
}
.fadein.show:nth-of-type(6) {
    transition-delay: 0.5s;
}
.fadein.show:nth-of-type(7) {
    transition-delay: 0.5s;
}
.newservice_wrap {
    display: flex;
    margin: 0 auto;
    height: 200px;
    width: max(50%,600px);
    position: relative;
}
.newservice_img {
    position: absolute;
    width: 90px;
}

.nonde {
    top: 10%;
}
.ending {
    bottom: 10%;
    left: 30%;
}
.omiai {
    top: 10%;
    left: 60%;
}
.kiitemi {
    bottom: 10%;
    left: 90%;
}

@keyframes fuwafuwa1 {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}
@keyframes fuwafuwa2 {
    0%, 100% {
        transform: translateY(-5px);
    }
    50% {
        transform: translateY(0);
    }
}

.anime-fuwafuwa1 {
animation: 2s fuwafuwa1 infinite;
}
.anime-fuwafuwa2 {
animation: 2s fuwafuwa2 infinite;
}

@media (max-width: 768px) {
    .people_img{
        width: 100%;
        height: auto;
    }
    .newservice_range{
        max-width:700px;
        margin-top:200px;
        margin-right: auto; 
        margin-left: auto;
    }
    .fadein {
        opacity: 0;
        flex: 4 1 40%;
        transform: translateY(10px);
        transition: all 1.0s;
    }

    .newservice_namerange{
        border-radius: 60px;
        padding:120px 20px 120px 20px;
        height:380px;
        width:300px;
        background: #FFFFFF; 
        text-align: center;
    }
    .newservice_img {
    width: 50px;
    }
.newservice_wrap {
    height: 100px;
    width: max(50%,350px);
}

}
@media screen and (max-width: 420px) {
    .newservice_range{
        flex: unset !important;
        max-width: 500px;
        margin-top: 140px;
        margin-right: auto;
        margin-left: auto;
    }
    .newservice_namerange{
        border-radius: 60px;
        padding: 20px;
        margin-right: auto;
        margin-left: auto;
        height: 330px;
        max-width: 300px;
        background: #FFFFFF;
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .people_img{
        width: 100%;
        height: auto;
    }
    .newservice_wrap {
    height: 100px;
    width: max(50%,280px);
}
}

</style>